import parsedataconfig from '@/config/ColorBar';

class ColorRangeMaker {
  getGradientColor(name, value) {
    const colorarray = parsedataconfig.color_arrays[name];
    const colorlength = parsedataconfig.color_arrays[name].length;
    const allvalue = parsedataconfig.values[name];

    if (value <= allvalue[0]) {
      return colorarray[0];
    }
    if (value >= allvalue[allvalue.length - 1]) {
      return colorarray[colorlength - 1];
    }

    const ops = this.getIndex(value, allvalue);

    const svalue = allvalue[ops - 1];
    const evalue = allvalue[ops];
    const scolor = colorarray[ops - 1];
    const ecolor = colorarray[ops];
    // console.log('ops==>',ops,'value==>',value,'allvalue===>',allvalue)

    return this.gradienta(scolor, ecolor, svalue, evalue, value);
  }

  getIndex(value, allvalue) {
    let low = 0;
    let high = allvalue.length - 1;
    let mid;
    while (low <= high) {
      mid = (low + high) >> 1;
      if (allvalue[mid] < value) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }
    return low;
  }

  gradienta(sColor, eColor, svalue, evalue, value) {
    const step = ((evalue - svalue) / (value - svalue));
    const rStep = (eColor[0] - sColor[0]) / step;
    const gStep = (eColor[1] - sColor[1]) / step;
    const bStep = (eColor[2] - sColor[2]) / step;
    const aStep = (eColor[3] - sColor[3]) / step;
    return [Math.round(rStep + sColor[0]), Math.round(gStep + sColor[1]), Math.round(bStep + sColor[2]), Math.round(aStep + sColor[3])];
  }
}

export default ColorRangeMaker;
