<template>
  <div class="city_select">
    <el-checkbox v-model="checked">
      {{ text }}
    </el-checkbox>
  </div>
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    checked: {
      get() {
        return this.type === 'normal';
      },
      set(val) {
        this.$emit('change', val ? 'normal' : 'none');
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.city_select {
  padding: 0 15px;
  height: 36px;
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(245, 245, 245, 1);
  }

  .switch-icon {
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid rgba(#000, 0.3);
  }

  .switch-checked {
    font-size: 12px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: $theme-color;
  }

  span {
    font-size: 12px;
    margin-left: 10px;
  }
}
</style>
