<template>
  <div
    class="system_title"
    :class="{
      mobile_title: isMobile,
      position: position,
      drag,
      center,
    }"
  >
    <img
      class="logo"
      src="~@/assets/images/logo.png"
      alt=""
      style="cursor: pointer;"
      @click="$emit('change')"
    >
    <div
      class="title_box"
      :class="{
        border,
      }"
    >
      <span>
        {{ title }}
      </span>
    </div>
    <v-time
      v-if="!isMobile && time"
      :time="currentData.timestamp"
      :utc="utc"
    />
    <slot name="search" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { drag } from '@/utils/drag';
import VTime from '@/components/Map/Time.vue';

export default {
  components: { VTime },
  props: {
    //  显示的时间格式，针对t1
    utc: {
      type: Boolean,
      default: false,
    },
    //  是否显示时间  针对首页
    time: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    back: {
      type: Boolean,
      default: true,
    },
    position: {
      type: Boolean,
      default: true,
    },
    drag: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['isMobile', 'currentData']),
  },
  mounted() {
    if (this.drag) {
      drag(this.$el);
    }
  },
};
</script>

<style lang="scss" scoped>
.system_title {
  top: 20px;
  left: 20px;
  color: #fff;
  z-index: 1000;
  display: flex;
  align-items: center;

  .title_box {
    font-size: 22px;
    line-height: 42px;
    font-weight: 600;
    padding-right: 10px;
  }

  &.center {
    left: 50%;
    transform: translateX(-50%);
  }

  &.position {
    position: absolute;
  }

  &.drag {
    cursor: move;
  }

  &.mobile_title {
    top: 10px;
    font-size: 18px;
    line-height: 32px;

    .logo {
      height: 32px;
    }

    .desc {
      font-size: 8px;
    }
  }

  .icon {
    margin-right: 5px;
    cursor: pointer;
    align-self: center;
  }

  .logo {
    height: 30px;
    margin-right: 5px;
  }

  .desc {
    font-size: 8px;
  }
}
</style>
