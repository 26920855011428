<template>
  <div
    class="player"
    :class="{
      mobile_player_t2: isMobile,
    }"
  >
    <div class="drag_wrap">
      <div class="drag_container">
        <div class="operator-wrap">
          <v-base-time-select
            v-if="!isMobile"
            ref="timeSelect"
          />
          <div class="player_wrap">
            <div
              v-if="!isMobile"
              class="picker_wrap"
            >
              <div class="label">
                日期
              </div>
              <el-date-picker
                v-model="date"
                type="date"
                placeholder="选择日期"
                format="yyyy.MM.dd"
                :picker-options="{ disabledDate }"
                :clearable="false"
              />
            </div>
            <template v-if="isMobile">
              <v-icon-word
                v-if="status === 'pause'"
                icon="play"
                desc="播放"
                spec
                @click.native="play"
              />
              <v-icon-word
                v-else
                icon="pause"
                desc="暂停"
                spec
                @click.native="pause"
              />
            </template>
            <v-icon-word
              icon="play-first"
              desc="第一张"
              @click.native="goToFrame(0)"
            />
            <v-icon-word
              icon="play-prev"
              desc="上一张"
              @click.native="prev"
            />
            <template v-if="!isMobile">
              <v-icon-word
                v-if="status === 'pause'"
                icon="play"
                desc="播放"
                spec
                @click.native="play"
              />
              <v-icon-word
                v-else
                icon="pause"
                desc="暂停"
                spec
                @click.native="pause"
              />
            </template>
            <el-select
              v-else
              v-model="time"
            >
              <el-option
                v-for="item in timeList"
                :key="item.forecastTime"
                :label="item.forecastTime | formatTimestamp"
                :value="item.forecastTime"
              />
            </el-select>
            <v-icon-word
              icon="play-next"
              desc="下一张"
              @click.native="next"
            />
            <v-icon-word
              icon="play-last"
              desc="最后一张"
              @click.native="goToFrame(timeList.length - 1)"
            />
          </div>
          <v-area-select v-if="!isMobile && t3" />
        </div>
      </div>
    </div>
    <v-timeline
      v-if="!isMobile"
      :index="position"
      model-type="t2"
      @change="setTime"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import Bus from '@/bus/index';
import { drag } from '@/utils/drag';
import VIconWord from '@/components/Map/IconWord.vue';
import { factorMap } from '@/config/MapConfig';
import VAreaSelect from '@/components/Map/AreaSelect.vue';
// import VElevation from '@/components/Map/Elevation.vue';
import VTimeline from '@/components/Map/Timeline.vue';
import VBaseTimeSelect from './BaseTImeSelect.vue';

export default {
  components: {
    VIconWord,
    VTimeline,
    VBaseTimeSelect,
    // VElevation,
    VAreaSelect,
  },

  filters: {
    formatTimestamp(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  props: {
    t3: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      position: 1,
    };
  },

  computed: {
    ...mapState(['isMobile']),
    ...mapState('map', ['status', 'factor', 'index', 'windy', 'city', 'isobar']),
    ...mapGetters('map', ['currentTime', 'timeList', 'elevationList']),
    // 时间选择器绑定的日期
    date: {
      get() {
        return moment(this.currentTime).format('YYYY/MM/DD');
      },
      set(value) {
        const time = moment(value);
        const str = time.format('YYYYMMDD');
        const index = this.dayMap[str][0];
        this.goToFrame(index);
      },
    },
    time: {
      get() {
        return this.currentTime;
      },
      set(value) {
        if (value in this.timeMap) {
          this.goToFrame(this.timeMap[value]);
          // this.setIndex(this.timeMap[value]);
        } else {
          this.$message('您选择的时间超出了预测范围');
        }
      },
    },
    // 根据返回的时间生成map，为禁用时间提供hash表，降低查询的时间复杂度
    timeCollection() {
      return this.timeList.reduce((target, item) => {
        const str = moment(item.forecastTime).format('YYYYMMDD');
        target.add(str);
        return target;
      }, new Set());
    },
    // 根据forecastTime生成hash表，方便计算索引
    dayMap() {
      return this.timeList.reduce((target, item, index) => {
        const str = moment(item.forecastTime).format('YYYYMMDD');
        if (target[str]) {
          target[str].push(index);
        } else {
          target[str] = [index];
        }
        return target;
      }, {});
    },
    // 根据forecastTime生成hash表，方便计算索引
    timeMap() {
      return this.timeList.reduce((target, item, index) => {
        target[item.forecastTime] = index;
        return target;
      }, {});
    },
    // 当前要素对应要素
    currentFactor() {
      return factorMap[this.factor];
    },
  },
  watch: {
    currentTime: {
      handler(value) {
        const hour = moment(value).hour();
        this.position = hour * 2 + 1;
      },
      immediate: true,
    },
  },
  created() {
    Bus.$on('pause', this.pause);
  },

  mounted() {
    drag(this.$el.querySelector('.drag_wrap'));
  },

  beforeDestroy() {
    Bus.$off('pause', this.pause);
  },

  methods: {
    ...mapMutations('map', ['setStatus', 'setIndex', 'setWindy', 'setCurrentTime', 'setCity', 'setIsobar']),
    /**
     * @description 播放
     * @returns {void}
     * @author yujie
     */
    play() {
      this.setStatus('play');
      this.next();
    },
    /**
     * @description 暂停
     * @returns {void}
     * @author yujie
     */
    pause() {
      this.setStatus('pause');
      Bus.$emit('clearTimeout');
    },
    /**
     * @description 播放上一张
     * @returns {void}
     * @author yujie
     */
    prev() {
      const index = (this.index - 1 + this.timeList.length) % this.timeList.length;
      this.goToFrame(index);
    },
    /**
     * @description 播放下一张
     * @returns {void}
     * @author yujie
     */
    next() {
      const index = (this.index + 1) % this.timeList.length;
      this.goToFrame(index);
    },
    /**
     * @description 跳转到指定帧
     * @returns {void}
     * @author yujie
     */
    goToFrame(index) {
      Bus.$emit('clearLayer', 'wind');
      Bus.$emit('clearLayer', 'isobar');
      Bus.$emit('removePopup');
      this.setIndex(index);
    },
    /**
     * @description 禁用时间方法，timepicke提供
     * @returns {Boolean} 当前日期是否禁用
     * @author yujie
     * @param value
     */
    disabledDate(value) {
      const str = moment(value).format('YYYYMMDD');
      return !this.timeCollection.has(str);
    },

    setTime(position) {
      const time = moment(this.currentTime);
      const str = time.add(position - 1 - time.hour(), 'h').format('YYYY-MM-DDTHH:mm:ss+08:00');
      if (str in this.timeMap) {
        this.goToFrame(this.timeMap[str]);
      } else {
        this.$message('您选择的时间超出了预测范围');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.player {
  position: absolute;
  width: 100%;
  padding: 0 130px;
  box-sizing: border-box;
  bottom: 30px;
  left: 0;
  display: flex;
  align-items: flex-end;
  z-index: 1000;

  .drag_wrap {
    position: absolute;
    bottom: 18px;
    left: calc((100% - 657px) / 2);

    .drag_container {
      display: flex;
      width: 100%;
      justify-content: center;

      .operator-wrap {
        display: flex;
        justify-content: center;
        background: rgba($text-operate, 1);
        border-radius: 21px;
      }
    }
  }

  .player_wrap {
    margin: 0 10px;
    display: flex;
    align-items: center;
    height: 42px;
    background: rgba($text-operate, 0.92);
    color: $theme-color;
    border-radius: 21px;
    padding: 0 5px;

    .picker_wrap {
      display: flex;
      align-items: center;

      .label {
        font-size: 12px;
        color: #666;
        margin-right: 10px;
        cursor: move;
      }

      ::v-deep {
        .el-input__inner,
        .el-input__icon {
          color: $theme-color;
        }

        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 120px;
        }
      }
    }
  }
}

.player.mobile_player_t2 {
  height: 62px;
  padding: 0;
  bottom: 6px;

  .drag_wrap {
    width: 100%;
    left: 0;
    background: #fff;

    .player_wrap {
      margin: 0;
      padding: 0 10px;
      border-radius: 0;
      width: 100%;
      justify-content: space-between;

      .icon_wrap {
        margin-left: 10px;
      }
    }
  }
}
</style>
