<template>
  <div class="layer-wrap">
    <div
      class="shadow-layer layer"
      :class="{
        active: layerType === 'shadow',
      }"
      @click="setLayerType('shadow')"
    >
      影像图
    </div>
    <div
      class="graph-layer layer"
      :class="{
        active: layerType === 'graph',
      }"
      @click="setLayerType('graph')"
    >
      地形图
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'LayerSelected',
  computed: {
    ...mapState('map', ['layerType']),
  },
  methods: {
    ...mapMutations('map', ['setLayerType']),
  },
};
</script>

<style scoped lang="scss">
.layer-wrap {
  display: flex;
  width: 110px;
  height: 22px;
  border-radius: 4px;
  position: absolute;
  right: 22px;
  top: 20px;
  z-index: 10004;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 22px;

  .layer {
    background: rgba(0, 0, 0, 0.45);
    width: 50%;
    cursor: pointer;

    &.active {
      color: $theme-color;
      background-color: #fff;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}
</style>
