<template>
  <div class="layer-box">
    <v-setting-config
      :type="city"
      text="城市图层11"
      @change="setCity"
    />
    <v-setting-config
      v-if="currentFactor === 'wind' || factor === 'pratesfc'"
      :type="windy"
      text="动态风"
      @change="setWindy"
    />
    <v-setting-config
      :type="hd"
      text="压缩"
      @change="setHD"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import VSettingConfig from '@/components/Map/SettingConfig.vue';
import { factorMap } from '@/config/MapConfig';

export default {
  components: {
    VSettingConfig,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('map', ['factor', 'windy', 'city', 'isobar', 'worldLayer', 'shadowLayer', 'hd']),
    // 当前要素对应要素
    currentFactor() {
      return factorMap[this.factor];
    },
  },
  methods: {
    ...mapMutations('map', ['setWindy', 'setCity', 'setIsobar', 'setMapLayer', 'setShadowLayer', 'setHD']),
    closeFn() {
      this.$emit('update:show');
    },
  },
};
</script>

<style scoped lang="scss">
.group-select-wrap {
  position: absolute;
  right: 46px;
  width: 130px;
  top: 0;
  color: #666;
  background: rgba(255, 255, 255, 1);
  padding: 10px 0 14px;
  border-radius: 4px;
  transition: all 0.5s;
  box-sizing: border-box;
  overflow: auto;
  z-index: 100001;

  .mt20 {
    margin-bottom: 20px;
  }

  .close-btn {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-setting {
    font-size: 14px;
    line-height: 20px;
    padding-left: 15px;
    margin-bottom: 10px;
  }
}
</style>
