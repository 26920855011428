import L from 'leaflet';
import * as turf from '@turf/turf';
// import * as d3 from 'd3';
import { contours } from 'd3-contour';

L.ContourLayer = (L.Layer ? L.Layer : L.Class).extend({
  options: {
    solutionX: 0.25,
    solutionY: 0.25,
    isclip: false,
    isDrawLeftRight: true,
    showLevel: 2, // 文字的显示级别
    stroke: true,
    color: '#5c5c5c', // '#61A5E8',
    weight: 1,
    opacity: 1,
    lineCap: 'round',
    lineJoin: 'round',
    fill: false,
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Microsoft YaHei',
    fontColor: '#61A5E8',
    fontStrokeSize: 2,
    fontStrokeColor: '#8ec100',
    data: null,
    xishu: 1,
  },
  _map: null,
  _canvasLayer: null,
  _ctx: null,
  _geo: null,

  initialize(options) {
    L.setOptions(this, options);
  },

  setData(data) {
    // -- custom data set
    this.options.data = data || [];
    const { solutionX, solutionY } = this.options;
    this._geo = contours().size([360 / solutionX, 180 / solutionY]).thresholds(20).smooth(true)(data);
    const ctx = this._ctx;
    const canvas = this._canvasLayer._canvas;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.drawContour(this._geo);
    // this.needRedraw(); // -- call to drawLayer
  },
  onAdd(map) {
    // create canvas, add overlay control
    let pane;
    if (this.options.pane) {
      pane = map.getPane(this.options.pane);
    }
    this._canvasLayer = L.canvasLayer({ pane }).delegate(this);
    this._canvasLayer.addTo(map);
    this._map = map;
    const { solutionX, solutionY } = this.options;

    const ctx = this._canvasLayer._canvas.getContext('2d');
    this._ctx = ctx;
    const canvas = this._canvasLayer._canvas;
    map = this._map;
    const data = this.options.data;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    this._geo = contours().size([360 / solutionX, 180 / solutionY])
      .thresholds(20)
      .smooth(true)(data);
  },
  onRemove: function onRemove() {
    this._map.removeLayer(this._canvasLayer);
  },

  polygonSmooth(inputPolys) {
    const me = this;
    const outPolys = [];
    if (!inputPolys) throw new Error('inputPolys is required');

    turf.geomEach(inputPolys, (geom, geomIndex, properties) => {
      let outCoords;
      let poly;
      let tempOutput;

      switch (geom.type) {
        case 'Polygon':
          outCoords = [
            [],
          ];
          for (let i = 0; i < 1; i++) {
            tempOutput = [
              [],
            ];
            poly = geom;
            if (i > 0) poly = turf.polygon(outCoords).geometry;
            me.processPolygon(poly, tempOutput);
            outCoords = tempOutput.slice(0);
          }
          outPolys.push(turf.polygon(outCoords, properties));
          break;

        default:
          throw new Error('geometry is invalid, must be Polygon or MultiPolygon');
      }
    });
    return turf.featureCollection(outPolys);
  },
  /**
   * @param {poly} poly to process
   * @param {poly} tempOutput to place the results in
   * @private
   */
  processPolygon(poly, tempOutput) {
    let prevGeomIndex = 0;
    let subtractCoordIndex = 0;

    turf.coordEach(poly, (currentCoord, coordIndex, featureIndex, multiFeatureIndex, geometryIndex) => {
      if (geometryIndex > prevGeomIndex) {
        prevGeomIndex = geometryIndex;
        subtractCoordIndex = coordIndex;
        tempOutput.push([]);
      }
      const realCoordIndex = coordIndex - subtractCoordIndex;
      const p1 = poly.coordinates[geometryIndex][realCoordIndex + 1];
      const p0x = currentCoord[0];
      const p0y = currentCoord[1];
      const p1x = p1[0];
      const p1y = p1[1];
      tempOutput[geometryIndex].push([0.75 * p0x + 0.25 * p1x, 0.75 * p0y + 0.25 * p1y]);
      tempOutput[geometryIndex].push([0.25 * p0x + 0.75 * p1x, 0.25 * p0y + 0.75 * p1y]);
    }, true);
    tempOutput.forEach((ring) => {
      ring.push(ring[0]);
    });
  },

  onLayerDidMount() {
    // -- prepare custom drawing
  },

  onLayerWillUnmount() {
    // -- custom cleanup
  },

  onDrawLayer() {
    // -- custom  draw
    const ctx = this._ctx;
    const canvas = this._canvasLayer._canvas;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.drawContour(this._geo);
  },

  getPoints(map, data) {
    const pts = [];
    const latlngs = [];
    let latlng;
    let latlngtemp = [];
    const latlngtmps = [];
    const { solutionX, solutionY } = this.options;

    for (let i = 0, len = data.length; i < len; i++) {
      const lat = 90 - (data[i][1] * solutionY);
      const lon = (data[i][0] * solutionX);
      latlng = L.latLng(lat, lon);
      latlngtemp = map.latLngToContainerPoint(latlng);
      latlngtmps.push([latlngtemp.x, latlngtemp.y]);
      latlngs.push(latlng);
    }

    for (let j = 0, len = latlngs.length; j < len; j++) {
      pts.push(map.latLngToContainerPoint(latlngs[j]));
    }
    return latlngtmps;
  },

  drawContour(data) {
    const map = this._map;
    const me = this;
    const ctx = this._ctx;
    const xishu = this.options.xishu;

    let points;
    let text;
    for (let j = 0; j < data.length; j++) {
      for (let i = 0, len = data[j].coordinates.length; i < len; i++) {
        for (let k = 0; k < data[j].coordinates[i].length; k++) {
          points = me.getPoints(map, data[j].coordinates[i][k]);
          const polygon = turf.polygon([points]);
          const smoothed = this.polygonSmooth(polygon, {
            iterations: 2,
          });
          text = parseInt(data[j].value / xishu);
          this._drawLine(ctx, smoothed.features[0].geometry.coordinates[0], text);
        }
      }
    }
  },
  _drawLine(ctx, points, text) {
    let p;
    ctx.save();
    ctx.beginPath();
    const zoom = this._map.getZoom();
    if (zoom >= this.options.showLevel) {
      this._drawText(ctx, points[Math.floor(points.length / 2)], text * this.options.xishu);
    }
    for (let i = 0, len = points.length; i < len; i++) {
      p = points[i];
      if (i === 0) {
        ctx.moveTo(p[0], p[1]);
      } else {
        ctx.lineTo(p[0], p[1]);
      }
    }

    this._fillStroke(ctx);
    ctx.restore();
  },

  _drawText(ctx, pt, text) {
    ctx.save();
    ctx.textAlign = 'start';
    ctx.textBaseline = 'middle';
    ctx.font = `normal ${this.options.fontWeight} ${this.options.fontSize} ${this.options.fontFamily}`;
    ctx.strokeStyle = this.options.fontStrokeColor;
    ctx.strokeText(text, pt[0], pt[1]);
    ctx.fillText(text, pt[0], pt[1]);

    ctx.restore();
  },

  _fillStroke(ctx) {
    const options = this.options;

    if (options.fill) {
      ctx.globalAlpha = options.fillOpacity;
      ctx.fillStyle = options.fillColor || options.color;
      ctx.fill(options.fillRule || 'evenodd');
    }

    if (options.stroke && options.weight !== 0) {
      if (ctx.setLineDash) {
        ctx.setLineDash(this.options && this.options._dashArray || []);
      }
      ctx.lineWidth = 0.8;
      ctx.strokeStyle = options.color;
      ctx.stroke();
    }
  },

});
