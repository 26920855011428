import L from 'leaflet';

// region start -- 将天地图的边界线修改为灰度线
L.TileLayer.Grayscale = L.TileLayer.extend({
  options: {
    quotaRed: 21,
    quotaGreen: 71,
    quotaBlue: 8,
    quotaDividerTune: 0,
    quotaDivider() {
      return this.quotaRed + this.quotaGreen + this.quotaBlue + this.quotaDividerTune;
    },
  },

  initialize(url, options) {
    options = options || {};
    options.crossOrigin = true;
    L.TileLayer.prototype.initialize.call(this, url, options);

    this.on('tileload', (e) => {
      this._makeGrayscale(e.tile);
    });
  },

  _createTile() {
    const tile = L.TileLayer.prototype._createTile.call(this);
    tile.crossOrigin = 'Anonymous';
    return tile;
  },

  _makeGrayscale(img) {
    if (img.getAttribute('data-grayscaled')) return;

    img.crossOrigin = '';
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    const imgd = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pix = imgd.data;
    for (let i = 0, n = pix.length; i < n; i += 4) {
      const value = (this.options.quotaRed * pix[i] + this.options.quotaGreen * pix[i + 1] + this.options.quotaBlue * pix[i + 2]) / this.options.quotaDivider();
      pix[i] = value;
      pix[i + 1] = value;
      pix[i + 2] = value;
    }
    ctx.putImageData(imgd, 0, 0);
    img.setAttribute('data-grayscaled', true);
    img.src = canvas.toDataURL();
  },
});
