// 筛选函数
// 函数用于计算两个时间点之间的差值（小时）

export const factor_log  = ['ri_min','dust_conc','dust_emis','dust_ddep','dod','pratesfcaggr','pratesfc','prerrt',
  'pres','preg','pre_total','prei','pres_alls','prer','presrt','pregrt','preirt',
  'base_reflectivity','max_reflectivity','pratesfcaggr','preraggr','presaggr','preiaggr',
  'pregaggr','ice','snow','rain','graupel','pres_all','dswrfsfc','pratesfc_3hr','pratesfc_6hr','pratesfc_24hr']
export function calculateHoursDifference(time1, time2) {
  const date1 = new Date(time1);
  const date2 = new Date(time2);
  return Math.abs(date2 - date1) / (1000 * 60 * 60);
}

export function filterTimeList(times,diff) {
  // 保留的时间列表
  const filtered = [];
  // 遍历时间列表
  for (let i = 1; i < times.length; i++) {
    // 计算当前时间点与第一个时间点的差
    const hoursDiff = calculateHoursDifference(times[0].forecastTime, times[i].forecastTime);
    // 对于第二个时间点，检查与第一个时间点的差是否为2小时
    if (filtered.length === 0 && hoursDiff === diff-1) {
      filtered.push(times[i]);
    } else if (filtered.length > 0) {
      // 对于其他时间点，检查与前一个保留时间点的差是否为3小时
      const prevKeptTime = filtered[filtered.length - 1].forecastTime;
      const nextHoursDiff = calculateHoursDifference(prevKeptTime, times[i].forecastTime);
      if (nextHoursDiff === diff) {
        filtered.push(times[i]);
      }
    }
  }
  return filtered;
}

export function decodePixel(image_data, tile_width) {
  let r; let g; let b;
  const buffer = new ArrayBuffer(24);
  const src = new Uint8Array(buffer);
  const result = new Float32Array(buffer);
  let byte_offset = 4 * tile_width * 4 + 8;
  for (let index = 0; index < 24; index++) {
    r = image_data[byte_offset];
    g = image_data[byte_offset + 1];
    b = image_data[byte_offset + 2];
    r = Math.round(r / 64);
    g = Math.round(g / 16);
    b = Math.round(b / 64);
    src[index] = (r << 6) + (g << 2) + b;
    byte_offset += 16;
  }
  return result;
}



export function reverse(data, data1, max, min, vmax, vmin, factor,config) {
  let result = 0; let windDir = 0;
  if (factor === 'wind') {
    const v1 = (min + data * (max - min) / 255) * 16;
    const v2 = (vmin + data1 * (vmax - vmin) / 255) * 16;
    result = Math.sqrt(v1 ** 2 + v2 ** 2);
    // const r2d = 180 / Math.PI;
    windDir = Math.atan2(v2, v1); // the direction from which wind is blowing
  } else if (factor_log.includes(factor)) {
    const value = min + data * (max - min) / 255;
    result = Math.pow(10, value) -1;
  }else {
    result = (min + data * (max - min) / 255) * 16;
  }

  return [result, windDir];
}


/**
 * 数值进行还原
 * @param result
 * @param max
 * @param min
 * @param vmax
 * @param vmin
 * @param factor
 * @param angle
 * @returns {(number|number)[]}
 */
export function reverseResult(result, max, min, vmax, vmin, factor, angle, config) {
  let data = 0;
  let data1 = 0;
  if (factor === 'wind') {
    // 如果 factor 为 'wind'
    // 根据夹角计算 v1 和 v2
    const v1 = result * Math.cos(angle) / 16;
    const v2 = result * Math.sin(angle) / 16;
    // 反向计算 data 和 data1，并使用 Math.round 进行取整
    data = Math.round((v1 - min) * (255 / (max - min)));
    data1 = Math.round((v2 - vmin) * (255 / (vmax - vmin)));
  } else if (factor_log.includes(factor)) {
    data = Math.round((Math.log10(result+1) - min) * (255 / (max - min)));
  }else {
    // 其他情况
    data = Math.round((result / 16 - min) * (255 / (max - min)));
  }
  return [data, data1];
}
