<template>
  <div v-loading="loading" class="wrap" :class="{ show }">
    <div
      v-show="isShow && show"
      id="wrap-head2"
      class="head"
      @mousemove="headFlag = true"
      @mouseleave="hideHead(2)"
    >
      <div class="box">
        <div class="time">{{ getTime(lineDataMap) }}</div>
        <div class="factor">
          <div v-for="item in defaultFactor" :key="item" class="factor-info">
            <div class="factor-info-item">
              <div class="text">{{ getFactorText(item) }}</div>
              <div class="info">
                {{
                  lineDataMap[item]
                    ? lineDataMap[item][factorIndex]
                      ? lineDataMap[item][factorIndex].value
                      : ""
                    : ""
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="close el-icon-close" @click="closedBottom" />
    <div class="factor-data">
      <div class="left">
        <div class="factor-item title" />
        <div class="factor-item"><span style="text-align: right">时间</span><span> h </span></div>
        <div
          v-for="(factor, fIndex) in defaultFactor"
          :key="factor"
          class="factor-item"
          :class="{
            active: factor === activeFactor,
          }"
          @click="toggleLineChart(fIndex, factor)"
        >
          <span class="first-text" style="text-align: right">
            {{ getFactorText(factor) }}
          </span>
          <span>{{ unit[factorMap[factor]] }}</span>
        </div>
      </div>
      <div
        ref="rightBox"
        class="right"
        @mousemove="mouseMoveDeal($event)"
        @scroll="scrollDeal"
        @mouseleave="hideHead(1)"
        @DOMMouseScroll.stop="handleScroll"
        @mousewheel.stop="handleScroll"
      >
        <div v-show="isShow && show" id="line-chart2" class="line" />
        <div
          v-show="defaultFactor.length"
          ref="chartBox"
          class="charts-box"
          :style="{ top: 48 + curIndex * 22 + 'px', width: `${getLineDataLength * dataWidth}px` }"
        />
        <div v-show="defaultFactor.length" class="date-wrap">
          <div
            v-for="(date, i) in lineDataMap.dateList"
            :key="date"
            class="date-item title"
            :style="{
              minWidth: getWidth(i) + 'px',
            }"
          >
            {{ date | formatTime1 }}
          </div>
        </div>
        <div v-show="defaultFactor.length" class="right-wrap">
          <div class="time-wrap">
            <div
              v-for="(it, itIndex) in lineDataMap[defaultFactor[0]]"
              :key="itIndex"
              class="item-time"
            >
              {{ it.time }}
              <div v-if="it.time === 23" class="line-date" :style="`height: ${lineHeight}px`" />
            </div>
          </div>
          <div class="data-wrap">
            <div
              v-for="factor in defaultFactor"
              :key="factor"
              class="item-data"
              :class="{ active: factor === activeFactor }"
            >
              <div
                v-for="(text, index) in lineDataMap[factor]"
                :key="index"
                class="text"
                :style="{
                  background:
                    factor === activeFactor ? 'none' : getCurrentColorFromValue(factor, text.value),
                }"
              >
                <span>{{ text.label }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="factor-selected">
        <el-select
          v-model="defaultFactor"
          multiple
          :multiple-limit="5"
          collapse-tags
          style="margin-left: 20px; width: 200px"
          placeholder="更多要素"
          @change="handleCheckedMetricChange"
        >
          <el-option
            v-for="item in factorList"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="start">
        <base-time-select @updateChartData="getFactorData" />
      </div>
      <div class="tags-time">
        <div
          v-for="(tag, tagIndex) in tagsTimeData"
          :key="tag.forecastTime"
          :class="{
            active: tagIndex === activeDateIndex,
          }"
          class="tags-item"
          @click="setDatePosition(tagIndex)"
        >
          {{ tag.forecastTime | formatTime }}
        </div>
      </div>
      <div v-show="!isCompleteEightDaysData" class="end" @click="loading = true">更多</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import { chartOption } from "@/config/lineOptions";
import { getCurrentPointFactorData, getCurrentPointTagsTimeData, getCurrentPointFactorDataSD} from "@/api/weather_hd";
import { unit, factorMap, modeMap, productionMap } from "@/config/MapConfig";
import Bus from "@/bus/index";
import echarts from "@/plugins/echarts";
import BaseTimeSelect from "@/components/Map/BaseTimeSelect.vue";
import ColorRangeMaker from "@/config/ParseColor";

export default {
  name: "VBottomMore",
  filters: {
    formatTime(value) {
      return moment(value).format("YYYY.MM.DD");
    },
    formatTime1(value) {
      const obj = {
        Monday: "周一",
        Tuesday: "周二",
        Wednesday: "周三",
        Thursday: "周四",
        Friday: "周五",
        Saturday: "周六",
        Sunday: "周日",
      };

      return `${obj[moment(value).format("dddd")]}  ${moment(value).format("YYYY.MM.DD")}`;
    },
  },
  components: {
    BaseTimeSelect,
  },
  props: {
    t3: {
      type: Boolean,
      default: false,
    },
    metrics: {
      type: Array,
      default: () => [],
    },
    config: {
      type: String,
      default: 'dust'
    }
  },
  data() {
    return {
      isCompleteEightDaysData: false,
      dataWidth: 50,
      activeDateIndex: 0,
      initChart: null,
      unit,
      factorMap,
      lineData: [],
      isShow: false,
      chatFlag: false,
      tagsTimeData: [],
      factorIndex: 0,
      toastInfo: {},
      headFlag: false,
      show: false,
      lineHeight: 0,
      position: null,
      defaultFactor: ["dust_conc", "dod", "dust_ddep", "dust_emis"],
      isIndeterminate: true,
      checkAll: false,
      loading: false,
      // 折线图默认为第一个
      activeFactor: "pratesfc",
    };
  },
  computed: {
    ...mapState(["isMobile"]),
    ...mapState("dust", ["pixelValue","index",'factor']),
    ...mapGetters("dust", ['isT2Mode', "currentData", "currentLayerDetails"]),
    factorList() {
      let arr = [];
      this.metrics.forEach((item) => {
        if (item.children) {
          arr = [...arr, ...item.children];
        } else {
          arr.push(item);
        }
      });
      return arr;
    },
    factor() {
      return this.config === 'dust' ? this.$store.state['dust']['factor'] : this.$store.state['map']['factor'];
    },
    curIndex() {
      return this.defaultFactor.findIndex((item) => item === this.activeFactor);
    },
    activeNames() {
      return this.lineData.map((item, index) => index);
    },
    factorIndexMap() {
      return this.defaultFactor.reduce((target, item, index) => {
        target[item] = index;
        return target;
      }, {});
    },
    lineDataMap() {
      return this.lineData.reduce((target, item) => {
        const { forecastDetails, factorCode } = item;
        const arr = [];
        target[factorCode] = forecastDetails.map((cur,inx) => {
          const { value, forecastTimeString } = cur;
          const date = forecastTimeString.slice(0, -2);
          const time = Number(forecastTimeString.slice(-2));
          if (!arr.includes(date)) {
            arr.push(date);
          }
          let val = 0;
          if (value.length > 1) {
            const windData = [value[0], value[1]];
            val = Math.sqrt(windData.reduce((sum, num) => sum + parseFloat(num) ** 2, 0));
          } else if (value.length === 1) {
            val = parseFloat(value[0]);
          }

          // factorCode === "dust_conc" ? (val = val * 1000) : "";

          // let val = value.length > 1 ? Math.sqrt(value.reduce((sum, num) => sum + parseFloat(num) ** 2, 0)) : parseFloat(value[0]);
          let label = val;
          if (this.factor === "ri_min"){
            val = Math.max(0, 7.5 - val)
          } else if(factorCode === this.factor && inx === this.index && this.pixelValue !==""){
            val = this.pixelValue
            label = this.pixelValue
          }
          return {
            // 时间的截取
            time,
            value: val.toFixed(2),
            label: label.toFixed(2),
            forecastTimeString,
          };
        });
        if (!target.dateList?.length) {
          target.dateList = arr;
        }
        return target;
      }, {});
    },
    getFirstLength() {
      const { baseTimeString } = this.config === 'dust' ? this.currentData : this.$store.getters['map/currentData'];
      //console.log('1111111111111111111111111111111111', baseTimeString);
      if (baseTimeString) {
        return 23 - Number(baseTimeString.slice(-2));
      }
      return 0;
    },
    getLineDataLength() {
      return this.lineDataMap[this.activeFactor]?.length ?? 0;
    },
  },
  watch: {
    position(val) {
      if (val) {
        if (Reflect.has(this.factorIndexMap, this.factor)) {
          this.defaultFactor.splice(this.factorIndexMap[this.factor], 1, this.factor);
          this.activeFactor = this.factor;
        } else {
          if (this.defaultFactor.length >= 5) this.defaultFactor.pop();
          this.defaultFactor.unshift(this.factor);
          this.activeFactor = this.defaultFactor[0];
        }
        console.log('this.defaultFactor1', this.defaultFactor, this.factor);
        this.getFactorData();
      }
    },
    defaultFactor() {
      this.lineHeight = this.$refs.rightBox.clientHeight + 30;
    },
    metrics: {
      handler() {
        // 处理温度要素不一致的问题
        this.defaultFactor = this.defaultFactor.map((item) => {
          if (["tmp2m", "t2mz"].includes(item)) {
            return !this.isT2Mode ? "tmp2m" : "t2mz";
          }
          return item;
        });
      },
      immediate: true,
    },
  },
  mounted() {
    // this.$refs.rightBox.addEventListener('scroll', function(event) {
    //   // 在这里处理滚动事件
    //   this.isShow = false;
    // });
    this.lineHeight = this.$refs.rightBox.clientHeight + 30;

    Bus.$on("showBottomDust", (position) => {
      this.show = true;
      this.defaultFactor = ["dust_conc", "dod", "dust_ddep", "dust_emis"];
      if (this.defaultFactor.includes(this.factor)) {
        this.defaultFactor.splice(this.defaultFactor.indexOf(this.factor), 1);
        this.defaultFactor.unshift(this.factor);
      }
      console.log('this.defaultFactor', this.defaultFactor)
      this.position = position;
      this.initLocal();
      this.$emit("controlTimeLine", false);
    });

    Bus.$on("hiddenBottom", () => {
      this.isShow = false;
      this.show = false;
      const that = this;
      setTimeout(() => {
        that.$emit("controlTimeLine", true);
      }, 350);
    });
  },
  beforeDestroy() {
    Bus.$off("showBottom");
    Bus.$off("hiddenBottom");
  },
  methods: {
    closedBottom() {
      this.show = false;
      Bus.$emit("hasHiddenBottom");
      Bus.$emit("removePopup");
      const that = this;
      setTimeout(() => {
        that.$emit("controlTimeLine", true);
      }, 350);
    },
    handleScroll(e) {
      if (e.wheelDelta || e.detail) {
        const l = this.$refs.rightBox.scrollLeft;
        if (e.wheelDelta > 0 || e.detail < 0) {
          // 当鼠标滚轮向上滚动时
          this.$refs.rightBox.scrollLeft = l - 8;
        }
        if (e.wheelDelta < 0 || e.detail > 0) {
          // 当鼠标滚轮向下滚动时
          this.$refs.rightBox.scrollLeft = l + 8;
        }
      }
    },
    scrollDeal() {
      this.isShow = false;
    },
    getCurrentColorFromValue(factorCode, findValue) {
      const colorList = new ColorRangeMaker().getGradientColor(factorMap[factorCode], findValue);
      const color = colorList.length === 3 ? colorList.join() : colorList.slice(0, -1).join();
      return `rgb(${color},0.4)`;
    },
    toggleLineChart(index, factor) {
      this.activeFactor = factor;
      this.renderChart(this.lineDataMap[factor], factor);
    },
    setDatePosition(index) {
      // 算出第一个的宽度
      const firstWidth = this.getWidth(0);
      this.activeDateIndex = index;
      this.$refs.rightBox.scrollLeft =
        index === 0 ? 0 : firstWidth + this.dataWidth * 24 * (index - 1);
      this.isShow = false;
    },
    mouseMoveDeal(event) {
      this.chatFlag = true;
      this.isShow = true;
      this.factorIndex = Math.floor((event.clientX + this.$refs.rightBox.scrollLeft - 160) / 50);
      document.getElementById("line-chart2").style.left = `${
        event.clientX + this.$refs.rightBox.scrollLeft - 160
      }px`;
      const headDom = document.getElementById("wrap-head2");
      headDom.style.left = `${event.clientX - 160 + 40}px`;
    },
    hideHead(type) {
      if (type === 1) {
        this.chatFlag = false;
        setTimeout(() => {
          if (!this.headFlag) {
            this.isShow = false;
          }
        }, 10);
      } else {
        this.headFlag = false;
        setTimeout(() => {
          if (!this.chatFlag) {
            this.isShow = false;
          }
        }, 10);
      }
    },
    initLocal() {
      // setTimeout(() => {
      //   this.isShow = true;
      // }, 500);
      // let headDom = document.getElementById('wrap-head2');
      // document.getElementById('line-chart').style.left = '0px';
      // headDom.style.left = `${0 + 40}px`;
    },
    getWidth(i) {
      if (i === 0) {
        return this.getFirstLength * this.dataWidth;
      }
      if (i === this.lineDataMap.dateList.length - 1) {
        return (24 - this.getFirstLength) * this.dataWidth;
      }
      return this.dataWidth * 24;
    },
    getFactorText(id) {
      const res = this.factorList.filter((item) => item.id === id);
      return res[0]?.text || "";
    },
    getTime(obj) {
      if (!Object.values(obj).length) return;
      const str = Object.values(obj)[0][this.factorIndex].forecastTimeString;

      // 提取年、月、日、小时信息
      const year = str.slice(0, 4);
      const month = str.slice(4, 6);
      const day = str.slice(6, 8);
      const hour = str.slice(8, 10);

      return `${year}-${month}-${day} ${hour}:00`;
    },
    handleCheckedMetricChange(value) {
      const checkedCount = value.length;
      if (checkedCount > 5) {
        this.$message.error("选择要素不能超过5个");
        return;
      }

      if (checkedCount) {
        this.activeFactor = this.defaultFactor[0];
        this.getFactorData();
      }
    },
    getUrlEncode(obj) {
      let str = "";
      Object.keys(obj).forEach((key) => {
        str += `${key}=${obj[key]}&`;
      });
      // 判断是否为订正要素
      const isOld = ["early", "late"].includes(obj.mode);
      console.log('this.defaultFactor', this.defaultFactor);
      this.defaultFactor.forEach((item) => {
        str += `factorCode=${
          (this.isT2Mode ? undefined : productionMap[item]) ?? (isOld ? obj.production : "t2")
        }:${(this.isT2Mode ? undefined : modeMap[item]) ?? (isOld ? obj.mode : "early")}:${item}&`;
      });
      console.log('this.defaultFactor', str.slice(0, -1));
      return str.slice(0, -1);
    },
    getFactorUrlEncode(obj) {
      let str = "";
      Object.keys(obj).forEach((key) => {
        str += `${key}=${obj[key]}&`;
      });
      return str.slice(0, -1);
    },
    renderChart(data, factor) {
      if (this.initChart) {
        this.initChart.dispose();
      }
      this.initChart = echarts.init(this.$refs.chartBox);
      const valueData = [];
      const xData = [];
      data.forEach((item,inx) => {
        if (this.pixelValue!==""&&inx === this.index && factor === this.factor) {
          valueData.push(this.pixelValue.toString());
        }else {
          valueData.push(item.value);
        }

        xData.push(item.time);
      });
      this.initChart.setOption(chartOption(xData, valueData, factor));
      this.$nextTick(() => {
        this.initChart.resize();
      });
    },
    async requestMoreData(typeData, data) {
      this.lineData = [];
      const res = await getCurrentPointFactorData(this.getUrlEncode(data));
      const { forecast } = res;
      if (forecast && forecast.length) {
        this.lineData = forecast ?? [];
        if (this.lineData.length) {
          this.renderChart(this.lineDataMap[this.activeFactor], this.activeFactor);
        }
      }
      this.loading = false;

      delete data.duration;
      const res1 = await getCurrentPointFactorData(this.getUrlEncode(data));
      const forecastData = res1.forecast;
      if (forecastData && forecastData.length) {
        this.lineData = forecastData ?? [];
        if (this.lineData.length) {
          const lineData_ = this.lineDataMap[this.activeFactor];
          const valueData = [];
          const xData = [];
          lineData_.forEach((item,inx) => {
            // valueData.push(item.value);
            if (this.pixelValue!==""&&inx === this.index) {
              valueData.push(this.pixelValue.toString());
            }else {
              valueData.push(item.value);
            }
            xData.push(item.time);
          });
          this.initChart.setOption(chartOption(xData, valueData, this.activeFactor));
        }
      }
    },
    async getFactorData() {
      if (this.defaultFactor.length < 1) {
        this.$message.info("请至少选择一个要素");
        return;
      }
      this.$refs.rightBox.scrollLeft = 0;
      this.activeDateIndex = 0;
      this.loading = true;
      this.isCompleteEightDaysData = false;

      const { production, baseTimeString, mode, region, pressure } = this.config === 'dust' ?  this.currentLayerDetails : {...this.$store.getters['map/currentLayerDetails']}
      console.log(` this.$store.getters['map/currentLayerDetails']`,  production, baseTimeString, mode, region, pressure);

      // 获取要素的类型
      const paramsObj = {
        baseTime: baseTimeString,
        mode,
        region,
        production,
      };

      getCurrentPointTagsTimeData(this.getUrlEncode(paramsObj)).then((tagsData) => {
        if (tagsData) {
          this.tagsTimeData = tagsData.forecast;
        }
      });

      const data = {
        ...this.position,
        mode,
        baseTime: baseTimeString,
        production,
        pressure,
        region,
        duration: 2,
      };

      this.requestMoreData(null, data)
        .then(() => {
          this.initChart.resize();
          this.isCompleteEightDaysData = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.wrap {
  position: absolute;
  bottom: -270px;
  left: 0;
  z-index: 100000;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  transition: 0.5s;

  .head {
    position: absolute;
    left: 40px;
    top: -93px;
    z-index: 999;

    .box {
      display: flex;
      flex-direction: column;
      height: initial;
      min-width: 240px;
      text-align: left;
      padding: 0.5em 1em;
      box-shadow: none;
      padding-top: 35px;
      color: white;
      background-color: rgba(68, 65, 65, 0.84);
      text-shadow: 0px 0px 4px black;
      position: relative;
      box-sizing: border-box;
      white-space: nowrap;
      vertical-align: middle;
      border-radius: 0.5em;

      &::before {
        top: 100%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        border-width: 0.5em;
        margin-left: -0.5em;
        border-top-color: rgba(68, 65, 65, 0.84);
        left: 120px;
      }

      &::after {
        font-size: 7px;
        opacity: 0.5;
        content: attr(data-forecasted);
        position: absolute;
        bottom: 4px;
      }

      .factor {
        display: flex;

        .factor-info-item {
          display: flex;
          flex-direction: column;
          width: 80px;
          text-align: center;

          .text {
            font-size: 10px;
            opacity: 0.7;
            margin-bottom: 5px;
          }

          .info {
            font-size: 15px;
            margin-bottom: 5px;
          }
        }
      }

      .time {
        position: absolute;
        left: 15px;
        top: 8px;
        width: calc(100% - 30px);
        font-size: 14px;
        line-height: 1.5;
        overflow: hidden;
      }
    }
  }

  &.show {
    bottom: 0;
  }

  .title {
    box-sizing: border-box;
    border-bottom: 1px solid #e5e5e5;
  }

  .factor-data {
    // max-height: 210px;
    display: flex;

    .left {
      min-width: 160px;
      line-height: 22px;
      font-size: 12px;
      background-color: #f2f2f2;
      color: #a0a0a0;
    }

    .right {
      position: relative;
      background-color: #f8f8f8;
      flex: 1;
      width: calc(100% - 160px);
      overflow-x: auto;
      overflow-y: hidden;

      .line {
        height: 100%;
        width: 1px;
        background: #d49500;
        position: absolute;
        z-index: 999;
      }

      .date-wrap {
        width: 100%;
        display: flex;

        .date-item {
          box-sizing: border-box;
          background: #f8f8f8;
          height: 30px;
          text-align: center;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303030;
          line-height: 30px;
        }
      }

      .charts-box {
        position: absolute;
        left: 0;
        top: 48px;
        z-index: 4;
        height: 66px;
      }

      .right-wrap {
        color: #666;

        .item-data {
          display: flex;

          &.active {
            //visibility: hidden;
            height: 66px;
            align-items: flex-end;
          }
        }

        .time-wrap {
          background: #fff;
        }

        .time-wrap,
        .item-data {
          display: flex;

          .text,
          .item-time {
            font-size: 12px;
            line-height: 22px;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #333;
            min-width: 50px;
            text-align: center;
            text-shadow: 0 0 1px #fff;
            background: #f8f8f8;
            .line-date {
              position: absolute;
              height: 100%;
              width: 1px;
              top: -30px;
              right: 0;
              background: #e5e5e5;
            }
          }
        }
      }
    }
  }

  .bottom {
    padding: 5px 30px;
    display: flex;
    position: relative;
    align-items: center;
    background: #f4f4f4;

    .factor-selected {
      ::v-deep {
        .el-select__tags {
          background: rgb(255, 255, 255);
          border-radius: 4px;
        }

        .el-input__inner {
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
        }
      }
    }

    .tags-time {
      display: flex;

      .tags-item {
        cursor: pointer;
        line-height: 22px;
        margin-left: 10px;
        border-radius: 6px;
        padding: 0 10px;
        font-size: 12px;
        background-color: rgba(#fff, 1);
        color: #999;
        border: 1px solid #e9e9e9;

        &.active {
          background-color: rgba($theme-color, 1);
          color: $text-operate;
        }
      }
    }

    .end {
      font-size: 12px;
      line-height: 34px;
      position: absolute;
      right: 20px;
      top: 0;
      cursor: pointer;
      color: #333;
    }
  }

  .factor-box {
    min-height: 200px;
    padding-top: 15px;

    .box {
      width: 100%;
      height: 280px;
    }
  }

  .close {
    position: absolute;
    right: 47px;
    top: -15px;
    width: 28px;
    height: 28px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    background: #3d3d3d;
    z-index: 1000003;
  }
}

.factor-item {
  display: flex;
  height: 22px;
  cursor: pointer;

  &:first-child {
    background-color: #f8f8f8;
    height: 30px;
  }

  span {
    width: 60%;
    text-align: left;
    color: #666;

    &:last-child {
      font-weight: 800;
      width: 40%;
      color: #333;
      margin-left: 5px;
    }
  }

  &.active {
    height: 66px;
    align-items: center;

    .first-text {
      &::before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        top: -3px;
        background-color: $theme-color;
      }
    }

    span {
      &:last-child {
        color: $theme-color;
      }
    }
  }
}

::v-deep {
  .el-checkbox {
    width: 25%;
  }

  .el-input__inner {
    height: 22px;
    line-height: 22px;
  }

  .el-select {
    .el-tag {
      margin: 0 0 0 6px;
      height: 20px;
    }
  }

  .el-tag--small {
    line-height: 20px;
  }

  .el-input__icon {
    line-height: 20px;
  }
}
</style>
