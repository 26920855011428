import colorBar from '@/config/ColorBar';
import { factorMap } from '@/config/MapConfig';

/**
 * @description 生成指定的色卡
 * @param {String} factor 要素
 * @returns {Object[]}
 * @author yujie
 */
const getColorBar = (factor) => {
  const key = factorMap[factor];
  const values = colorBar.values[key];
  const colors = colorBar.color_arrays[key];
  const plus = (factor === 't2mz' || factor === 'tmp' || factor === 'tmpsfc') ? 273.15 : 0;

  return values.map((item, index) => {
    const value = item + plus;

    let color = `rgb(${colors[index][0]}, ${colors[index][1]}, ${colors[index][2]})`;
    if (colors[index].every((v) => v === 255)) {
      color = 'transparent';
    }

    return {
      offset: value,
      color,
    };
  });
};

/**
 * @description 判断当前可视区域是否在指定边界内
 * @param {LatLngBounds} area 可视区域
 * @param {Number[]} border 边界
 * @returns {Boolean}
 * @author yujie
 */
const isContains = (area, border) => !!(area || border);

/**
 * 节流函数
 * @param {Function} func 目标函数
 * @param {*} wait 节流间隔
 * @param {*} delay 最后一次执行延迟，默认为 wait 的一半
 */
const throttle = (func, wait, delay) => {
  let timeout;
  let time = 0;
  delay = delay || (wait / 2);
  return () => {
    const context = this;
    // eslint-disable-next-line no-undef
    const args = arguments;
    const now = Date.now();
    if (timeout) {
      clearTimeout(timeout);
    }
    if (now - time > wait) {
      time = now;
      timeout = null;
      func.apply(context, args);
    } else {
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait + time - now + delay);
    }
  };
};

/**
 * @description 获取图片质量
 * @param {Number} diff ms数
 * @returns {Number} 图片的压缩比
 * @author yujie
 */
const getQuality = (diff) => {
  const map = [1500, 3000, 4500];

  if (diff <= map[0]) {
    return 1;
  }

  if (diff > map[0] && diff < map[1]) {
    return 0.9;
  }

  if (diff > map[1] && diff < map[2]) {
    return 0.7;
  }

  return 0.5;
};

// 获取浏览器窗口的可视区域的高度
function getViewPortHeight() {
  return document.documentElement.clientHeight || document.body.clientHeight;
}

export {
  getColorBar, isContains, throttle, getQuality, getViewPortHeight,
};
