<template>
  <div class="operate-menu">
    <div
      class="qrShow"
      ref="menu"
      style="height: auto; line-height: 26px; position: fixed"
      :style="{ top: menuTop + 'px', left: menuLeft + 'px' }"
      v-html="getRatio()"
      v-show="isShow && haveFactor()"
    ></div>
    <div
      v-show="!showMetrics"
      ref="operateMenuIcon"
      class="more-icon icon"
      @click.stop="
        showMetrics = true;
        setShowMetric(true);
      "
    >
      <span class="metric_text">
        {{ metricActive.text }}
      </span>
      <svg-icon :icon-class="metricActive.icon || 'more-block'" />
    </div>

    <div
      class="all-menu-wrap"
      ref="container"
      :style="{
        transform: !showMetrics ? 'translate(0, 0)' : `translateX(-190px)`,
        opacity: !showMetrics ? 0 : 1,
      }"
    >
      <span v-if="showMetrics" class="close icon" @click.stop="hideMetrics()">
        <svg-icon icon-class="close" />
      </span>

      <div class="metrics-wrap">
        <div
          v-for="metric in currentData"
          :key="metric.id"
          :class="{
            'is-active': metric.id === factor,
          }"
          class="item"
          style="position: relative"
          @click.stop="handler($event, metric)"
        >
          <div style="display: flex">
            <span class="icon">
              <i
                v-if="metric.children && metric.children.length"
                :class="!metric.show ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
              />
              <svg-icon v-else :icon-class="metric.icon" />
            </span>
            <span class="text">{{ metric.text }}</span>
          </div>
          <!-- <div
            class="qrShow"
            style="height: 26px; line-height: 26px"
            v-html="getRatio(metric)"
            v-show="
              activeID === metric.id &&
              !metric.children &&
              haveFactor(metric.id)
            "
          ></div> -->
          <div v-show="metric.show">
            <div
              v-for="item in metric.children"
              :key="item.id"
              :class="{
                'is-active': item.id === factor,
              }"
              class="item second-item"
              style="position: relative"
              @click.stop="handler($event, item, true)"
            >
              <span class="icon">
                <svg-icon :icon-class="item.icon" />
              </span>
              <span class="text">{{ item.text }}</span>
              <!-- <div
                class="qrShow"
                style="height: 26px; line-height: 26px; left: -125px;"
                v-html="getRatio(item)"
                v-show="
                  activeID === item.id &&
                  haveFactor(item.id)
                "
              ></div> -->
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          (currentFactor === 'wind' || factor === 'wgrd10m' || factor === 'pratesfc') &&
          routerName === 'weather'
        "
        class="dynamic-wind"
      >
        <el-switch v-model="isWindy" active-color="#05f" />
        <span class="text">动态风</span>
      </div>
      <div v-else>
        <v-city-select :city="city" class="mt20" @change="setCity" />
        <v-hd-select :hd="hd" class="mt20" @change="setHD" />
        <v-windy-select
          v-if="currentFactor === 'wind' || factor === 'pratesfc' || factor === 'wgrd10m'"
          :windy="windy"
          class="mt20"
          @change="setWindy"
        />
        <v-isobar-select :isobar="isobar" @change="setIsobar" />
        <v-map-layer-select @change="mapLayerChange('graph')" :type="layerType" />
        <v-shadow-layer-select @change="mapLayerChange('shadow')" :type="layerType" />
      </div>

      <div class="basetime">
        <span class="text">起报时间(local)</span>
        <div class="select_wrap">
          <el-select v-model="time">
            <el-option
              v-for="item in baseTime"
              :key="item.baseTimeModel || item"
              :label="item | formatTime"
              :value="item.baseTimeModel || item"
            />
          </el-select>
        </div>
      </div>
      <div v-if="elevationList && elevationList.length" class="elevation">
        <span class="text">高程</span>
        <div class="select_wrap">
          <el-select v-model="elevationValue">
            <el-option v-for="item of elevationList" :key="item" :label="item" :value="item" />
          </el-select>
        </div>
      </div>
      <div v-if="routerName === 't3'" class="region">
        <span class="text">区域</span>
        <div class="select_wrap">
          <el-select v-model="regionValue">
            <el-option
              v-for="item of regionList"
              :key="item.regionCode"
              :label="item.regionName"
              :value="item.regionCode"
            />
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import VWindySelect from "@/components/Map/WindySelect.vue";
import VCitySelect from "@/components/Map/CitySelect.vue";
import VHdSelect from "@/components/Map/HDSelect.vue";
import { TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND } from "@/config";
import VIsobarSelect from "@/components/Map/IsobarSelect.vue";
import { drag } from "@/utils/drag";
import { factorMap, modeFactorMap, dust_factorMap } from "@/config/MapConfigT4";
import Bus from "@/bus";
import VMapLayerSelect from "@/components/Map/MapLayerSelect.vue";
import VShadowLayerSelect from "@/components/Map/ShadowLayerSelect.vue";

export default {
  name: "MetricSelect",
  filters: {
    formatTime(value) {
      if (typeof value === "string") {
        const [time, model] = value.split(" ");
        return `${moment(time).format("YYYY-MM-DD HH时")}`;
      }
      const model = value.mode === "early" ? "DA" : "ND";
      return `${moment(value.baseTime).format("YYYY-MM-DD HH时")}`;
    },
  },
  components: {
    VWindySelect,
    VCitySelect,
    VHdSelect,
    VIsobarSelect,
    VMapLayerSelect,
    VShadowLayerSelect,
  },
  props: {
    factor: {
      type: String,
      required: true,
    },
    windy: {
      type: String,
      required: true,
    },
    initBaseTime: {
      type: String,
      required: true,
    },
    baseTime: {
      type: Array,
      required: true,
    },
    metrics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showMetrics: false,
      // metrics,
      dynamicWindSwitch: true,
      activeID: false,
      currentData: [],
      menuTop: 0,
      menuLeft: 0,
      isShow: false,
      activeMetric: "",
      prevScrollTop: 0,
    };
  },
  computed: {
    ...mapState("map", [
      "t2Mode",
      "city",
      "isobar",
      "elevation",
      "region",
      "regionList",
      "worldLayer",
      "shadowLayer",
      "hd",
    ]),
    ...mapState(["showMetricState", "t1Mode"]),
    ...mapGetters("map", ["elevationList"]),
    ...mapState("dust", ["layerType"]),
    isWindy: {
      get() {
        return this.windy === "normal";
      },
      set(val) {
        this.$emit("setWindy", val ? "normal" : "none");
      },
    },
    // 当前起报时间
    time: {
      get() {
        return this.initBaseTime;
      },
      set(val) {
        this.$emit("handleBaseTimeChange", val);
      },
    },
    regionValue: {
      get() {
        return this.region;
      },
      set(value) {
        this.setRegion(value);
      },
    },
    elevationValue: {
      get() {
        return this.elevation;
      },
      set(value) {
        this.setElevation(value);
        Bus.$emit("clearLayer", "map");
        Bus.$emit("render");
      },
    },
    metricActive() {
      let obj;
      const loop = (data) => {
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          if (item.id === this.factor) {
            obj = item;
            break;
          }
          if (item.children && item.children.length) {
            loop(item.children);
          }
        }
        return obj;
      };
      return loop(this.metrics) ?? {};
    },
    // 当前要素对应要素
    currentFactor() {
      return factorMap[this.factor];
    },
    routerName() {
      return this.$route.name;
    },
  },
  watch: {
    metrics() {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  mounted() {
    document.body.addEventListener("click", this.hideMetrics);
    this.$refs.container.addEventListener("scroll", this.handleScroll);
    drag(this.$refs.operateMenuIcon);
    this.showMetrics = this.showMetricState;
  },
  beforeDestroy() {
    this.$refs.container.removeEventListener("scroll", this.handleScroll);
    document.body.removeEventListener("click", this.hideMetrics);
  },
  methods: {
    ...mapMutations(["setShowMetric"]),
    ...mapMutations("map", [
      "setStatus",
      "setIndex",
      "setWindy",
      "setCity",
      "setIsobar",
      "setElevation",
      "setRegion",
      "setMapLayer",
      "setShadowLayer",
      "setHD",
    ]),
    ...mapMutations("map", ["setFactor", "setMode"]),
    ...mapMutations("dust", [
      "setLayerType",
      "setCity",
      "setHD",
      "setWindData",
    ]),
    hideMetrics() {
      this.isShow = false;
      this.showMetrics = false;
      this.setShowMetric(false);
    },
    handleScroll() {
      const scrollTop = this.$refs.container.scrollTop;
      const scrollDistance = scrollTop - this.prevScrollTop;
      this.prevScrollTop = scrollTop;
      console.log("11111111111111111", scrollDistance);
      // 在滚动时更新菜单位置
      if (this.menuTop !== 0) {
        this.menuTop -= scrollDistance;
      }
    },
    initData() {
      this.currentData = this.metrics.map((metric) => {
        if (metric.children && metric.children.length) {
          metric.show = metric.subId ? metric.subId.includes(this.factor) : false;
        }
        return metric;
      });
    },
    mapLayerChange(item) {
      this.setLayerType(item);
    },
    getRatio() {
      const metric = this.activeMetric;
      if (this.isTyp || this.isSatel) {
        //如果列表是t2此时取t2的模式，否则取t1的模式
        if (sessionStorage.getItem("tmode") === "t2") {
          return this.t2Ratio(metric);
          //天机1时
        } else {
          return ["pre_total", "pres_all", "prer", "pres", "preg", "prei"].includes(metric.id)
            ? "12km<br/>自起报时累计"
            : "12km";
        }
      } else {
        //以天机2为准
        // if (this.$route.name === "weather_hd" || this.$route.name === "earth") {
        return this.t2Ratio(metric);
        // } else {
        //   return ['prer333', 'prer111', 'prer', 'pres', 'preg', 'prei'].includes(metric.id) ?  "自起报时累计<br/>12KM" :  "12KM";
        // }
      }
    },
    t2Ratio(metric) {
      if (this.t2Mode === TIANJI2_MERGE) {
        if (["tmp2m", "t2mz"].includes(metric.id)) {
          return "1km";
        } else if (["dust_conc", "dod", "dust_ddep", "dust_emis"].includes(metric.id)) {
          return "12km";
        } else {
          return ["pre_total", "pres_all", "prer", "pres", "preg", "prei"].includes(metric.id)
            ? "10km<br/>自起报时累计"
            : "10km";
        }
      } else {
        return ["pre_total", "pres_all", "prer", "pres", "preg", "prei"].includes(metric.id)
          ? "2.5km<br/>自起报时累计"
          : "2.5km";
      }
    },
    haveFactor() {
      const id = this.activeMetric.id;
      const mapType =
        sessionStorage.getItem("tmode") === "t1"
          ? modeFactorMap[this.$store.state.t1Mode]
          : modeFactorMap[this.$store.state["map"]["t2Mode"]];
      return mapType.includes(id);
    },
    showParent(metric) {
      return metric.subId ? metric.subId.includes(this.factor) : false;
    },
    toggleChange(metric) {
      this.currentData = this.currentData.map((item) => {
        if (item.id === metric.id) {
          item.show = !item.show;
        }
        return item;
      });
    },

    handler(event, metric, isSon) {
      this.isShow = false;
      let isParent;
      const factor = this.$store.state["dust"]["factor"];
      if (metric.children && metric.children.length) {
        isParent = true;
        this.toggleChange(metric);
        if (!metric.show || metric.subId.includes(factor)) return;
        metric = metric.children[0];
      }
      if (dust_factorMap[metric.id]) {
        if (!isParent) {
          //处理位置这里，
          this.activeMetric = metric;
          this.isShow = true;
          // this.menuTop = event.clientY;
          // this.menuLeft = event.clientX;

          const containerRect = event.target.getBoundingClientRect();
          this.menuTop = containerRect.top;
          this.menuLeft = isSon ? containerRect.left - 170 : containerRect.left - 161;
          // this.menuHeight = event.target.clientHeight;
        }

        this.$sensors.track("CurrentFactor", {
          info: `${metric.text}`,
          mode: this.t2Mode,
          space_type: this.$route.name === "earth" ? '三维' : '二维',
          page_type: "沙尘",
        });
        this.$emit("change", metric);
        return;
      }

      this.setMode(TIANJI2_MERGE);
      if (metric.isPage) {
        this.$sensors.track("CurrentPage", {
          page_type: metric.routerName,
          space_type: this.$route.name === "earth" ? '三维' : '二维',
        });
        this.$router.push({ name: metric.routerName });
        return;
      }
      if (metric.children && metric.children.length) {
        return;
      }

      //切换的要素不是沙尘要素跳转到天机1/天机2
      if (!dust_factorMap[metric.id]) {
        if (factorMap[metric.id]) {
          //t2
          let mapType = modeFactorMap[TIANJI2_MERGE];
          if (!mapType.includes(metric.id)) {
            let str = "";
            for (let key in modeFactorMap) {
              let item = modeFactorMap[key];
              if (item.includes(metric.id) && !str) {
                str = key;
              }
            }

            if (!str.includes("天机1")) {
              this.$store.commit("map/setMode", str);
              this.$store.commit("map/setFactor", metric.id);
              this.$router.push({ name: "weather_hd" });
            } else {
              this.$store.commit("setMode", str);
              this.$store.commit("setFactor", metric.id);
              this.$router.push({ name: "weather" });
            }
          } else {
            this.$store.commit("map/setFactor", metric.id);
            this.$router.push({ name: "weather_hd" });
          }

          this.$sensors.track("CurrentFactor", {
            info: `${metric.text}`,
            mode: sessionStorage.getItem("tmode") === "t1" ? this.t1Mode : this.t2Mode,
            space_type: this.$route.name === "earth" ? '三维' : '二维',
            page_type: sessionStorage.getItem("tmode") === "t1" ? "天机1" : "天机2",
          });

          return;
        }
        this.$message("当前要素正在开发中，请耐心等待。");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #05f;
$grey-bg: rgba(50, 50, 50, 0.5);

.qrShow {
  position: fixed;
  z-index: 999;
  background-color: rgba(66, 66, 66, 0.85);
  width: auto;
  min-width: 93px;
  padding: 0 5px 0 5px;
  transform: translateY(-19%);
  height: auto;
  color: white;
  text-align: center;
  border-radius: 6px;
  font-family: sans-serif;
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid rgba(0, 0, 0, 0);
    border-left-color: rgba(66, 66, 66, 0.85);
    left: 99%;
    top: 50%;
    margin-top: -7px;
  }
}

.operate-menu {
  position: absolute;
  right: 40px;
  bottom: 110px;
  color: #fff;
  z-index: 10000;

  .more-icon.icon {
    position: absolute;
    right: -39px;
    top: -21px;
    cursor: move;
    background-color: rgba(50, 50, 50, 0.5);
    width: auto;
    word-break: keep-all;
    border-radius: 15px;
    padding: 0 15px;
    font-size: 14px;

    .metric_text {
      margin-right: 5px;
    }
  }

  .all-menu-wrap {
    position: fixed;
    right: -190px;
    padding: 27px 10px 10px 10px;
    top: 0;
    color: #fff;
    background-color: rgba(68, 65, 65, 0.84);
    font-size: 16px;
    margin-right: -5px;
    transition: all 0.5s;
    height: 94%;
    box-sizing: border-box;
    overflow: auto;

    .close {
      position: absolute;
      left: -30px;
      top: 6px;

      .svg-icon {
        font-size: 30px;
      }
    }

    .basetime,
    .region,
    .elevation {
      margin-top: 4px;
      padding: 5px;
      background: rgba($text-operate, $alpha: 0.92);
      border-radius: 8px;

      .label {
        font-size: 12px;
        color: #333;
        margin-right: 10px;
        cursor: move;
      }

      .text {
        color: #333;
      }

      .select_wrap {
        ::v-deep {
          .el-popper {
            z-index: 9999999;
          }
        }
        width: 164px;
      }
    }
  }

  .item {
    margin-bottom: 10px;
    transition: all 0.5s;
    //display: flex;

    &.second-item {
      display: flex;
      margin-left: 10px;
      font-size: 12px;
    }
  }

  .icon,
  .text {
    display: inline-block;
    line-height: 30px;
  }

  .is-active {
    span:first-child {
      background-color: $primary-color;
    }
  }

  .icon {
    width: 30px;
    height: 30px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .text {
    min-width: 80px;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    margin-left: 6px;
  }
}
</style>
