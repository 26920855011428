<template>
  <div class="basetime_select">
    <div class="label">
      起报
    </div>
    <div class="select_wrap">
      <el-select
        v-model="value"
        placeholder="起报时间"
      >
        <el-option
          v-for="(item, index) in baseTimeList"
          :key="index"
          :value="item"
          :label="formatTime(item)"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { factorMap, mapForCn, allMetrics, metrics } from '@/config/MapConfigT4';
import moment from 'moment';
import Bus from '@/bus/index';

export default {
  computed: {
    ...mapState('dust', ['t2Mode', 'currentBaseTime', 'factor', 'layerType']),
    ...mapGetters('dust', ['baseTime', 'currentMode']),
    baseTimeList() {
      return this.baseTime;
    },
    value: {
      get() {
        return this.currentBaseTime;
      },
      set(value) {
        const [time, model] = value.split(' ');
        this.setCurrentBaseTime(value);
        this.$sendPoint({
          category: 'button',
          action: '起报-天机2',
          opt_label: `${moment(time).format('YYYY.MM.DD HH时')} ${this.currentMode ? '' : model}`,
        });
        this.$sensors.track("CurrentStartTime", {
          info: mapForCn[this.factor],
          mode:  this.t2Mode,
          space_type: this.$route.name === "earth" ? '三维' : '二维',
          startTime: `${moment(time).format('YYYY.MM.DD HH时')} ${this.currentMode ? '' : model}`,
          page_type: '沙尘',
        });
        const ms = new Date().getTime() - new Date(time).getTime();
        const day = ms / 1000 / 60 / 60 / 24
        if (day > 60) {
          this.$sensors.track("times_of_60_days_ago", {
            info: mapForCn[this.factor],
            mode:  this.t2Mode,
            space_type: this.$route.name === "earth" ? '三维' : '二维',
            page_type: '沙尘',
          });
        }
        Bus.$emit('removePopup');
      },
    },
    isShowStyle: {
      get() {
        const arr = ['base_reflectivity', 'pratesfc', 'max_reflectivity', 'ri_min', 'prerrt', 'presrt', 'pregrt', 'preirt', 'prer', 'pres', 'preg', 'prei'];
        return arr.indexOf(this.factor) !== -1 && this.layerType !== 'shadow';
      },
    },
  },
  methods: {
    ...mapMutations('dust', ['setCurrentBaseTime']),
    formatTime(value) {
      const [time, model] = value.split(' ');
      return `${moment(time).format('YYYY.MM.DD HH时')} ${this.currentMode ? '' : model}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.basetime_select {
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 21px;
  //padding: 0 5px 0 15px;

  .label {
    font-size: 12px;
    color: #fff;
    margin-right: 5px;
    cursor: move;
    width: 30px;
  }

  .select_wrap {
    width: 164px;

    ::v-deep {
      .el-input__icon {
        line-height: 26px;
      }
    }
  }
}
</style>
