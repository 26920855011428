<template>
  <div id="map" ref="map" class="map" />
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import L from "leaflet";
import Bus from "@/bus/index";
import "leaflet-velocity/dist/leaflet-velocity.css";
import "@/plugins/LeafletVelocity/L.VelocityLayer";
import { getImage } from "@/api/image";
import { getBaseTime } from "@/api";
import { factorMap, factorPressure, HDMap, factorWind } from "@/config/MapConfig";
import { unitMap, geoserverUrl, unit } from "@/config/MapConfigT4";
import locateIcon from "@/assets/images/dot.png";
import { TIANDITU_KEY } from "@/config";
import ParseData from "./ParseData";
import ParseWind from "./ParseWind";
import ParseContour from "./ParseContour";

export default {
  data() {
    return {
      // 地图实例
      map: null,
      // 当前要素的分辨率，反向计算要素数据使用
      resolutionX: 0,
      resolutionY: 0,
      // 当前渲染的图片是否是高清图
      hd: false,
      // 当前渲染的图片对象
      image: null,
      // 当前是否在加载图片
      loadImage: false,
      popup: null,

      // 当前位置的维度
      currentLatitude: 35,

      // 当前位置的经度
      currentLogitude: 118,

      // 是否全屏
      fullScreen: false,
    };
  },
  computed: {
    ...mapState(["isMobile"]),
    ...mapState(["currentData", "factor", "status", "currentFactorData", "baseTime", "mapCenter"]),
    ...mapState("map", ["layerType", "windy", "city"]),
    ...mapGetters(["currentSlp", "currentWind"]),
    // 当前要素对应的key
    factorKey() {
      return factorMap[this.factor] || "temp";
    },
  },

  watch: {
    currentData: {
      handler(value) {
        if (!value.meta) return;
        this.render(!!HDMap[this.factor]);
      },
      immediate: true,
    },
    factor() {
      this.clearLayerAndOverlay("layer");
      this.clearLayerAndOverlay("contour");
    },
    status() {
      if(this.status === 'play') {
        this.render(!!HDMap[this.factor]);
      } else {
      //   setTimeout(() => {
      //     let index = this.currentFactorData.findIndex((item, index) => {
      //   return item.timestamp === this.currentData.timestamp;
      // }) + 1;
      // if (index >= this.currentFactorData.length) {
      //   index = 0;
      // }
      // this.setCurrentData(this.currentFactorData[index]);
      //   }, 500);
      }
    },
    windy() {
      if (this.windy === "normal") {
        if (!this.loadImage) {
          const hd = factorWind[this.factor] ? false : this.hd;
          const image = factorWind[this.factor] ? this.windImage : this.image;
          if (!image) {
            this.renderWind();
          } else {
            this.generateWindLayer(hd, image);
          }
        }
      } else {
        this.clearLayerAndOverlay("layer");
      }
    },
    // 监听城市图层控制标识变化
    city(value) {
      if (value === "none") {
        this.clearLayerAndOverlay("city");
      } else {
        this.cityLayer = this.cityLayer ? this.cityLayer : this.setCityLayer();
        this.map.addLayer(this.cityLayer);
      }
    },
    layerType(value) {
      this.clearLayerAndOverlay("toggleMap");
      if (value === "graph") {
        this.renderGeoLayer();
        this.map.getPane("mapLayerPane").style.zIndex = 20;
      } else if (value === "shadow") {
        this.renderTianDiMapLayer();
        this.map.getPane("mapLayerPane").style.zIndex = 20;
      } else {
        this.renderWindyMapLayer();
        this.map.getPane("mapLayerPane").style.zIndex = 51;
      }
    },
  },
  created() {
    this.realData = [];
    this.cancel = [];
    this.image = null;
    Bus.$on("clearLayerAndOverlay", this.clearLayerAndOverlay);
    Bus.$on("zoomIn", this.zoomIn);
    Bus.$on("zoomOut", this.zoomOut);
    Bus.$on("locate", this.manualLocation);
    Bus.$on("removePopup", this.removePopup);
    Bus.$on("setLocation", this.setLocation);
  },
  mounted() {
    this.initMap();

    // 定位到用户当前位置
    this.map.locate();
  },

  beforeDestroy() {
    Bus.$off("clearLayerAndOverlay", this.clearLayerAndOverlay);
    Bus.$off("zoomIn", this.zoomIn);
    Bus.$off("zoomOut", this.zoomOut);
    Bus.$off("locate", this.manualLocation);
    Bus.$off("removePopup", this.removePopup);
    Bus.$off("setLocation", this.setLocation);
    this.setCenter(this.map.getCenter());
    this.removeEvent();
    this.$refs.map.removeEventListener("click", this.handleMapClose);
  },

  methods: {
    ...mapMutations(["setShowMetric", "setCurrentData", "setBaseTime", "setCenter"]),
    ...mapMutations("map", ["setLoading"]),
    /**
     * @description 初始化地图
     * @returns {void}
     * @author yujie
     */
    initMap() {
      this.map = L.map("map", {
        crs: L.CRS.EPSG4326, // 坐标系--大地坐标系
        center: this.mapCenter ? this.mapCenter : [35, 118], // 坐标中心
        maxZoom: 9, // 最大倍数
        minZoom: 2, // 最小倍数
        zoom: localStorage.getItem("current-zoom") ? localStorage.getItem("current-zoom") : 2,
        // maxBounds: [[-90, -Infinity], [90, Infinity]], // 区域限制范围
        maxBounds: [
          [-90, 0],
          [90, 360],
        ], // 区域限制范围
        // maxBounds: [[5, 70], [70, 155]],
        maxBoundsViscosity: 1,
        attributionControl: false, // 是否去除右下角标志
        zoomControl: false, // 是否显示地图缩放图例
      });

      // 创建不同的窗口(由于zIndex无效)
      this.map.createPane("borderLayerPane");
      this.map.createPane("provinceLayerPane");
      this.map.createPane("cityLayerPane");
      this.map.createPane("windLayerPane");
      this.map.createPane("heatmapLayerPane");
      this.map.createPane("contourLayerPane");
      this.map.createPane("mapLayerPane");

      // 设置窗口对应层级
      this.map.getPane("cityLayerPane").style.zIndex = 100;
      this.map.getPane("provinceLayerPane").style.zIndex = 99;
      this.map.getPane("borderLayerPane").style.zIndex = 98;
      this.map.getPane("contourLayerPane").style.zIndex = 60;
      this.map.getPane("windLayerPane").style.zIndex = 50;
      this.map.getPane("heatmapLayerPane").style.zIndex = 30;
      this.map.getPane("mapLayerPane").style.zIndex = 51;

      this.addLayer();
      this.addEvent();
      this.convertMapByZoom();
    },

    convertMapByZoom() {
      this.map.on("zoomend", () => {
        const zoomlevel = this.map.getZoom();
        // localStorage.setItem('map-current-zoom', zoomlevel);
        localStorage.setItem("current-zoom", zoomlevel);
        if (zoomlevel === 9) {
          // 国界线图层 ，城市图层
          // this.cityLayer  this.borderLayer
          // if (this.cityLayer) {
          //   this.map.removeLayer(this.cityLayer);
          //   this.cityLayer = null;
          // }
          // // 国界图层 borderLayerPane
          // if (this.borderLayer) {
          //   this.map.removeLayer(this.borderLayer);
          //   this.borderLayer = null;
          //   this.borderLayer1 = L.tileLayer(
          //     `https://{s}.tianditu.gov.cn/DataServer?T=cta_c&X={x}&Y={y}&L={z}&tk=${TIANDITU_KEY}`,
          //     {
          //       zoomOffset: 1,
          //       subdomains: ["t1", "t2", "t3", "t4", "t5", "t6"],
          //       pane: "borderLayerPane",
          //       opacity: 0.7,
          //     }
          //   );
          //   this.map.addLayer(this.borderLayer1);
          // }
          // 国界线图层 ，城市图层
          // this.cityLayer  this.borderLayer
          if (this.cityLayer) {
            this.map.removeLayer(this.cityLayer);
            this.cityLayer = null;
          }
          // 国界图层 borderLayerPane
          if (!this.borderLayer1) {
            this.borderLayer1 = L.tileLayer(
                `https://{s}.tianditu.gov.cn/DataServer?T=cta_c&X={x}&Y={y}&L={z}&tk=${TIANDITU_KEY}`,
                {
                  zoomOffset: 1,
                  subdomains: ["t1", "t2", "t3", "t4", "t5", "t6"],
                  pane: "borderLayerPane",
                  opacity: 0.7,
                }
            );
            this.map.addLayer(this.borderLayer1);
          }
          if (this.borderLayer){
            this.map.addLayer(this.borderLayer);
            this.borderLayer =null;
          }

        } else {
          // if (this.borderLayer1) {
          //   this.map.removeLayer(this.borderLayer1);
          // }
          // if (!this.borderLayer && !this.cityLayer) {
          //   this.addCityAndBorderLayer();
          // }


          if (this.layerType === "default") {
            if (this.borderLayer) {
              this.map.removeLayer(this.borderLayer);
              // this.borderLayer1 = null
              this.borderLayer =null;
            }
          }

          if (this.borderLayer1) {
            this.map.removeLayer(this.borderLayer1);
            // this.borderLayer1 = null
            this.borderLayer1 =null;
          }

          if (!this.cityLayer) {
            this.addCityAndBorderLayer();
          }
        }

      });
    },

    /**
     * @description 添加图层
     * @returns {void}
     * @author yujie
     */
    addLayer() {
      // 创建国界图层
      if (this.layerType === "graph") {
        this.renderGeoLayer();
        this.map.getPane("mapLayerPane").style.zIndex = 20;
      } else if (this.layerType === "shadow") {
        this.renderTianDiMapLayer();
        this.map.getPane("mapLayerPane").style.zIndex = 20;
      } else {
        this.renderWindyMapLayer();
        this.map.getPane("mapLayerPane").style.zIndex = 51;
      }



      // 国、省、市、区、镇名称
      const invertFilter = ["grayscale:100%", "invert:100%"];

      // this.map.addLayer(this.borderLayer);
      if (this.city === "normal") {
        this.cityLayer = L.tileLayer.colorFilter(
          `https://{s}.tianditu.gov.cn/DataServer?T=cta_c&X={x}&Y={y}&L={z}&tk=${TIANDITU_KEY}`,
          {
            subdomains: ["t1", "t2", "t3", "t4", "t5", "t6", "t7"],
            zoomOffset: 1, // For Tianditu
            pane: "cityLayerPane",
            opacity: 0.8,
            filter: invertFilter,
          }
        );
        this.map.addLayer(this.cityLayer);
      }
    },

    addCityAndBorderLayer() {
      if (this.city === "normal") {
        this.cityLayer = this.setCityLayer();
        this.map.addLayer(this.cityLayer);
      }
    },

    /**
     * 国界图层
     * @returns {*}
     */
    setCountryBorder() {
      const url = `${geoserverUrl}/line-map/{z}/{x}/{y}.pbf`;
      const borderLayer = L.vectorGrid.protobuf(url, {
        rendererFactory: L.canvas.tile,
        pane: "borderLayerPane",
        vectorTileLayerStyles: {
          interactive: true,
          world_line(properties) {
            if (properties.contry_sea === 1) {
              return {
                opacity: 0.9,
                fillColor: "#333",
                fillOpacity: 1,
                fill: true,
                color: "#333",
                weight: 1.2,
              };
            }

            return {
              opacity: 0.9,
              fillColor: "#333",
              fillOpacity: 1,
              fill: true,
              color: "#333",
              weight: 0.3,
            };
          },
          world_line_prov(properties) {
            if (properties.country_level === 1) {
              return {
                color: "#1d1d1d",
                weight: 1,
              };
            }
            return {
              color: "#333",
              dashArray: "2, 4",
              weight: 1,
            };
          },
          world_line_city(properties) {
            // 地形图配色
            if (properties.country_level === 3) {
              return {
                color: "#999",
                dashArray: "2, 6",
                weight: 1,
              };
            }
            if (properties.country_level === 4) {
              return {
                color: "#ccc",
                dashArray: "2, 8",
                weight: 1,
              };
            }
            if (properties.country_level === 2) {
              return {
                color: "#333",
                dashArray: "2, 4",
                weight: 1.2,
              };
            }
            return {
              color: "#1d1d1d",
              weight: 1,
            };
          },
        },
      });
      return borderLayer;
    },

    /**
     * 构建城市图层
     */
    setCityLayer() {
      // 国、省、市、区、镇名称
      const invertFilter = ["grayscale:100%", "invert:100%"];

      const cityLayer = L.tileLayer.colorFilter(
        `https://{s}.tianditu.gov.cn/DataServer?T=cva_c&X={x}&Y={y}&L={z}&tk=${TIANDITU_KEY}`,
        {
          subdomains: ["t1", "t2", "t3", "t4", "t5", "t6"],
          zoomOffset: 1, // For Tianditu
          pane: "cityLayerPane",
          opacity: 0.8,
          filter: invertFilter,
        }
      );

      return cityLayer;
    },

    addPopupLayer({ lat, lon }) {
      if (this.popup) {
        this.removePopup();
      }
      const data =
        (parseInt((90 - lat) / this.resolutionY) * 360) / this.resolutionX +
        parseInt(lon / this.resolutionX);
      if (this.realData.length) {
        const content = this.realData[data];
        // 获取当前地图可视区域的范围
        const { min, max } = this.map.getPixelBounds();
        // 获取当前位置的坐标
        const { x, y } = this.map.project({ lat, lon }, this.map.getZoom()).floor();
        this.popup = L.popup({
          className: y - min.y < 140 ? "custom-popup custom-popup-sepcial" : "custom-popup",
          closeButton: false,
        });
        const latS = parseFloat(lat) > 0.0 ? 'N' : 'S';
        const lngS = parseFloat(lon) > 0.0 ? 'E' : 'W';
        const unitDesc = unit[this.factorKey];
        this.popup
          .setLatLng({ lat, lon })
          .setContent(
            `
          <div class="custom-popup-content">
            <div style="font-size: 13px">${Math.abs(lon).toFixed(3)}${lngS},${Math.abs(lat).toFixed(3)}${latS} </div>
            <div class="line"></div>
            <div class="close el-icon-circle-close custom-popup-close"></div>
            <div class="custom-popup-text">
                <div style="font-size: 18px;font-weight: 500">
                    <span id="factor-value">${content.toFixed(2)}</span>
                    <span style="font-size: 14px;margin-bottom: 2px">${unitDesc}</span>
                </div>
            </div>
          </div>
          `
          )
          // .setContent(unitMap[this.factorKey](content))
          .openOn(this.map);
      }
    },
    /**
     * @description 添加地图事件
     * @returns {void}
     * @author yujie
     */
    addEvent() {
      this.map.on('zoomend', () => {
        this.renderWind();
      });
      this.map.on("click", (event) => {
        const { lat, lng: lon } = event.latlng;
        this.setShowMetric(false);
        this.addPopupLayer({ lat, lon });
      });

      L.control
        .scale({
          imperial: false,
          maxWidth: 80,
          position: this.isMobile ? "topright" : "bottomleft",
        })
        .addTo(this.map);

      // 定位到用户当前位置
      const dotIcon = L.divIcon({
        className: "blue-circle-icon",
        iconSize: [10, 10], // 设置图标大小
        html: '<div style="background-color: #156FCF; width: 10px; height: 10px; border-radius: 50%;border: 2px solid white;"></div>', // 使用HTML来定义圆形图标
      });
      // this.map.on('locationfound', (position) => {
      //   const locationMarker = L.marker([position.latitude, position.longitude], { icon: dotIcon });
      //   // locationMarkerbindPopup('Your are here :)');
      //   locationMarker.addTo(this.map);
      //   this.currentLatitude = position.latitude;
      //   this.currentLogitude = position.longitude;
      // });
      // this.map.on('locationerror', (e) => {
      // console.error('Geolocation定位失败，将使用腾讯地图进行定位', e);
      // 使用腾讯地图定位
      this.tencentMapLocation(dotIcon);
      // });

      this.map.on("mousemove", (e) => {
        const {lat, lng} = e.latlng;
        const latS = parseFloat(lat) > 0.0 ? 'N' : 'S';
        const lngS = parseFloat(lng) > 0.0 ? 'E' : 'W';
        const lnglatText = Math.abs(lng).toFixed(2) + lngS + '\xa0\xa0'
          + Math.abs(lat).toFixed(2) + latS;
        this.$emit('mapMouseMove',lnglatText)
      })

      this.$refs.map.addEventListener("click", this.handleMapClose);
    },
    handleMapClose(e) {
      const { className } = e.target;
      if (className && className.indexOf("custom-popup-close") > -1) {
        this.removePopup();
      }
      e.stopPropagation();
    },

    setLocation(position) {
      const { lat, lon } = position;
      // if (this.locationMarker) {
      //   this.locationMarker.remove();
      // }
      //
      // this.locationMarker = L.marker([lat, lon], {
      //   icon: L.icon({
      //     iconUrl: locationIcon,
      //     className: 'locatedIcon',
      //     iconSize: [20, 20],
      //   }),
      // });
      // this.locationMarker.addTo(this.map);
      if (this.status === "pause") {
        this.addPopupLayer({ lat, lon });
        this.map.flyTo([lat, lon], 7);
      }
    },

    /**
     * @description 使用腾讯地图进行定位
     * @returns {void}
     * @author yunpengliu
     */
    tencentMapLocation(dotIcon) {
      this.$jsonp("https://apis.map.qq.com/ws/location/v1/ip", {
        key: "UMKBZ-SSVK2-TSMUO-CIJ3D-FE4F6-2KFSI",
        output: "jsonp",
      })
        .then((res) => {
          if (res.status === 0) {
            const locationMarker = L.marker([res.result.location.lat, res.result.location.lng], {
              icon: dotIcon,
            });
            // locationMarkerbindPopup('Your are here :)');
            locationMarker.addTo(this.map);
            // this.map.flyTo([res.result.location.lat, res.result.location.lng], 3);
            this.currentLatitude = res.result.location.lat;
            this.currentLogitude = res.result.location.lng;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },

    /**
     * @description 渲染地图图层
     * @param {Boolean} hd 是否渲染高清图层
     * @returns {void}
     * @author yujie
     */
    async render(hd) {
      let index = this.currentFactorData.findIndex((item, index) => {
        return item.timestamp === this.currentData.timestamp;
      }) + 1;
      if (index >= this.currentFactorData.length) {
        index = 0;
      }
      const isPlay = this.status === 'play';
      const currentData = this.status === 'play' ? this.currentFactorData[index] : this.currentData;
      console.log('currentData', currentData);
      this.hd = hd;
      this.loadImage = true;
      if (this.cancel && typeof this.cancel === "function") this.cancel();
      if (this.cancelWind && typeof this.cancelWind === "function") {
        this.cancelWind();
      }
      let dataURL = null;
      let slpURL = null;
      if (!hd && currentData.image) {
        dataURL = currentData.image;
      } else if (!hd && currentData.getImage) {
        dataURL = await currentData.getImage;
      } else {
        const promise = [
          getImage(hd ? currentData.imagePath : currentData.thumbnailPath, (c) => {
            this.cancel = c;
          }),
        ];
        if (factorPressure[this.factor] && this.currentSlp) {
          promise.push(
            getImage(hd ? this.currentSlp.imagePath : this.currentSlp.thumbnailPath, (c) => {
              this.cancel = c;
            })
          );
        }
        [dataURL, slpURL] = await Promise.all(promise);
      }

      this.image = new Image();
      this.image.src = dataURL;
      this.image.addEventListener("load", () => {
        this.loadImage = false;
        this.generateImage(hd, this.image);
        if (!hd && this.status === "pause") {
          this.render(true);
        }

        if (hd && factorWind[this.factor] && this.status === "pause" && this.windy === "normal") {
          this.renderWind();
        }

        if (this.factorKey === "wind" && this.status === "pause" && this.windy === "normal") {
          this.generateWindLayer(hd, this.image);
        }

        if (this.status === "play" || isPlay) {
          this.next();
        }
      });

      if (slpURL) {
        const slpImage = new Image();
        slpImage.src = slpURL;
        slpImage.addEventListener("load", () => {
          this.generateContourLayer(hd, slpImage);
        });
      }
      this.setLoading(false);
    },

    /**
     * @description 渲染风场
     * @returns {Promise}
     * @author yujie
     */
    async renderWind() {
      if (!this.currentWind) return;
      const wind = await getImage(this.currentWind.thumbnailPath, (c) => {
        this.cancelWind = c;
      });
      this.windImage = new Image();
      this.windImage.src = wind;
      this.windImage.addEventListener("load", () => {
        this.generateWindLayer(false, this.windImage);
      });
    },

    /**
     * @description 渲染等值线
     * @returns {Promise}
     * @author yujie
     */
    async renderContourLayer() {
      const slpURL = await getImage(this.currentSlp.imagePath);

      const slpImage = new Image();
      slpImage.src = slpURL;
      slpImage.addEventListener("load", () => {
        this.generateContourLayer(true, slpImage);
      });
    },

    /**
     * @description 生成热力图
     * @param {Boolean} hd 是否是高清图
     * @param {Image} image 单通道图片对象
     * @returns {void}
     * @author yujie
     */
    generateImage(hd, image) {
      const surfaceData = this.currentData.meta;
      const data = { image, ...surfaceData };
      data.height = surfaceData[hd ? "height" : "theight"];
      data.width = surfaceData[hd ? "width" : "twidth"];
      data.origionx = 0;
      data.origiony = 0;
      data.level = this.factorKey === "wind" || this.factor === "wgrd10m" ? 2 : 0;
      this.resolutionX = 360 / data.width;
      this.resolutionY = 180 / data.height;
      const handler = new ParseData(data, this.factorKey, 360 / data.width, 180 / data.height);
      const base64 = handler.setData();
      this.realData = handler.realData;
      if (this.imageOverlay) {
        this.imageOverlay.setUrl(base64);
      } else {
        this.imageOverlay = L.imageOverlay(
          base64,
          [
            [-90, 0],
            [90, 360],
          ],
          {
            opacity: 1,
            zIndex: 10,
            pane: "heatmapLayerPane",
            noWrap: false,
            className: "custom_image",
          }
        ).addTo(this.map);
      }
    },

    /**
     * @description 生成风流场图层
     * @param {Boolean} hd 是否是高清图
     * @param {Image} image 单通道图片对象
     * @returns {void}
     * @author yujie
     */
    generateWindLayer(hd, image) {
      const surfaceData = (factorWind[this.factor] ? this.currentWind : this.currentData).meta;
      const width = surfaceData[hd ? "width" : "twidth"];
      const height = surfaceData[hd ? "height" : "theight"];
      const data = {
        min: surfaceData.min,
        max: surfaceData.max,
        width,
        height,
        umin: surfaceData.umin,
        umax: surfaceData.umax,
        vmin: surfaceData.vmin,
        vmax: surfaceData.vmax,
        origionxv: 0,
        origionyu: 0,
        origionxu: 0,
        origionyv: 0,
        levelu: 0,
        levelv: 1,
        solutionX: 360 / width,
        solutionY: 180 / height,
        image,
      };
    
      const windOptions = {
        colorScale: [
          "rgba(255, 255, 255, 0.2)",
          "rgba(255, 255, 255, 0.3)",
          "rgba(255, 255, 255, 0.4)",
          "rgba(255, 255, 255, 0.5)",
          "rgba(255, 255, 255, 0.6)",
          "rgba(255, 255, 255, 0.7)",
        ],
      };
      // console.time('windy');
      const zoom = localStorage.getItem("current-zoom") || 2
      const scaleFactor = zoom > 4 ? zoom : 1;
      const windData = new ParseWind(data).getData();
      // console.timeEnd('windy');
      if (this.windLayer) {
        // console.time('setData');
        this.windLayer.setData(windData);
        this.windLayer.setOptions({ particleMultiplier: 1 / (400 * scaleFactor) })
        // console.timeEnd('setData');
      } else {
        this.windLayer = L.velocityLayer(
          Object.assign(windOptions, {
            lineWidth: 1,
            data: windData,
            velocityScale: 0.01,
            maxVelocity: 10,
            minVelocity: 1,
            particleMultiplier: 1 / (400 * scaleFactor),
            frameRate: 20,
            paneName: "windLayerPane",
          })
        );

        this.map.addLayer(this.windLayer);
        // 由于以下四个事件需要windLayer定义以后才可以注册，所以进行延迟注册
        this.addEventDelay();
      }
    },

    /**
     * @description 延迟注册地图拖动及缩放事件，保证风场图层已加载完毕
     * @returns {void}
     * @author yunpengliu
     */
    addEventDelay() {
      this.map.on("zoomstart", this.clearWind);
      this.map.on("zoom", this.clearWind);
      this.map.on("zoomend", this.clearAndRestartWind);
      this.map.on("movestart", this.clearWind);
      this.map.on("move", this.clearWind);
      this.map.on("moveend", this.clearAndRestartWind);
    },
    /**
     * @description 清除风粒子
     * @returns {void}
     * @author yunpengliu
     */
    clearWind() {
      this.windLayer._clearWind();
    },
    /**
     * @description 清除风粒子并重新渲染
     * @returns {void}
     * @author yunpengliu
     */
    clearAndRestartWind() {
      this.windLayer._clearAndRestart();
    },
    /**
     * @description 生成风流场图层
     * @param {Boolean} hd 是否是高清图
     * @param {Image} image 单通道图片对象
     * @returns {void}
     * @author yujie
     */
    generateContourLayer(hd, image) {
      const surfaceData = this.currentSlp.meta;
      const width = surfaceData[hd ? "width" : "twidth"];
      const height = surfaceData[hd ? "height" : "theight"];
      const data = {
        ...surfaceData,
        width,
        height,
        image,
        origionx: 0,
        origiony: 0,
        level: 0,
      };

      const contourData = new ParseContour(data).getData();
      if (this.contourLayer) {
        this.contourLayer.setData(contourData);
      } else {
        this.contourLayer = new L.ContourLayer({
          solutionX: 360 / width,
          solutionY: 180 / height,
          isclip: false,
          isDrawLeftRight: true,
          showLevel: 4, // 文字的显示级别
          stroke: true,
          color: "#00f", // '#61A5E8',
          weight: 0.6,
          opacity: 0.5,
          lineCap: "round",
          lineJoin: "round",
          fill: false,
          fontSize: "10px",
          fontWeight: 500,
          fontFamily: "Microsoft YaHei",
          fontColor: "#3e4043",
          fontStrokeSize: 0,
          fontStrokeColor: "#484649",
          data: contourData,
          xishu: 20,
          pane: "contourLayerPane",
        }).addTo(this.map);
      }
    },

    /**
     * @description 清除新增的热力图层和风流场图层
     * @param {String} type 删除图层的类型 image:热力图图层 layer：风流场图层 不传两者全部删除
     * @returns {void}
     * @author yujie
     */
    clearLayerAndOverlay(type) {
      if (this.imageOverlay && (type === "image" || !type)) {
        this.map.removeLayer(this.imageOverlay);
        this.imageOverlay = null;
      }

      if (this.windLayer && (type === "layer" || !type)) {
        this.map.off("zoomstart", this.clearWind);
        this.map.off("zoom", this.clearWind);
        this.map.off("zoomend", this.clearAndRestartWind);
        this.map.off("movestart", this.clearWind);
        this.map.off("move", this.clearWind);
        this.map.off("moveend", this.clearAndRestartWind);

        this.map.removeLayer(this.windLayer);
        this.windLayer = null;
      }

      if (this.contourLayer && (type === "contour" || !type)) {
        this.map.removeLayer(this.contourLayer);
        this.contourLayer = null;
      }

      // change 地形图 还是天地图
      if (type === "toggleMap") {
        if (this.mapLayer) this.map.removeLayer(this.mapLayer);
        this.mapLayer = null;
      }

      if (type === "city") {
        if (this.cityLayer) this.map.removeLayer(this.cityLayer);
      }
    },

    /**
     * @description 播放下一张
     * @returns {void}
     * @author yujie
     */
    async next() {
      let index = this.currentFactorData.findIndex((item, index) => {
        return item.timestamp === this.currentData.timestamp;
      }) + 1;
      if (index >= this.currentFactorData.length) {
        index = 0;
        const baseTime = await getBaseTime(this.factor);
        if (baseTime && baseTime[0] !== this.baseTime[0]) {
          this.setBaseTime(baseTime);
          await this.$parent.getFactorcast(baseTime[0], false);
        }
      }
      this.setCurrentData(this.currentFactorData[index]);
    },

    zoomIn() {
      this.map.zoomIn();
    },

    zoomOut() {
      this.map.zoomOut();
    },

    /**
     * @description 手动定位到用户当前所在位置
     * @returns {void}
     * @author yujie
     */
    manualLocation() {
      this.map.flyTo([this.currentLatitude, this.currentLogitude], 7);
    },

    removePopup() {
      if (this.popup) {
        this.map.removeLayer(this.popup);
        // this.popup?.remove();
      }
    },

    /**
     * @description 移除地图事件
     * @returns {void}
     * @author yujie
     */
    removeEvent() {
      this.map.off("move");
      this.map.off("mousemove");
    },

    renderGeoLayer() {
      if (!this.borderLayer) {
        this.borderLayer = this.setCountryBorder();
        this.map.addLayer(this.borderLayer);
      }
      if (!this.mapLayer) {
        // this.mapLayer = L.tileLayer.wms(`${geoserverUrl}/geoserver/gwc/service/wms`, {
        //   layers: 'hpc:hillshade',
        //   format: 'image/png8',
        //   transparent: true,
        //   tiled: true,
        //   pane: 'mapLayerPane',
        //   // opacity: 0.7,
        // });
        this.mapLayer = L.tileLayer("/hillshade/{z}/{x}/{y}.png", {
          zoomOffset: 1,
          pane: "mapLayerPane",
          className: "hillshade-class-img-map",
        });
        this.map.addLayer(this.mapLayer);
      }
    },
    setWindyLayer() {
      const windyshade = L.tileLayer("https://www.tjweather.com/clear/{z}/{x}/{-y}.png", {
        zoomOffset: 1,
        maxNativeZoom: 8,
        pane: "mapLayerPane",
      });
      return windyshade;
    },
    renderWindyMapLayer() {
      if (this.borderLayer){
        this.map.removeLayer(this.borderLayer);
        this.borderLayer = null;
      }
      this.mapLayer = this.setWindyLayer();
      this.map.addLayer(this.mapLayer);
    },
    renderTianDiMapLayer() {
      if (!this.borderLayer) {
        this.borderLayer = this.setCountryBorder();
        this.map.addLayer(this.borderLayer);
      }
      if (!this.mapLayer) {
        // 卫星影像图层
        // this.mapLayer = L.tileLayer(`https://{s}.tianditu.gov.cn/DataServer?T=img_c&X={x}&Y={y}&L={z}&tk=${TIANDITU_KEY}`, {
        //   subdomains: ['t1', 't2', 't3', 't4', 't5', 't6', 't7'],
        //   zoomOffset: 1, // For Tianditu
        //   opacity: 1,
        //   pane: 'mapLayerPane',
        // });
        this.mapLayer = L.tileLayer("/satellite/{z}/{x}/{y}.png", {
          // 链接要改对应的
          zoomOffset: 1,
          pane: "mapLayerPane",
          className: "satellite-class-img-map",
        });
        this.map.addLayer(this.mapLayer);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}

.custom_image {
  image-rendering: pixelated;
  border-right: 1px solid rgba($color: #fff, $alpha: 0);
}

::v-deep {
  .leaflet-popup-content-wrapper {
    position: absolute;
    //left: 60%;
    top: -80px;
    left: -68px;
    margin-left: -1px;
    //border-radius: 0 40px 40px 0;
    border-radius: 5px;
    background-color: rgba($text-operate, 0.92);
    color: $text-menu;

    .leaflet-popup-content {
      width: 149px;
      margin: 5px 10px;

      .line {
        border-left: 2px solid rgba($text-operate, 1);
        //height: 140px;
        height:35px;
        position: absolute;
        //left: 0;
        //top: 0;
        left: 68px;
        top: 50px;
      }

      .close {
        position: absolute;
        font-size: 20px;
        right: -20px;
        top: 0;
        color: $text-operate;
        cursor: pointer;
      }

      .custom-popup-content {
        .custom-popup-text {
          //display: flex;
          //align-items: center;
          //white-space: nowrap;
          //justify-content: space-between;

          .current-value {
            font-size: 15px;
            font-weight: 500
          }
          .current-factor {
            font-size: 12px;
          }

          .more-factor-data {
            width: 30px;
            height: 30px;
            //margin-left:10px;
            text-align: center;
            line-height: 30px;
            border-radius: 50%;
            background-color: $theme-color;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }

  .custom-popup-sepcial > .leaflet-popup-content-wrapper {
    top: 50px;

    .leaflet-popup-content {
      .line {
        top: -35px;
      }
    }
  }

  .leaflet-popup-tip-container {
    top: -10px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .leaflet-popup-tip {
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: $theme-color;
      box-sizing: border-box;
      border: 2px solid $text-operate;
      margin: 0 auto;
    }

    .pulsating-icon {
      animation: pulsate .5s ease-out;
      -webkit-animation: pulsate .5s ease-out;
      animation-iteration-count: 3;
      -webkit-animation-iteration-count: 3;
      border: 3px solid #fff;
      pointer-events: none;
      height: 28px;
      width: 28px;
      margin-left: -14px;
      margin-top: -14px;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      opacity: 0
    }

    .pulsating-icon.repeat {
      animation: pulsate 2s ease-out;
      -webkit-animation: pulsate 2s ease-out;
      /* animation-iteration-count:infinite; */
      -webkit-animation-iteration-count: infinite;
    }
  }

  .more-factor-data {
    color: #0078a8;
    cursor: pointer;
  }

  .leaflet-left {
    bottom: 45px;

    .leaflet-control-scale {
      margin-left: 5px;
    }
  }

  .leaflet-right {
    .leaflet-control-scale {
      margin-right: 22px;
    }
  }

  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2,1.2);
      opacity: 0;
    }
  }
}
</style>
