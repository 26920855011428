<template>
  <div class="basetime_select">
    <div class="label">
      起报
    </div>
    <div class="select_wrap">
      <el-select
        v-model="time"
        placeholder="起报时间"
      >
        <el-option
          v-for="item in baseTime"
          :key="item.baseTimeModel"
          :label="item | formatTime"
          :value="item.baseTimeModel"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import {mapForCn } from "@/config/MapConfigT4";
import moment from 'moment';
import Bus from '@/bus/index';

export default {
  filters: {
    formatTime(value) {
      const mode = value.mode === 'early' ? 'DA' : 'ND';
      return `${moment(value.baseTime).format('YYYY-MM-DD HH时')} ${mode}`;
    },
  },
  computed: {
    ...mapState(['t1Mode', 'baseTime', 'currentBaseTime']),
    baseTimeMap() {
      return this.baseTime.reduce((target, item) => {
        target[item.baseTimeModel] = item;
        return target;
      }, {});
    },
    time: {
      get() {
        return this.currentBaseTime.baseTimeModel;
      },

      set(value) {
        const time = this.baseTimeMap[value];
        this.setCurrentBaseTime(time);
        this.$sendPoint({
          category: 'button',
          action: '起报-天机1',
          opt_label: `${time.baseTimeModel}`,
        });
        this.$sensors.track("CurrentStartTime", {
          info: mapForCn[this.factor],
          mode:  this.t1Mode,
          space_type: this.$route.name === "earth" ? '三维' : '二维',
          startTime:  `${time.baseTimeModel}`,
          page_type: '天机1',
        });
        const ms = new Date().getTime() - new Date(time.baseTime).getTime();
        const day = ms / 1000 / 60 / 60 / 24
        if (day > 60) {
          this.$sensors.track("times_of_60_days_ago", {
            info: mapForCn[this.factor],
            mode:  this.t1Mode,
            space_type: this.$route.name === "earth" ? '三维' : '二维',
            page_type: '天机1',
          });
        }
        Bus.$emit('getFactorcast', time, true);
      },
    },
  },

  watch: {
    baseTime() {
      const current = this.baseTime[0];
      this.value = current.baseTimeModel;
      // this.setCurrentBaseTime(current);
    },
  },
  methods: {
    ...mapMutations(['setCurrentBaseTime']),
  },
};
</script>

<style lang="scss" scoped>
.basetime_select {
  height: 30px;
  //padding: 0 5px 0 15px;
  display: flex;
  align-items: center;
  border-radius: 4px;

  .label {
    font-size: 12px;
    color: #fff;
    width: 30px;
    //margin-right: 10px;
    cursor: move;
  }

  .select_wrap {
    width: 164px;

    ::v-deep {
      .el-input__icon {
        line-height: 26px;
      }
      .el-input__inner {
        color: #ffffff;
        border-radius: 4px;
        border: none;
      }
    }
  }
}
</style>
