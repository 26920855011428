import { httpt3 as http } from './http';

/**
 * @description 获取区域列表
 * @returns {Promise}
 * @author yujie
 */
export const getRegion = () => http.get('/region/list');

/**
 * @description 根据要素和预报时间获取要素预报
 * @param baseTime
 * @param {String} factorCode 要素id
 * @returns {Promise}
 * @author yujie
 */
export const getFactorcast = (baseTime, factorCode) => http.get('/factor/forecast', { params: { baseTime, factorCode } });

/**
 * @description 获取风场距离当前时间最近的数据
 * @returns {Promise}
 * @author yujie
 */
export const getCurrentWind = () => http.get('/factor/3d/wind');

/**
 * @description 瓦片通过factor获取数据
 * @param {String} factorCode 要素code
 * @param {String} regionCode 区域id
 * @returns {Promise}
 * @author yujie
 */
export const getDataByFactor = (factorCode, regionCode) => http.get('/factor/forecast-available', { params: { factorCode, regionCode } });
