<template>
  <div class="mode" :style="isMobile ? 'right: 0;bottom: 72px;' : ''">
    <div
      v-for="item in types"
      :key="item"
      class="item"
      :style="isMobile ? 'padding: 0px 10px;' : ''"
      :class="t2Mode === item ? 'active' : ''"
      @click="setModeType(item)"
    >
      {{ getName(item) }}
      <div class="text" v-show="t2Mode === item">{{ "12km" }}</div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { factorMap, mapForCn, allMetrics, metrics } from '@/config/MapConfigT4';
import { TIANJI2_MERGE, TIANJI2_DA, TIANJI2_ND, TIANJI1_DA, TIANJI1_ND } from "@/config";
import { drag } from "@/utils/drag";

export default {
  data() {
    return {
      types: [TIANJI2_MERGE, TIANJI2_ND, TIANJI2_DA],
      disableClick: true,
      isMove: false,
    };
  },
  computed: {
    ...mapState("dust", ["factor", "t2Mode"]),
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  mounted() {
    drag(this.$el);
    this.$el.addEventListener("mousedown", (event) => {
      this.handleMouseDown();
    });
    this.$el.addEventListener("mouseup", (event) => {
      this.handleMouseUp();
      setTimeout(() => {
        this.isMove = false;
      }, 500);
    });
  },
  methods: {
    ...mapMutations("dust", ["setMode"]),
    // 组件中的方法
    handleMouseDown() {
      this.$el.addEventListener("mousemove", this.handleMouseMove);
    },
    handleMouseMove(event) {
      // 监听鼠标移动事件时的逻辑
      this.isMove = true;
    },
    handleMouseUp() {
      this.$el.removeEventListener("mousemove", this.handleMouseMove);
    },
    setModeType(item) {
      if(this.isMove) return;
      this.$sensors.track("currentMode", {
        info: mapForCn[this.factor],
        mode: this.t2Mode,
        space_type: this.$route.name === "earth" ? '三维' : '二维',
        page_type: "天机2",
      });
      this.$sensors.track("Jump_volume", {
        from_factor: mapForCn[this.factor],
        from_mode: this.t2Mode,
        from_space_type: this.$route.name === "earth" ? '三维' : '二维',
        to_mode: item,
        to_factor: mapForCn[this.factor],
        to_space_type: this.$route.name === "earth" ? '三维' : '二维',
      });
      if (item === TIANJI1_DA || item === TIANJI1_ND) {
        sessionStorage.setItem("tmode", "t1");
        const factor = this.factor === "tmp2m" ? "t2mz" : this.factor;
        if (factorMapT1[factor]) {
          this.setFactor(factor);
        }
        this.$store.commit("setMode", item);
        this.$router.push({ name: "weather" });
        // this.setFactor
      } else {
        sessionStorage.setItem("tmode", "t2");
        this.$store.commit("map/setMode", item);
        this.setMode(item);

      }
      // if (item !== TIANJI2_MERGE) {
      //   if (item !== TIANJI1_DA && item !== TIANJI1_ND) {
      //     sessionStorage.setItem("tmode", "t2");
      //     // if (item === TIANJI2_MERGE && this.factor === "t2mz") {
      //     //   this.setFactor("tmp2m");
      //     // } else {
      //     //   this.setFactor(this.factor);
      //     // }
      //     this.$sensors.track("currentMode", {
      //       info: mapForCn[this.$store.state['map']['factor']],
      //       space_type: this.$route.name === "earth" ? '三维' : '二维',
      //       mode: item,
      //       page_type: "天机2",
      //     });
      //     this.$store.commit("map/setMode", item);
      //     this.$router.push({ name: "weather_hd" });
      //   } else {
      //     sessionStorage.setItem("tmode", "t1");
      //     // const factor = this.factor === "tmp2m" ? "t2mz" : this.factor;
      //     // if (factorMapT1[factor]) {
      //     //   this.setFactor(factor);
      //     // }
      //     this.$sensors.track("currentMode", {
      //       info: mapForCn[this.$store.state["factor"]],
      //       space_type: this.$route.name === "earth" ? '三维' : '二维',
      //       mode: item,
      //       page_type: "天机2",
      //     });
      //     this.$store.commit("setMode", item);
      //     this.$router.push({ name: "weather" });
      //   }

      //   // this.setFactor
      // } else {
      //   sessionStorage.setItem("tmode", "t1");
      //   this.setMode(item);
      //   this.$sensors.track("currentMode", {
      //     info: mapForCn[this.factor],
      //     space_type: this.$route.name === "earth" ? '三维' : '二维',
      //     mode: item,
      //     page_type: "沙尘",
      //   });
      // }
    },
    getName(text) {
      return text.replace(2,1);
    },
  },
};
</script>

<style scoped lang="scss">
.mode {
  position: fixed;
  z-index: 998;
  right: 79px;
  bottom: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 6px;
  .text {
    font-size: 8px;
    margin-left: 5px;
  }

  .item {
    padding: 0 16px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    border-radius: 6px;
    text-shadow: 0px 0px 4px black;
  }

  .active {
    background-color: $theme-color;
    display: flex;
    padding: 0 11px;
  }
}
</style>
