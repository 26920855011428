<template>
  <div style="height: 30px; width: 100%; margin-left: 20px; margin-top: 22px">
    <div class="timeline">
      <div
        class="target"
        :style="{
          left: `${indexFlag * (100 / (timeType.timeLength - 1)) + 0.2}%`,
        }"
      >
        <div class="box" />

        <div v-if="date" class="datetime" :class="{ last_tip: isLast || indexFlag === (timeType.timeLength - 1) }">
          {{ date }}
        </div>
      </div>
      <div class="time_wrap">
        <div
          v-for="i in 24"
          :key="i"
          class="time_item"
          :class="{
            active: i === indexFlag + 1,
            animation: i <= indexFlag,
          }"
          :style="canClick(i, timeType.type)"
        >
          <div class="clickDiv" @click="timeClick(i)" :style="canClick(i, timeType.type)"/>
          <div
            class="circle"
            :class="{
              animation: i <= indexFlag,
            }"
            :style="canClick(i, 1)"
          />
          <div v-if="i % 2 || timeType.type > 1" class="word satellite" :style="canClick(i, timeType.type, 'word')">
            {{ (i - 1) | getTime(timeType.type) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";

export default {
  filters: {
    getTime(value, type) {
      return `${value * type < 10 ? `0${value * type}` : value * type}:00`;
    },
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    color: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isClick: false,
      isLast: false,
    };
  },
  computed: {
    ...mapState("dust", ["status"]),
    ...mapState("dust", [
      "timeType", "currentBaseTime"
    ]),
    ...mapGetters("dust", ["currentTime"]),
    ...mapGetters("satellite", ["currentSatelliteTime"]),
    indexFlag() {
      return this.index;
    },
    date() {
        return moment(this.currentTime).format("YYYY-MM-DD HH:00");
    },
    currentStartTime() {
      const time = (this.currentBaseTime ? this.currentBaseTime.split(' ')[0] : '') || sessionStorage.getItem('baseTime')
      return moment(time).format("YYYY-MM-DD HH:00");
    }
  },
  methods: {
    timeClick(i) {
      this.isClick = true;
      if (i === this.timeType.timeLength) {
        this.isLast = true;
      } else {
        this.isLast = false;
      }
      this.$emit("change", i);
    },
    canClick(i, type, typ) {
      // console.log(this.currentStartTime.split(' ')[0],'currentStartTime')
      if (this.currentStartTime.split(' ')[0] === moment(this.currentTime).format("YYYY-MM-DD")) {
        const hour = this.$options.filters.getTime(i - 2, type)
        // console.log(hour, 'hour')
        if (hour < this.currentStartTime.split(' ')[1]) {
          if (typ === 'word') {
            return 'cursor: not-allowed; color: #222222;'
          }
          return 'cursor: not-allowed;'
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  width: 100%;
  height: 1px;
  background: #6b738b;
  box-sizing: border-box;
  display: flex;
  color: rgba($color: #fff, $alpha: 1);
  font-size: 12px;
  position: relative;

  &.satellite {
    background: #fff;
  }

  .slider {
    position: absolute;
    top: -14px;
    transform: translateX(-50%);
    color: rgba($color: #f00, $alpha: 0.5);
    font-size: 20px;
  }

  .target {
    position: absolute;
    top: -7px;
    padding: 4px;
    z-index: 2;
    transform: translateX(-65%);
    border-radius: 50%;
    background: $text-operate;

    .box {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $theme-color;
    }

    .datetime {
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translateX(-50%);
      height: 24px;
      background: #fff;
      color: $theme-color;
      width: 118px;
      line-height: 24px;
      border-radius: 4px;
      text-align: center;

      &::before {
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        box-sizing: border-box;
        border-top: 3px solid #fff;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }
    }

    .last_tip {
      left: -210%;

      &::before {
        left: 76%;
      }
    }
  }

  .time_wrap {
    width: 100%;
    display: flex;

    .time_item {
      flex: 1;
      cursor: pointer;
      position: relative;

      &:last-child {
        cursor: pointer;
        width: 2px;
        flex: 0;

        .word {
          transform: translateX(-50%);
        }
      }

      &.animation {
        background: #bac3db;
      }

      .clickDiv {
        position: absolute;
        top: -10px;
        left: -4px;
        cursor: pointer;
        width: 10px;
        height: 35px;
        z-index: 9999;
      }

      .circle {
        width: 1px;
        height: 7px;
        background-color: #6b738b;
        position: absolute;
        top: -6px;
        cursor: pointer;

        &.satellite,
        &.animation {
          background: #fff;
        }
      }

      .word {
        position: absolute;
        top: 4px;
        transform: translateX(-48%);
        text-align: center;
      }
    }
  }
}
</style>

<style>
.current_a {
  background-color: #ffffff;
}
</style>
