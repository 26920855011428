import { factorFour } from '@/config/MapConfig';
import parseDataConfig from '@/config/ColorBar';

class ParseData {
  constructor(surfaceData, factor, resolutionX, resolutionY) {
    Object.assign(this, surfaceData);
    this.min = surfaceData.min ?? surfaceData.pregMin;
    this.max = surfaceData.max ?? surfaceData.pregMax;
    this.image = surfaceData.image;
    this.width = surfaceData.width;
    this.height = surfaceData.height;
    this.latmax = 90;
    this.lonmin = 0;
    this.origionx = surfaceData.origionx;
    this.origiony = surfaceData.origiony;
    this.level = surfaceData.level;
    this.factor = factor;
    this.realData = null;
    this.imageData = null;
    this.resolutionX = resolutionX;
    this.resolutionY = resolutionY;
  }

  get total() {
    return factorFour[this.factor] ? 4 : 1;
  }

  setData() {
    const canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height * this.total;
    const ctx = canvas.getContext('2d');
    const xmin = this.lonmin;
    const ymax = this.latmax;
    const imgwidth = this.width;
    const imgheight = this.height * this.total;
    const origionx = this.origionx;
    const origiony = this.origiony;
    const level = this.level;
    const xoffset = xmin / this.resolutionX;
    const yoffset = (90 - ymax) / this.resolutionY;
    ctx.drawImage(this.image, origionx, origiony, imgwidth, imgheight, xoffset, yoffset, imgwidth, imgheight);

    const imageData = ctx.getImageData(0, 0, imgwidth, imgheight);
    const data = imageData.data;
    this.realData = [];
    console.time('loop');
    if (factorFour[this.factor]) {
      this.handlerPregAll(data);
    } else {
      for (let i = 0; i < data.length; i += 4) {
        const realdata = this.reverse(data[i + level], this.max, this.min);
        this.realData.push(realdata);
        const color = this.getGradientColor(this.factor, realdata);
        data[i] = color[0]; // red
        data[i + 1] = color[1]; // green
        data[i + 2] = color[2];
        data[i + 3] = color[3];

        // if (color[0] === 255 && color[1] === 255 && color[2] === 255) {
        //   data[i + 3] = 0;
        // }
      }
    }
    console.timeEnd('loop');
    ctx.putImageData(imageData, 0, 0);
    ctx.imageSmoothingEnabled = false;
    console.time('put');
    if (factorFour[this.factor]) {
      const canvas1 = document.createElement('canvas');
      canvas1.width = this.width;
      canvas1.height = this.height;
      const ctx1 = canvas1.getContext('2d');
      ctx1.drawImage(canvas, 0, 0, this.width, this.height, 0, 0, this.width, this.height);
      this.image = this.convertCanvasToImage(canvas1);
    } else {
      this.image = this.convertCanvasToImage(canvas);
    }
    console.timeEnd('put');

    return this.image;
  }

  handlerPregAll(data) {
    const count = this.width * this.height * 4;

    for (let i = 0; i < count; i += 4) {
      const graupel = this.reverse(data[i + this.level], this.pregMax, this.pregMin);
      const ice = this.reverse(data[i + count + this.level], this.preiMax, this.preiMin);
      const rain = this.reverse(data[i + count * 2 + this.level], this.prerMax, this.prerMin);
      const snow = this.reverse(data[i + count * 3 + this.level], this.presMax, this.presMin);
      this.realData.push({
        graupel, ice, rain, snow,
      });
      const color1 = this.getGradientColor('graupel', graupel, true);
      const color2 = this.getGradientColor('ice', ice, true);
      const color3 = this.getGradientColor('rain', rain, true);
      const color4 = this.getGradientColor('snow', snow, true);

      const color = this.multiply(this.multiply(color1, color2), this.multiply(color3, color4));
      data[i] = color[0];
      data[i + 1] = color[1];
      data[i + 2] = color[2];
    }
  }

  multiply(color1, color2) {
    const result = [];
    for (let i = 0; i < color1.length; i++) {
      result[i] = Math.floor((color1[i] * color2[i]) / 255);
    }
    return result;
  }

  gradienta(sColor, eColor, svalue, evalue, value) {
    const step = ((evalue - svalue) / (value - svalue));
    const rStep = (eColor[0] - sColor[0]) / step;
    const gStep = (eColor[1] - sColor[1]) / step;
    const bStep = (eColor[2] - sColor[2]) / step;
    const aStep = (eColor[3] - sColor[3]) / step;
    return [Math.round(rStep + sColor[0]), Math.round(gStep + sColor[1]), Math.round(bStep + sColor[2]), Math.round(aStep + sColor[3])];
  }

  getIndex(value, allvalue) {
    let low = 0;
    let high = allvalue.length - 1;
    let mid;
    while (low <= high) {
      mid = (low + high) >> 1;
      if (allvalue[mid] < value) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }
    return low;
  }

  getGradientColor(name, value) {
    const colorarray = parseDataConfig.color_arrays[name];
    const colorlength = parseDataConfig.color_arrays[name].length;
    const allvalue = parseDataConfig.values[name];

    if (value <= allvalue[0]) {
      return colorarray[0];
    }
    if (value >= allvalue[allvalue.length - 1]) {
      return colorarray[colorlength - 1];
    }

    const ops = this.getIndex(value, allvalue);

    const svalue = allvalue[ops - 1];
    const evalue = allvalue[ops];
    const scolor = colorarray[ops - 1];
    const ecolor = colorarray[ops];

    return this.gradienta(scolor, ecolor, svalue, evalue, value);
  }

  convertCanvasToImage(canvas) {
    return canvas.toDataURL('image/png', 1);
  }

  reverse(data, max = this.max, min = this.min) {
    if (this.factor === 'ri_min') {
      return Math.exp(1) ** (min + data * (max - min) / 255);
    }
    return (min + data * (max - min) / 255);
  }
}

export default ParseData;
