export const metrics = [
  {
    id: 'flow_water',
    text: '降水率',
    icon: 'f-prerrt',
    subId: ['pratesfc', 'prerrt', 'presrt', 'pregrt', 'preirt'],
    children: [
      {
        id: 'pratesfc',
        // factor: 'wind',
        icon: 'f-pratesfc',
        text: '总降水',
      },
      {
        id: 'prerrt',
        icon: 'prerrt',
        text: '降雨',
      },
      {
        id: 'presrt',
        icon: 'presrt',
        text: '降雪',
      },
      {
        id: 'pregrt',
        icon: 'pregrt',
        text: '霰降水',
      },
      {
        id: 'preirt',
        icon: 'preirt',
        text: '冰降水',
      },
    ],
  },
  {
    id: 'add_water',
    text: '累计降水',
    icon: 'f-totalrain',
    subId: ['pre_total', 'pres_all', 'prer', 'pres', 'preg', 'prei'],
    children: [
      {
        id: 'pre_total',
        icon: 'rain',
        text: '总降水量',
      },
      {
        id: 'prer',
        icon: 'rain',
        text: '液态降水量',
      },
      {
        id: 'pres_all',
        icon: 'rain',
        text: '固态降水量',
      },
      // {
      //   id: 'pres',
      //   icon: 'snow',
      //   text: '累计降雪',
      // },
      // {
      //   id: 'preg',
      //   icon: 'graupel',
      //   text: '累计霰降水',
      // },
      // {
      //   id: 'prei',
      //   icon: 'ice',
      //   text: '累计冰降水',
      // },
    ],
  },
  {
    id: 'windy',
    icon: 'f-windy',
    // private: 't2',
    text: '风速',
    subId: ['wgrd10m', 'w100m'],
    children: [
      {
        id: 'wgrd10m',
        icon: 'wgrd10m',
        text: '10米风场',
      },
      {
        id: 'w100m',
        icon: 'wind-wheel',
        text: '100米风场',
      },
    ],
  },
  {
    id: 'tmp2m',
    factor: 'temp',
    icon: 'f-temp',
    text: '温度',
    private: 't2',
    type: 'merge',
  },
  {
    id: 't2mz',
    icon: 'f-temp',
    private: 't2',
    factor: 'temp',
    text: '温度',
    type: 'default',
  },
  {
    id: 'rh2m',
    icon: 'humidity',
    text: '相对湿度',
    private: 't2',
  },
  {
    id: 'radar',
    icon: 'f-radar',
    private: 't2',
    text: '雷达',
    subId: ['max_reflectivity', 'base_reflectivity'],
    children: [
      {
        id: 'max_reflectivity',
        // factor: 'wind',
        icon: 'radar',
        text: '明日雷达',
        private: 't2',
      },
      {
        id: 'base_reflectivity',
        // factor: 'wind',
        icon: 'base_ref',
        text: '基本反射率',
        private: 't2',
      },
    ],
  },

  {
    id: 'ri_min',
    icon: 'f-airport',
    text: '晴空颠簸指数',
    type: 'default',
  },
  {
    id: 'dswrfsfc',
    icon: 'dswrfsfc',
    text: '辐照度',
    type: 'default',
  },
  {
    id: 'dust',
    icon: 'dust',
    subId: ['dust_conc', 'dod', 'dust_ddep', 'dust_emis'],
    text: '沙尘',
    isPage: true,
    private: 't2',
    children: [
      {
        id: 'dust_conc',
        factor: 'dust_conc',
        icon: 'dust_conc',
        text: '地表沙尘浓度',
        private: 't2',
        isPage: 'true',
        routerName: 'dust',
      },

      {
        id: 'dod',
        factor: 'dod',
        icon: 'dod',
        text: '沙尘光学厚度',
        private: 't2',
        isPage: 'true',
        routerName: 'dust',
      },
      {
        id: 'dust_ddep',
        factor: 'dust_ddep',
        icon: 'dust_ddep',
        text: '累计沉降',
        private: 't2',
        isPage: 'true',
        routerName: 'dust',
      },
      {
        id: 'dust_emis',
        factor: 'dust_emis',
        icon: 'dust_emis',
        text: '扬沙通量',
        private: 't2',
        isPage: 'true',
        routerName: 'dust',
      }
    ]
  },
  {
    id: 'satellite',
    icon: 'satellite',
    text: '明日卫星',
    private: 't2',
    isPage: 'true',
    routerName: 'satellite',
  },
  {
    id: 'typhoon',
    icon: 'typhoon',
    text: '台风',
    private: 't2',
    isPage: 'true',
    routerName: 'typhoon',
  },
  // {
  //   id: 'pratesfc',
  //   icon: 'pratesfc',
  //   text: '总降水率',
  // },

  // {
  //   id: 'w200m',
  //   icon: '200pa',
  //   text: '200米风场',
  // },

  // {
  //   id: 'slp',
  //   icon: 'slp',
  //   text: '海平面气压',
  // },

  // {
  //   id: 'prert',
  //   icon: 'prert',
  //   text: '实时降水',
  // },

  // {
  //   id: 'preall',
  //   icon: 'preall',
  //   text: '累计降水',
  // },
];


export const allMetrics = [
  {
    id: 'tmp2m',
    factor: 'temp',
    icon: 'f-temp',
    text: '温度',
    private: 't2',
    type: 'merge',
  },
  {
    id: 't2mz',
    icon: 'f-temp',
    private: 't2',
    factor: 'temp',
    text: '温度',
    type: 'default',
  },
  {
    id: 't2mz',
    private: 't1',
    factor: 'temp',
    icon: 'f-humidity',
    text: '温度',
  },
  {
    id: 'flow_water',
    text: '降水率',
    icon: 'f-prerrt',
    subId: ['pratesfc', 'prerrt', 'presrt', 'pregrt', 'preirt'],
    children: [
      {
        id: 'pratesfc',
        // factor: 'wind',
        icon: 'f-pratesfc',
        text: '总降水',
      },
      {
        id: 'prerrt',
        icon: 'prerrt',
        text: '降雨',
      },
      {
        id: 'presrt',
        icon: 'presrt',
        text: '降雪',
      },
      {
        id: 'pregrt',
        icon: 'pregrt',
        text: '霰降水',
      },
      {
        id: 'preirt',
        icon: 'preirt',
        text: '冰降水',
      },
    ],
  },
  {
    id: 'add_water',
    text: '累计降水',
    icon: 'f-totalrain',
    subId: ['pre_total', 'pres_all', 'prer', 'pres', 'preg', 'prei'],
    children: [
      {
        id: 'pre_total',
        icon: 'rain',
        text: '总降水量',
      },
      {
        id: 'prer',
        icon: 'rain',
        text: '液态降水量',
      },
      {
        id: 'pres_all',
        icon: 'rain',
        text: '固态降水量',
      },
    ],
  },
  {
    id: 'windy',
    icon: 'f-windy',
    // private: 't2',
    text: '风速',
    subId: ['wgrd10m', 'w100m'],
    children: [
      {
        id: 'wgrd10m',
        icon: 'wgrd10m',
        text: '10米风场',
      },
      {
        id: 'w100m',
        icon: 'wind-wheel',
        text: '100米风场',
      },
    ],
  },
  {
    id: 'rh2m',
    icon: 'humidity',
    text: '相对湿度',
    private: 't2',
  },
  {
    id: 'radar',
    icon: 'f-radar',
    private: 't2',
    text: '雷达',
    subId: ['max_reflectivity', 'base_reflectivity'],
    children: [
      {
        id: 'max_reflectivity',
        // factor: 'wind',
        icon: 'radar',
        text: '明日雷达',
        private: 't2',
      },
      {
        id: 'base_reflectivity',
        // factor: 'wind',
        icon: 'base_ref',
        text: '基本反射率',
        private: 't2',
      },
    ],
  },

  {
    id: 'ri_min',
    icon: 'f-airport',
    text: '晴空颠簸指数',
  },
  {
    id: 'dswrfsfc',
    icon: 'dswrfsfc',
    text: '辐照度',
  },
  {
    id: 'dust',
    icon: 'dust',
    subId: ['dust_conc', 'dod', 'dust_ddep', 'dust_emis'],
    text: '沙尘',
    isPage: true,
    private: 't2',
    children: [
      {
        id: 'dust_conc',
        factor: 'dust_conc',
        icon: 'dust_conc',
        text: '地表沙尘浓度',
        private: 't2',
        isPage: 'true',
        routerName: 'dust',
      },

      {
        id: 'dod',
        factor: 'dod',
        icon: 'dod',
        text: '沙尘光学厚度',
        private: 't2',
        isPage: 'true',
        routerName: 'dust',
      },

      {
        id: 'dust_ddep',
        factor: 'dust_ddep',
        icon: 'dust_ddep',
        text: '累计沉降',
        private: 't2',
        isPage: 'true',
        routerName: 'dust',
      },

      {
        id: 'dust_emis',
        factor: 'dust_emis',
        icon: 'dust_emis',
        text: '扬沙通量',
        private: 't2',
        isPage: 'true',
        routerName: 'dust',
      },

    ]
  },
  {
    id: 'satellite',
    icon: 'satellite',
    text: '明日卫星',
    private: 't2',
    isPage: 'true',
    routerName: 'satellite',
  },
  {
    id: 'typhoon',
    icon: 'typhoon',
    text: '台风',
    private: 't2',
    isPage: 'true',
    routerName: 'typhoon',
  },
  // {
  //   id: 'pratesfc',
  //   icon: 'pratesfc',
  //   text: '总降水率',
  // },

  // {
  //   id: 'w200m',
  //   icon: '200pa',
  //   text: '200米风场',
  // },

  // {
  //   id: 'slp',
  //   icon: 'slp',
  //   text: '海平面气压',
  // },

  // {
  //   id: 'prert',
  //   icon: 'prert',
  //   text: '实时降水',
  // },

  // {
  //   id: 'preall',
  //   icon: 'preall',
  //   text: '累计降水',
  // },
];

export const mapForCn = {
  pratesfc: '总降水',
  prerrt: '降雨',
  presrt: '降雪',
  pregrt: '霰降水',
  preirt: '冰降水',
  pre_total: '总降水量',
  prer: '液态降水量',
  pres_all: '固态降水量',
  wgrd10m: '10米风场',
  w100m: '100米风场',
  tmp2m: '温度',
  t2mz: '温度',
  rh2m: '相对湿度',
  max_reflectivity: '明日雷达',
  base_reflectivity: '基本反射率',
  ri_min: '晴空颠簸指数',
  dust_conc: '地表沙尘浓度',
  dswrfsfc: '辐照度',
  dod: '沙尘光学厚度',
  dust_ddep: '累计沉降',
  dust_emis: '扬沙通量',
  satellite: '明日卫星',
  typhoon: '台风',
};

// 聚合模式：降水、降水量、风速、温度、相对湿度、雷达、    沙尘、台风

// 天机2/DA：降水、降水量、风速、温度、相对湿度、雷达、晴空颠簸数、明日卫星

// 天机2/ND：降水、降水量、风速、温度、相对湿度、雷达、晴空颠簸数

// 天机1/DA：：降水、降水量、风速、温度、晴空颠簸数

// 天机1/ND：降水、降水量、风速、温度、晴空颠簸数


export const modeFactorMap = {
  '聚合': ['pratesfc', 'prerrt', 'presrt', 'pregrt', 'preirt', 'pre_total', 'pres_all', 'prer', 'pres', 'preg', 'prei', 'wgrd10m', 'w100m', 'tmp2m', 'rh2m', 'max_reflectivity', 'base_reflectivity', 'dust_conc', 'dod', 'dust_ddep', 'dust_emis','dswrfsfc'],
  '天机2/ND': ['pratesfc', 'prerrt', 'presrt', 'pregrt', 'preirt', 'pre_total', 'pres_all', 'prer', 'pres', 'preg', 'prei', 'wgrd10m', 'w100m', 't2mz', 'rh2m', 'max_reflectivity', 'base_reflectivity', 'ri_min', 'dust_conc', 'dod', 'dust_ddep', 'dust_emis','dswrfsfc'],
  '天机2/DA': ['pratesfc', 'prerrt', 'presrt', 'pregrt', 'preirt', 'pre_total', 'pres_all', 'prer', 'pres', 'preg', 'prei', 'wgrd10m', 'w100m', 't2mz', 'rh2m', 'max_reflectivity', 'base_reflectivity', 'ri_min','dust_conc', 'dod', 'dust_ddep', 'dust_emis','dswrfsfc'],
  '天机1/ND':  ['pratesfc', 'prerrt', 'presrt', 'pregrt', 'preirt', 'pre_total', 'pres_all', 'prer', 'pres', 'preg', 'prei', 'wgrd10m', 'w100m', 't2mz', 'ri_min','dswrfsfc'],
  '天机1/DA':  ['pratesfc', 'prerrt', 'presrt', 'pregrt', 'preirt', 'pre_total', 'pres_all', 'prer', 'pres', 'preg', 'prei', 'wgrd10m', 'w100m', 't2mz', 'ri_min','dswrfsfc'],
}

export const dustMetrics = [
  {
    id: 'dust_conc',
    factor: 'dust_conc',
    icon: 'dust_conc',
    text: '地表沙尘浓度',
    private: 't2',
    type: 'merge',
  },
  {
    id: 'dod',
    factor: 'dod',
    icon: 'dod',
    text: '沙尘光学厚度',
    private: 't2',
    type: 'merge',
  },
  {
    id: 'dust_ddep',
    factor: 'dust_ddep',
    icon: 'dust_ddep',
    text: '累计沉降',
    private: 't2',
    type: 'merge',
  },
  {
    id: 'dust_emis',
    factor: 'dust_emis',
    icon: 'dust_emis',
    text: '扬沙通量',
    private: 't2',
    type: 'merge',
  },

  {
    id: 'dswrfsfc',
    factor: 'dswrfsfc',
    icon: 'dswrfsfc',
    text: '辐照度',
    private: 't2',
    type: 'merge',
  },
]

export const t3Metrics = [
  {
    id: 'pratesfc',
    // factor: 'wind',
    icon: 'pratesfc',
    text: '总降水率',
  },
  {
    id: 'radar',
    text: '雷达',
    icon: 'radar',
    subId: ['max_reflectivity', 'base_reflectivity'],
    children: [
      {
        id: 'max_reflectivity',
        // factor: 'wind',
        icon: 'radar',
        text: '明日雷达',
        private: 't2',
      },

      {
        id: 'base_reflectivity',
        // factor: 'wind',
        icon: 'base_ref',
        text: '基本反射率',
        private: 't2',
      },
    ],
  },
  {
    id: 'windySpeed',
    text: '风速',
    icon: 'wind',
    subId: ['wind', 'wgrd10m', 'w100m'],
    children: [
      // {
      //   id: 'wind',
      //   icon: 'wind',
      //   text: '气压面风速',
      // },
      {
        id: 'wgrd10m',
        icon: 'wgrd10m',
        text: '10米风场',
      },

      {
        id: 'w100m',
        icon: 'wind-wheel',
        text: '100米风场',
      },
    ],
  },
  {
    id: 'tempt',
    icon: 'temp',
    text: '温度',
    subId: ['tmp', 't2mz', 'tmpsfc'],
    children: [
      // {
      //   id: 'tmp',
      //   icon: 'temp',
      //   text: '气压面温度',
      // },
      {
        id: 't2mz',
        icon: 'temperature',
        text: '2米温度',
      },
      {
        id: 'tmpsfc',
        icon: 'tmpsfc',
        text: '地表温度',
      },
    ],
  },
  {
    id: 'rainWater',
    icon: 'prerrt',
    text: '降水',
    subId: ['prerrt', 'presrt', 'pregrt', 'preirt'],
    children: [
      {
        id: 'prerrt',
        icon: 'prerrt',
        text: '降雨',
      },
      {
        id: 'presrt',
        icon: 'presrt',
        text: '降雪',
      },
      {
        id: 'pregrt',
        icon: 'pregrt',
        text: '霰降水',
      },
      {
        id: 'preirt',
        icon: 'preirt',
        text: '冰降水',
      },
    ],
  },
  {
    id: 'addRainWater',
    icon: 'rain',
    text: '累计降水',
    subId: ['prer', 'pres', 'preg', 'prei'],
    children: [
      {
        id: 'prer',
        icon: 'rain',
        text: '累计降雨',
      },
      {
        id: 'pres',
        icon: 'snow',
        text: '累计降雪',
      },
      {
        id: 'preg',
        icon: 'graupel',
        text: '累计霰降水',
      },
      {
        id: 'prei',
        icon: 'ice',
        text: '累计冰降水',
      },
    ],
  },

];

export const factorMapT1 = {
  pratesfc: 'pratesfc',
  dswrfsfc: 'dswrfsfc',
  wgrd10m: 'wind',
  w100m: 'wind',
  t2mz: 'temp',
  pre_total: 'pre_total',
  pres_all: 'pres_all',
  ri_min: 'ri_min',
  prerrt: 'rain',
  presrt: 'snow',
  pregrt: 'graupel',
  preirt: 'ice',
  prer: 'prer',
  pres: 'pres',
  preg: 'preg',
  prei: 'prei',
}

export const factorMap = {
  tmp: 'temp',
  wind: 'wind',
  t2mz: 'temp',
  pres_all: 'pres_all',
  pre_total: 'pre_total',
  tmp2m: 'temp',
  tmpsfc: 'temp',
  max_reflectivity: 'max_reflectivity',
  base_reflectivity: 'base_reflectivity',
  wgrd10m: 'wind',
  w100m: 'wind',
  w200m: 'wind',
  prer: 'prer',
  pres: 'pres',
  prei: 'prei',
  preg: 'preg',
  dust_conc: 'dust_conc',
  dswrfsfc: 'dswrfsfc',
  dod: 'dod',
  dust_ddep: 'dust_ddep',
  dust_emis: 'dust_emis',
  slp: 'slp',
  ri_min: 'ri_min',
  pratesfc: 'pratesfc',
  preall: 'preall',
  prert: 'prert',
  prerrt: 'rain',
  pregrt: 'graupel',
  preirt: 'ice',
  presrt: 'snow',
  rh2m: 'rh2m',
};


export const dust_factorMap = {
  dust_conc: 'dust_conc',
  dod: 'dod',
  dust_ddep: 'dust_ddep',
  dust_emis: 'dust_emis',
};

export const factorFour = {
  preall: 'preall',
  prert: 'prert',
};

export const factorPressure = {
  // prer: 'rain',
  // pres: 'snow',
  // prei: 'ice',
  // preg: 'graupel',
  // preall: 'preall',
  // prert: 'prert',
  // prerrt: 'rain',
  // pregrt: 'graupel',
  // preirt: 'ice',
  // presrt: 'snow',
  // pratesfc: 'pratesfc',
};

export const factorWind = {
  pratesfc: 'wgrd10m',
  prerrt: 'wgrd10m',
  presrt: 'wgrd10m',
  pregrt: 'wgrd10m',
  preirt: 'wgrd10m',
  pre_total: 'wgrd10m',
  prer: 'wgrd10m',
  pres_all: 'wgrd10m',
  t2mz: 'wgrd10m',
  ri_min: 'wgrd10m',
};

export const HDMap = {
  // wgrd10m: 'wind',
  prer: 'prer',
  pres: 'pres',
  prei: 'prei',
  preg: 'preg',
  preall: 'preall',
  prert: 'prert',
  prerrt: 'rain',
  pregrt: 'graupel',
  preirt: 'ice',
  presrt: 'snow',
  pratesfc: 'pratesfc',
};

export const unitMap = {
  temp(value) {
    // return `温度：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">温度</span><br><span class="current-value" >${value} ${unit['temp']}</span>`;
  },
  dust_conc(value) {
    // return `沙尘浓度：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">沙尘浓度</span><br><span class="current-value" >${value} ${unit['dust_conc']}</span>`;
  },

  dswrfsfc(value) {
    // return `沙尘浓度：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">辐照度</span><br><span class="current-value" >${value} ${unit['dswrfsfc']}</span>`;
  },

  dod(value) {
    // return `光学厚度：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">光学厚度</span><br><span class="current-value" >${value} ${unit['dod']}</span>`;
  },

  dust_ddep(value) {
    // return `累计沉降：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">累计沉降</span><br><span class="current-value" >${value} ${unit['dust_ddep']}</span>`;
  },

  dust_emis(value) {
    // return `扬沙通量：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">扬沙通量</span><br><span class="current-value" >${value} ${unit['dust_emis']}</span>`;
  },

  wind(value) {
    // return `风速：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">风速</span><br><span class="current-value" >${value} ${unit['wind']}</span>`;
  },
  rain(value) {
    // return `降雨：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">降雨</span><br><span class="current-value" >${value} ${unit['rain']}</span>`;
  },
  snow(value) {
    // return `降雪：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">降雪</span><br><span class="current-value" >${value} ${unit['snow']}</span>`;
  },
  ice(value) {
    // return `降冰：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">降冰</span><br><span class="current-value" >${value} ${unit['ice']}</span>`;
  },
  graupel(value) {
    // return `霰降水：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">霰降水</span><br><span class="current-value" >${value} ${unit['graupel']}</span>`;
  },

  prer(value) {
    // return `液态降水量：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">液态降水量</span><br><span class="current-value" >${value} ${unit['prer']}</span>`;
  },
  pre_total(value) {
    // return `总降水量：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">总降水量</span><br><span class="current-value" >${value} ${unit['pre_total']}</span>`;
  },
  pres_all(value) {
    // return `固态降水量：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">固态降水量</span><br><span class="current-value" >${value} ${unit['pres_all']}</span>`;
  },
  pres(value) {
    // return `降雪：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">降雪</span><br><span class="current-value" >${value} ${unit['pres']}</span>`;
  },
  prei(value) {
    // return `降冰：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">降冰</span><br><span class="current-value" >${value} ${unit['prei']}</span>`;
  },
  preg(value) {
    // return `霰降水：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">霰降水</span><br><span class="current-value" >${value} ${unit['preg']}</span>`;
  },
  ri_min(value) {
    // return `指数：<span class="current-value">${value}</span>`;
    return `<span class="current-factor">指数</span><br><span class="current-value" >${value} ${unit['ri_min']}</span>`;
  },
  slp(value) {
    return `<span class="current-factor">气压</span><br><span class="current-value" >${value} ${unit['slp']}</span>`;
  },
  pratesfc(value) {
    // return `<!--总降水率：<span class="current-value">${value}</span>-->`;
    return `<span class="current-factor">总降水率</span><br><span class="current-value" >${value} ${unit['pratesfc']}</span>`;
  },
  preall(value) {
    return `
      降雨：<span class="current-value">${value.rain}<br /></span>
      降雪：<span class="current-value">${value.snow}<br /></span>
      降冰：<span class="current-value">${value.ice}<br /></span>
      霰降水：<span class="current-value">${value.graupel}<br />
    </span>`;
  },
  prert(value) {
    return `
      降雨：<span class="current-value">${value.rain}<br /></span>
      降雪：<span class="current-value">${value.snow}<br /></span>
      降冰：<span class="current-value">${value.ice}<br /></span>
      霰降水：<span class="current-value">${value.graupel}<br />
    </span>`;
  },

  max_reflectivity(value) {
    // return `雷达:<span class="current-value"> ${(value < 0 ? 0 : value)}</span>`;
    return `<span class="current-factor">雷达</span><br><span class="current-value" >${(value < 0 ? 0 : value)} ${unit['max_reflectivity']}</span>`;
  },

  base_reflectivity(value) {
    // return `雷达:<span class="current-value"> ${(value < 0 ? 0 : value)}</span>`;
    return `<span class="current-factor">雷达</span><br><span class="current-value" >${(value < 0 ? 0 : value)} ${unit['base_reflectivity']}</span>`;
  },

  rh2m(value) {
    // return `相对湿度：<span class="current-value">${(Number.isNaN(value) ? 0 : value)}</span>`;
    return `<span class="current-factor">相对湿度</span><br><span class="current-value" >${(Number.isNaN(value) ? 0 : value)} ${unit['rh2m']}</span>`;
  },
};

export const unit = {
  temp: '℃',
  dswrfsfc: 'W/m²',
  wind: 'm/s',
  dust_conc: 'μg/m³',
  dod: "",
  dust_ddep: "μg/m²",
  dust_emis: "μg/㎡/h",
  rain: 'mm/hr',
  snow: 'mm/hr',
  ice: 'mm/hr',
  graupel: 'mm/hr',
  prer: 'mm',
  pre_total: 'mm',
  pres_all: 'mm',
  pres: 'mm',
  prei: 'mm',
  preg: 'mm',
  slp: 'mb',
  ri_min: '',
  pratesfc: 'mm/hr',
  max_reflectivity: 'dBz',
  base_reflectivity: 'dBz',
  rh2m: '%',
};

// export const geoserverUrl = 'https://app.tjweather.com:8000';
export const geoserverUrl = '';

export const ModelLegend = {
  tj: { color: '#00BFFF', name: '中科天机', checked: true },
  gc: { color: '#008000', name: '观测', checked: true },
  ifs: { color: '#8B4513', name: 'IFS', checked: false },
  jh: { color: '#FFA500', name: '欧洲集合', checked: false },
  gfs: { color: '#1E90FF', name: 'GFS', checked: false },
};

export default metrics;
