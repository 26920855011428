<template>
  <div class="metric-select">
    <div class="metric-wrap" @mouseover="showAllMenu = true" @mouseleave="handleMouseLeave">
      <ul>
        <li
          v-for="metric in currentData"
          :key="metric.id"
          :class="{ active: metric.id === factor || showParent(metric) }"
          :style="{
            width: metric.id === factor || showAllMenu || showParent(metric) ? '130px' : '42px',
          }"
          @click.stop="handler(metric)"
        >
          <div
            class="text-wrap"
            :class="metric.show && metric.children ? 'expand-second' : ''"
            @click="toggleChange(metric)"
          >
            <div class="icon">
              <svg-icon :icon-class="metric.icon" />
            </div>
            <div
              v-show="metric.id === factor || showAllMenu || showParent(metric)"
              @mouseenter="activeID = metric.id"
              @mouseleave="activeID = ''"
              class="text"
            >
              <span>{{ metric.text }}</span>
              <i
                v-if="metric.children"
                :class="metric.show ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
              />
              <div
                class="qrShow"
                style="height: 26px; line-height: 26px"
                v-html="getRatio(metric)"
                v-show="
                  activeID === metric.id &&
                  metric.id !== 'satellite' &&
                  metric.id !== 'typhoon' &&
                  haveFactor(metric.id)
                "
              ></div>
            </div>
          </div>
          <ul
            v-show="(metric.show && showAllMenu) || (metric.show && showParent(metric))"
            class="second transition-box"
          >
            <li
              v-for="sub in handleActiveMetric(metric.children)"
              :key="sub.id"
              @click.stop="handler(sub, 'second', metric)"
            >
              <div
                class="text-wrap"
                @mouseenter="activeID = sub.id"
                @mouseleave="activeID = ''"
                :class="{ active: sub.id === factor }"
              >
                <div class="text" style="position: relative">
                  {{ sub.text }}
                  <div
                    class="qrShow"
                    :style="
                      ['pre_total', 'pres_all', 'prer', 'pres', 'preg', 'prei'].includes(sub.id)
                        ? ' top: -7px;line-height: 21px;'
                        : 'top: 0'
                    "
                    v-show="
                      activeID === sub.id &&
                      sub.id !== 'satellite' &&
                      sub.id !== 'typhoon' &&
                      haveFactor(sub.id)
                    "
                    v-html="getRatio(sub)"
                  ></div>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { drag } from "@/utils/drag";
import { TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND } from "@/config";
import { modeFactorMap, factorMap, dust_factorMap, dustMetrics } from "@/config/MapConfigT4";
import { mapGetters } from "vuex/dist/vuex.common.js";

export default {
  name: "MetricSelect",
  props: {
    factor: {
      type: String,
      required: true,
    },
    metrics: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "t2",
    },
  },
  data() {
    return {
      currentData: [],
      showAllMenu: true,
      activeID: "",
      isMove: false,
    };
  },
  computed: {
    ...mapGetters('dust', ["isT2Mode"]),
    ...mapState("dust", ["t2Mode"]),
  },
  watch: {
    metrics() {
      this.initData();
    },
  },
  mounted() {
    drag(this.$el);
    this.$el.addEventListener("mousedown", (event) => {
      this.handleMouseDown();
    });
    this.$el.addEventListener("mouseup", (event) => {
      this.handleMouseUp();
      setTimeout(() => {
        this.isMove = false;
      }, 500);
    });
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapMutations("map", ["setFactor", "setMode"]),
    handleMouseDown() {
      this.$el.addEventListener("mousemove", this.handleMouseMove);
    },
    handleMouseMove(event) {
      // 监听鼠标移动事件时的逻辑
      this.isMove = true;
    },
    handleMouseUp() {
      this.$el.removeEventListener("mousemove", this.handleMouseMove);
    },
    handleActiveMetric(metric = []) {
      return this.showAllMenu ? metric : metric.filter((item) => item.id === this.factor);
    },
    showParent(metric) {
      return metric.subId ? metric.subId.includes(this.factor) : false;
    },
    handleMouseLeave() {
      this.currentData = this.metrics.map((metric) => {
        metric.show = metric.id === this.factor || this.showParent(metric);
        return metric;
      });
      // this.showAllMenu = false;
    },
    getRatio(metric) {
      if (this.isTyp || this.isSatel) {
        //如果列表是t2此时取t2的模式，否则取t1的模式
        if (sessionStorage.getItem("tmode") === "t2") {
          return this.t2Ratio(metric);
          //天机1时
        } else {
          return ["pre_total", "pres_all", "prer", "pres", "preg", "prei"].includes(metric.id)
            ? "12km<br/>自起报时累计"
            : "12km";
        }
      } else {
        //以天机2为准
        // if (this.$route.name === "weather_hd" || this.$route.name === "earth") {
        return this.t2Ratio(metric);
        // } else {
        //   return ['prer333', 'prer111', 'prer', 'pres', 'preg', 'prei'].includes(metric.id) ?  "1小时<br/>12KM" :  "12KM";
        // }
      }
    },
    t2Ratio(metric) {
      if (this.t2Mode === TIANJI2_MERGE) {
        if (["tmp2m", "t2mz"].includes(metric.id)) {
          return "1km";
        } else if (["dust_conc", "dod", "dust_ddep", "dust_emis"].includes(metric.id)) {
          return "12km";
        } else {
          return ["pre_total", "pres_all", "prer", "pres", "preg", "prei"].includes(metric.id)
            ? "10km<br/>自起报时累计"
            : "10km";
        }
      } else {
        if(["dust_conc", "dod", "dust_ddep", "dust_emis"].includes(metric.id)){
          return "12km";
        }
        return ["pre_total", "pres_all", "prer", "pres", "preg", "prei"].includes(metric.id)
          ? "2.5km<br/>自起报时累计"
          : "2.5km";
      }
    },
    initData() {
      this.currentData = this.metrics.map((metric) => {
        metric.show = metric.id === this.factor || this.showParent(metric);
        return metric;
      });
    },
    toggleChange(metric) {
      if (metric.children && metric.children.length) {
        this.currentData = this.currentData.map((item) => {
          if (item.id === metric.id) {
            item.show = !item.show;
          } else {
            item.show = false;
          }
          return item;
        });
      }
    },

    haveFactor(id) {
      const mapType = modeFactorMap[TIANJI2_MERGE];
      return mapType.includes(id);
    },

    handler(metric) {
      console.log('aaaaaaaaaa')
      if (this.isMove) return;
      const factor = this.$store.state["dust"]["factor"];
      if (metric.children && metric.children.length) {
        if (metric.subId.includes(factor)) return;
        metric = metric.children[0];
      }
      if (dust_factorMap[metric.id]) {
        this.$sensors.track("CurrentFactor", {
          info: `${metric.text}`,
          space_type: this.$route.name === "earth" ? "三维" : "二维",
          mode: this.t2Mode,
          page_type: "沙尘",
        });
        const factorText = dustMetrics.find(item => item.id === factor).text;
        this.$sensors.track("Jump_volume", {
          from_factor: factorText,
          from_mode: sessionStorage.getItem("tmode") === "t1" ? this.t1Mode : this.t2Mode,
          from_space_type: this.$route.name === "earth" ? '三维' : '二维',
          to_mode: sessionStorage.getItem("tmode") === "t1" ? this.t1Mode : this.t2Mode,
          to_factor: metric.text,
          to_space_type: this.$route.name === "earth" ? '三维' : '二维',
        });
        this.$emit("change", metric);
        return;
      }
      // this.setMode(TIANJI2_MERGE);
      if (metric.isPage) {
        this.$sensors.track("CurrentPage", {
          page_type: metric.text,
          space_type: this.$route.name === "earth" ? "三维" : "二维",
        });
        this.$router.push({ name: metric.routerName });
        return;
      }
      if (metric.children && metric.children.length) {
        return;
      }

      //切换的要素不是沙尘要素跳转到天机1/天机2
      if (!dust_factorMap[metric.id]) {
        if (factorMap[metric.id]) {
          //t2
          let mapType = modeFactorMap[this.t2Mode];

          if (["tmp2m", "t2mz"].includes(metric.id)) {
            metric.id = !this.isT2Mode ? "tmp2m" : "t2mz";
          }
          if (!mapType.includes(metric.id)) {

            let str = "";
            for (let key in modeFactorMap) {
              let item = modeFactorMap[key];
              if (item.includes(metric.id) && !str) {
                str = key;
              }
            }

            if (!str.includes("天机1")) {
              this.$store.commit("map/setMode", str);
              this.$store.commit("map/setFactor", metric.id);
              this.$router.push({ name: "weather_hd" });
            } else {
              this.$store.commit("setMode", str);
              this.$store.commit("setFactor", metric.id);
              this.$router.push({ name: "weather" });
            }
          } else {
            console.log("includes", mapType.includes(metric.id), mapType);
            this.$store.commit("map/setFactor", metric.id);
            this.$router.push({ name: "weather_hd" });
          }

          this.$sensors.track("CurrentFactor", {
            info: `${metric.text}`,
            mode: sessionStorage.getItem("tmode") === "t1" ? this.t1Mode : this.t2Mode,
            space_type: this.$route.name === "earth" ? "三维" : "二维",
            page_type: sessionStorage.getItem("tmode") === "t1" ? "天机1" : "天机2",
          });

          return;
        }
        this.$message("当前要素正在开发中，请耐心等待。");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #05f;
$grey-bg: rgba(50, 50, 50, 0.5);

.metric-select {
  position: absolute;
  left: 20px;
  top: 80px;
  color: $text-operate;
  z-index: 1000;
  .qrShow {
    position: absolute;
    left: 111%;
    z-index: 999;
    background-color: rgba(66, 66, 66, 0.55);
    width: 93px;
    height: auto;
    color: white;
    text-align: center;
    border-radius: 6px;
    font-family: sans-serif;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border: 8px solid rgba(0, 0, 0, 0);
      border-right-color: rgba(66, 66, 66, 0.55);
      right: 100%;
      top: 50%;
      margin-top: -7px;
    }
  }
}

.metric-wrap {
  > ul {
    position: relative;
    border-radius: 25px;

    > li {
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      margin-bottom: 2px;

      > .text-wrap {
        border-radius: 6px;
        display: flex;
        background: rgba(66, 66, 66, 0.55);
        backdrop-filter: blur(1px);
        align-items: center;
        padding: 2px 0;

        &.expand-second {
          border-radius: 6px 6px 0 0;
          background-color: rgba(66, 66, 66, 0.55) !important;
        }

        .text {
          font-size: 12px;
          position: relative;
          line-height: 32px;
          display: flex;
          flex: 1;
          justify-content: space-between;
          align-items: center;
          text-shadow: 0px 0px 4px black;

          i {
            margin-right: 10px;
          }
        }
      }

      .second {
        .text-wrap {
          height: 26px;
          background: rgba(66, 66, 66, 0.55);
        }

        .text {
          line-height: 26px;
          margin-left: 32px;
          font-size: 12px;
          text-shadow: 0px 0px 4px black;
        }
      }

      .icon {
        border-radius: 6px;
        height: 32px;
        width: 32px;
        line-height: 32px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .active {
        &.text-wrap {
          background: #027aff;
        }
      }

      .text-wrap:hover,
      &.active > .text-wrap {
        background-color: #027aff;

        > .text {
          color: $text-operate;
        }
      }
    }
  }

  .second li {
    .text-wrap {
      &:hover,
      &.active {
        background: #027aff;
      }
    }

    &:last-child {
      .text-wrap {
        border-radius: 0 0 6px 6px;
      }
    }
  }
}
</style>
