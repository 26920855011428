import { httpHD as http, httpST, httpSD } from './http';

/**
 * @description 根据要素和预报时间获取要素预报
 * @param baseTime
 * @param {String} factorCode 要素id
 * @returns {Promise}
 * @author yujie
 */
export const getFactorcast = (baseTime, factorCode) => http.get('/factor/forecast', { params: { baseTime, factorCode } });

/**
 * @description 获取风场距离当前时间最近的数据
 * @returns {Promise}
 * @author yujie
 */
export const getCurrentWind = () => http.get('/factor/3d/wind');

/**
 * @description 瓦片通过factor获取数据
 * @param {String} factorCode 要素code
 * @returns {Promise}
 * @author yujie
 */
export const getDataByFactor = (factorCode, production = 't2') => http.get('/main/factor/forecast-available', { params: { factorCode, production } });
export const getDataByFactorSD = (factorCode, production = 't2') => httpSD.get('/main/factor/forecast-available', { params: { factorCode, production } });

/**
 * @description 根据当前的点的坐标获取各要素的数据昆山的
 * @param {str} factorType 要素类别
 * @param {str} paramsStr 经纬度、起报时间、模式、要素
 * @returns {Promise}
 * @author guoqb
 */
export const getCurrentPointKsFactorData = (paramsStr) => httpST.get(`/arxiv/single-point/query?${paramsStr}`, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

/**
 * @description 根据当前的点的坐标阿里云获取各要素的数据
 * @param {str} factorType 要素类别
 * @param {str} paramsStr 经纬度、起报时间、模式、要素
 * @returns {Promise}
 * @author guoqb
 */
export const getCurrentPointAliFactorData = (paramsStr) => http.get(`/main/single-point/query?${paramsStr}`, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

/**
 * @description 根据要素获取要素的类型
 * @param {str} paramsObj 经纬度、起报时间、模式、要素
 * @returns {Promise}
 * @author guoqb
 */
export const getFactorTypeData = (paramsObj) => http.get(`/main/single-point/factor-type?${paramsObj}`, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

/**
 * @description 根据当前的点的坐标阿里云获取各要素的数据
 * @param {str} factorType 要素类别
 * @param {str} paramsStr 经纬度、起报时间、模式、要素
 * @returns {Promise}
 * @author guoqb
 */
export const getCurrentPointFactorData = (paramsStr) => http.get(`/spas/single-point/query?${paramsStr}`, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const getCurrentPointFactorDataSD = (paramsStr) => httpSD.get(`/main/single-point/query?${paramsStr}`, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

/**
 * @description 根据当前的点获取要素预报时间
 * @param {str} factorType 要素类别
 * @param {str} paramsStr 经纬度、起报时间、模式、要素
 * @returns {Promise}
 * @author guoqb
 */
export const getCurrentPointTagsTimeData = (paramsStr) => http.get(`/main/single-point/index?${paramsStr}`, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});
