<template>
  <div
    class="wrap"
    :class="{ show, mobile: isMobile }"
    :style="{
      width: isMobile ? '100%' : '600px',
    }"
  >
    <div
      class="close el-icon-close"
      @click="show = false"
    />
    <div class="select">
      <div style="margin: 15px 0;" />
      <el-checkbox-group
        v-model="defaultFactor"
        :max="5"
        @change="handleCheckedMetricChange"
      >
        <el-checkbox
          v-for="item in factorList"
          :key="item.id"
          :style="{
            width: isMobile ? '30%' : '25%',
          }"
          :label="item.id"
        >
          {{ item.text }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <div
      v-loading="loading"
      class="factor-box"
    >
      <el-collapse
        :value="activeNames"
      >
        <el-collapse-item
          v-for="(item, index) in lineData"
          :key="item.factorCode"
          :title="getFactorText(item.factorCode)"
          :name="index"
        >
          <div
            class="box"
            :class="'box' + index"
          />
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { lineOption } from '@/config/lineOptions';
import { getCurrentPointFactorData } from '@/api/weather_hd';
import Bus from '@/bus/index';
import echarts from '@/plugins/echarts';

export default {
  name: 'MobileBottomMoreVue',
  props: {
    t3: {
      type: Boolean,
      default: false,
    },
    metrics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      initChart: {},
      lineData: [],
      show: false,
      position: null,
      defaultFactor: ["dust_conc", "dod", "dust_ddep", "dust_emis"],
      // defaultFactor: ['pratesfc', 't2mz'],
      metricsList: [],
      isIndeterminate: true,
      checkAll: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapGetters('dust', ['currentData', 'currentLayerDetails']),
    metricsAll() {
      return this.metrics.map((item) => item.id);
    },
    factorList() {
      let arr = [];
      this.metrics.forEach((item) => {
        if(!item.isPage) {
          if (item.children) {
          arr = [...arr, ...item.children];
          } else {
            arr.push(item);
          }
        }
      });
      return arr;
    },
    activeNames() {
      return this.lineData.map((item, index) => index);
    },
  },
  watch: {
    position(val) {
      if (val) {
        this.getFactorData();
      }
    },
  },
  created() {
    Bus.$on('showBottomDust', (position) => {
      this.show = true;
      this.position = position;
    });
  },
  beforeDestroy() {
    Bus.$off('showBottomDust');
  },
  methods: {
    getFactorText(id) {
      const res = this.factorList.filter((item) => item.id === id);
      return res[0]?.text || '';
    },
    handleCheckedMetricChange(value) {
      const checkedCount = value.length;
      if (checkedCount > 5) {
        this.$message.error('选择要素不能超过5个');
        return;
      }
      this.lineData = [];
      this.getFactorData();
    },
    getUrlEncode(obj) {
      let str = '';
      Object.keys(obj).forEach((key) => {
        str += `${key}=${obj[key]}&`;
      });
      this.defaultFactor.forEach((item) => {
        str += `factorCode=${item}&`;
      });
      return str.slice(0, -1);
    },
    renderChart(data, index) {
      if (this.initChart[index]) {
        this.initChart[index].dispose();
      }
      const chartDom = document.querySelector(`.box${index}`);
      this.initChart[index] = echarts.init(chartDom);
      const valueData = [];
      const xData = [];
      data.forecastDetails.forEach((item) => {
        valueData.push(parseFloat(item.value).toFixed(2));
        xData.push(item.forecastTimeString);
      });
      this.initChart[index].setOption(lineOption(xData, valueData));
    },
    async getFactorData() {
      this.loading = true;
      const {
        production, baseTimeString, mode, region, pressure,
      } = this.currentLayerDetails;
      const data = {
        ...this.position,
        mode,
        baseTime: baseTimeString,
        production,
        pressure,
        region,
      };
      const str = this.getUrlEncode(data);
      await getCurrentPointFactorData(str)
        .then((res) => {
          const { forecast } = res;
          this.lineData = forecast ?? [];
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
      this.lineData.forEach((item, index) => {
        this.renderChart(item, index);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.wrap {
  position: absolute;
  top: 0;
  //right: 0;
  right: -600px;
  z-index: 100002;
  width: 600px;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  transition: 0.5s;

  &.mobile {
    right: -100%;
  }

  &.show {
    right: 0;
  }

  .factor-box {
    min-height: 200px;
    padding-top: 15px;

    .box {
      width: 100%;
      height: 280px;
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    font-weight: 800;
    line-height: 40px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    background: #3697d1;
    transition: background-color 0.2s;

    &:hover {
      background: #f7a715;
    }
  }
}

::v-deep {
  .el-checkbox {
    width: 25%;
  }
}
</style>
