import L, {Browser, GridLayer, Util} from 'leaflet';
import {factorMap, productionMap} from '@/config/MapConfig';
import fragmentShaderData from '@/plugins/glsl/fragshader.glsl';
import overSamplingFragmentShaderData from '@/plugins/glsl/overSamplingFragshader.glsl';
import vertexShaderText from '@/plugins/glsl/vertexShader.glsl';
import overSamplingVertexShaderText from '@/plugins/glsl/overSamplingVertexShader.glsl';
import route from '@/router/index'
import store from '@/store';
import colorArray from '@/config/ColorBarArray';
import parseDataConfig from '@/config/ColorBar';
import {decodePixel, factor_log, reverseResult,reverse} from "@/config/Utils";

const commonCanvas = L.DomUtil.create('canvas');
const handlerTileCanvas = L.DomUtil.create('canvas');
const factor_type2 = ['rh2m','t2mz','tmp2m', 'temp']

L.TileLayer.WebGL = L.GridLayer.extend({
  options: {
      url: '',
      tileLayers: [],
      zoomOffset: 0,
      pane: '',
      config:'',
      baseTimeString:0,
  },

  initialize(options) {
    // 图层初始化
    Util.setOptions(this, options);
    const size = this.getTileSize();
    // webgl的render
    this._renderer = L.DomUtil.create('canvas');
    this.config = options.config || 'dust'
    this.tileZoom = 2;
    this.baseTimeString = options.baseTimeString;
    this.gradientObj={}
    this.min = -1;
    this.max = 0;
    this.gradient = [[]];
    this.step = 0;
    this.maxIndex = 0;
    this.colors = [];
    this.ident = 0;
    this.factorId=1;
    this.opaque = true;
    this.neutralGrayIndex = 0;
    this.renderObj={
      factorType:1,
      minAlpha:0.1,
      maxAlpha:1,
    }
    this._renderer.width = size.x;
    this._renderer.height = size.y;
    this._gl = this._renderer.getContext('webgl2', {
        premultipliedAlpha: false,
        antialias: true,
      })
      || this._renderer.getContext('experimental-webgl', {
        premultipliedAlpha: false,
        antialias: true,
      });

    this._gl.viewport(0, 0, 256, 256);
    this.factor = "";
    this._url = options.url;
    this._loadGLProgram();
  },
  _loadGLProgram() {
    // 加载webgl的Program
    const gl = this._gl;
    this.getCurrentFactor()
    this.overSamplingShaderProgram = this.createShaderProgram(gl, overSamplingVertexShaderText, overSamplingFragmentShaderData);
    this.insertShaderProgram = this.createShaderProgram(gl, vertexShaderText, fragmentShaderData);
    this.screenBuffer = this.initBuffersForScreen(gl);
    this.offScreenBuffer = this.initBuffersForFramebuffer(gl);
    this.getColor();
    this.factorId = factor_type2.includes(this.factor)?1:2;
    this.renderObj = this.judgeFactorType(this.factor);
  },

  getCurrentFactor(){
    const factor = factorMap[store.state[this.config].factor]
    const timeType = store.state[this.config].timeType
    if (factor === "pratesfc" && timeType.type>1){
      this.factor = `${factor}_${timeType.type}hr`
    }else {
      this.factor = factor;
    }
  },

  getColor() {
    this.gradient = colorArray.color_arrays[this.factor].default;
    this.steps = colorArray.color_arrays[this.factor].steps;
    this.setMinMax();
    this.colors = this.createGradientArray(this.opaque);
    this.maxIndex = this.steps - 1 << 2;
    this.step = (this.max - this.min) / this.steps;
    this.gradientObj = this.createGradientObject()
  },

  setMinMax() {
    this.min = this.gradient[0][0];
    this.max = this.gradient[this.gradient.length - 1][0];
  },

  rgba2yuva(color) {
    const r = color[0];
    const g = color[1];
    const b = color[2];
    const a = 0.299 * r + 0.587 * g + 0.114 * b;
    return [a, 0.565 * (b - a), 0.713 * (r - a), color[3]];
  },

  yuva2rgba(color) {
    const r = color[0];
    const g = color[1];
    const b = color[2];
    return [r + 1.403 * b, r - 0.344 * g - 0.714 * b, r + 1.77 * g, color[3]];
  },

  createGradientArray(useDefaultFlag = true, premultiplyAlpha = false, scaleFactor = 1) {
    const stepsCount = this.steps + 1;
    const resultArray = new Uint8Array(stepsCount * 4);
    const stepSize = (this.max - this.min) / this.steps;
    const gradientStops = this.gradient;
    let currentIndex = 0;
    const startIdx = 0;
    let endIdx = 1;
    let startStop = gradientStops[startIdx];
    let endStop = gradientStops[endIdx] !== null && void 0 !== gradientStops[endIdx] ? gradientStops[endIdx] : gradientStops[startIdx];
    for (let step = 0; step < this.steps; step++) {
      const stepValue = this.min + step * stepSize * scaleFactor;
      if (stepValue > endStop[0] && endIdx < gradientStops.length) {
        startStop = endStop;
        endStop = gradientStops[++endIdx];
      }
      const interpolationFactor = (stepValue - startStop[0]) / (endStop[0] - startStop[0]);
      const interpolatedColor = this.getGradientColorYUVA(startStop[1], endStop[1], interpolationFactor);
      if (premultiplyAlpha) {
        this.makePremultiplied(interpolatedColor);
      }
      resultArray[currentIndex++] = Math.round(interpolatedColor[0]);
      resultArray[currentIndex++] = Math.round(interpolatedColor[1]);
      resultArray[currentIndex++] = Math.round(interpolatedColor[2]);
      resultArray[currentIndex++] = useDefaultFlag ? 255 : Math.round(interpolatedColor[3]);
    }

    this.neutralGrayIndex = currentIndex;
    resultArray[currentIndex++] = resultArray[currentIndex++] = resultArray[currentIndex++] = 128;
    resultArray[currentIndex++] = 255;
    return resultArray;
  },

  makePremultiplied(color) {
    const t = color[3] / 255;
    for (let n = 0; n < 3; n++) {
      color[n] = Math.max(0, Math.min(t * color[n], 255));
    }
    return color;
  },

  getGradientColorYUVA(start, end, interpolationFactor) {
    const colorStep = 1 / 255;
    const startColor = this.getMulArray(start, colorStep);
    const endColor = this.getMulArray(end, colorStep);
    const startRGB = this.rgba2yuva(startColor);
    const endRGB = this.rgba2yuva(endColor);
    const gradRGB = this.gradYuva(startRGB, endRGB, interpolationFactor, !0);
    const changeRGB = this.yuva2rgba(gradRGB);
    for (let u = 0; u < changeRGB.length; u++) {
      changeRGB[u] = Math.min(256 * changeRGB[u], 255);
    }
    return changeRGB;
  },

  getMulArray(start, colorStep) {
      const color = [];
      for (let r = 0; r < start.length; r++) {
          color.push(start[r] * colorStep);
      }
      return color;
  },

  lerpArray(startArray, endArray, factor) {
    const interpolatedArray = [];
    const complementFactor = 1 - factor;
    for (let i = 0; i < startArray.length; i++) {
      // Linear interpolation formula
      interpolatedArray.push(startArray[i] * complementFactor + endArray[i] * factor);
    }
    return interpolatedArray;
  },

  vec2size(g, b) {
    return Math.sqrt(g * g + b * b);
  },

  gradYuva(startColor, endColor, interpolationFactor, scaleInterpolation) {
    const interpolatedColor = this.lerpArray(startColor, endColor, interpolationFactor);
    if (scaleInterpolation) {
      const startSize = this.vec2size(startColor[1], startColor[2]);
      const endSize = this.vec2size(endColor[1], endColor[2]);

      if (startSize > 0.05 && endSize > 0.05) {
        const interpolatedSize = this.vec2size(interpolatedColor[1], interpolatedColor[2]);

        if (interpolatedSize > 0.01) {
          const scaleFactor = (startSize * (1 - interpolationFactor) + endSize * interpolationFactor) / interpolatedSize;
          interpolatedColor[1] *= scaleFactor;
          interpolatedColor[2] *= scaleFactor;
        }
      }
    }
    return interpolatedColor;
  },

  value2index(value) {
    return isNaN(value) ? this.neutralGrayIndex : Math.max(0, Math.min(this.maxIndex, (value - this.min) / this.step << 2));
  },

  getRGBA(value) {
    this.colors || this.getColor();
    const t = this.value2index(value);
    return [this.colors[t], this.colors[t + 1], this.colors[t + 2], this.colors[t + 3]];
  },

  // 构建webGL 上下文
  createShaderProgram(gl, vertexSource, fragmentSource) {
    const vertexShader = this.loadShader(gl, gl.VERTEX_SHADER, vertexSource);
    const fragmentShader = this.loadShader(
      gl,
      gl.FRAGMENT_SHADER,
      fragmentSource,
    );
    // 创建着色器对象
    const shaderProgram = gl.createProgram();
    // 添加着色器
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    // 多个着色器合并链接
    gl.linkProgram(shaderProgram);
    // 创建是否成功检查
    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
      console.log('无法初始化着色器程序:', gl.getProgramInfoLog(shaderProgram));
      return null;
    }
    const wrapper = { program: shaderProgram };

    const numAttributes = gl.getProgramParameter(
      shaderProgram,
      gl.ACTIVE_ATTRIBUTES,
    );
    for (let i = 0; i < numAttributes; i++) {
      const attribute = gl.getActiveAttrib(shaderProgram, i);
      wrapper[attribute.name] = gl.getAttribLocation(
        shaderProgram,
        attribute.name,
      );
    }
    const numUniforms = gl.getProgramParameter(
      shaderProgram,
      gl.ACTIVE_UNIFORMS,
    );
    for (let i = 0; i < numUniforms; i++) {
      const uniform = gl.getActiveUniform(shaderProgram, i);
      wrapper[uniform.name] = gl.getUniformLocation(
        shaderProgram,
        uniform.name,
      );
    }

    return wrapper;
  },

  initBuffersForScreen(gl) {
    const vertices = new Float32Array([
      1, 1,
      -1, 1,
      1, -1,
      -1, -1,
    ]); // 矩形
    const texCoords = new Float32Array([
      1.0, 0.0,
      0.0, 0.0,
      1.0, 1.0,
      0.0, 1.0,
    ]);

    const obj = {};
    obj.verticesBuffer = this.createBuffer(gl, gl.ARRAY_BUFFER, vertices);
    obj.texCoordsBuffer = this.createBuffer(gl, gl.ARRAY_BUFFER, texCoords);
    return obj;
  },

  initBuffersForFramebuffer(gl) {
    const vertices = new Float32Array([
      1, 1,
      -1, 1,
      1, -1,
      -1, -1,
    ]); // 矩形
    const texCoords = new Float32Array([
      1.0, 0.0,
      0.0, 0.0,
      1.0, 1.0,
      0.0, 1.0,
    ]);

    const obj = {};
    obj.verticesBuffer = this.createBuffer(gl, gl.ARRAY_BUFFER, vertices);
    obj.texCoordsBuffer = this.createBuffer(gl, gl.ARRAY_BUFFER, texCoords);
    return obj;
  },

  // 创建缓冲对象
  createBuffer(gl, type, data) {
    const buffer = gl.createBuffer();
    gl.bindBuffer(type, buffer);
    gl.bufferData(type, data, gl.STATIC_DRAW);
    gl.bindBuffer(type, null);
    return buffer;
  },
  // 绑定着色器与webGL上下文
  loadShader(gl, type, source) {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      console.log('着色器编译失败: ', gl.getShaderInfoLog(shader));
      gl.deleteShader(shader);
      return null;
    }
    return shader;
  },

  createFramebufferObject(gl) {
    const size = this.getTileSize();
    const framebuffer = gl.createFramebuffer();
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      size.x,
      size.y,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      null,
    );
    // 纹理坐标水平填充 s
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    // 纹理坐标垂直填充 t
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    // 纹理放大处理
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    // 纹理缩小处理
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    framebuffer.texture = texture; // Store the texture object

    // Attach the texture  to the FBO
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      texture,
      0,
    );

    // Check if FBO is configured correctly
    const e = gl.checkFramebufferStatus(gl.FRAMEBUFFER);
    if (gl.FRAMEBUFFER_COMPLETE !== e) {
      console.log(`Frame buffer object is incomplete: ${e.toString()}`);
      return;
    }
    // 构建缓冲对象
    // gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    // gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.bindTexture(gl.TEXTURE_2D, null);

    return { framebuffer, texture };
  },
  createGradientObject() {
    try {
      let stepsInput = this.steps;
      let steps = stepsInput;
      let stepDivisionFactor = 1;

      // If the number of steps exceed 2048, limit them to 2048 and calculate the step division factor
      if(steps > 2048) {
        // stepDivisionFactor = stepsInput / (steps = 2048);
        stepDivisionFactor = stepsInput / steps;
      }

      let calculatedSteps = 1 << Math.round(Math.log2(steps));
      if(calculatedSteps < steps) {
        calculatedSteps += calculatedSteps;
      }

      let multiplier = steps / ((this.max - this.min) * calculatedSteps);
      let incrementValue = -multiplier * this.min;
      let fourfoldR = calculatedSteps << 2;

      let gradientArray = this.createGradientArray(false, false, stepDivisionFactor);

      if (gradientArray.byteLength < fourfoldR) {
        let newArray = new Uint8Array(fourfoldR);
        newArray.set(gradientArray);

        if (gradientArray.byteLength > 7) {
          let chunk = new Uint8Array(4);
          for (let i = 0; i < 4; i++) {
            chunk[i] = gradientArray[gradientArray.byteLength - 8 + i];
          }
          for (let i = gradientArray.byteLength - 4; i < fourfoldR; i += 4) {
            newArray.set(chunk, i);
          }
        }
        gradientArray = newArray;

      } else if (gradientArray.byteLength > fourfoldR) {
        gradientArray = new Uint8Array(gradientArray.buffer, 0, fourfoldR);
      }
        return {
          mul: multiplier,
          add: incrementValue,
          arr: gradientArray,
          width: calculatedSteps,
          height: 1,
      }
      // return {
      //   // texture: this.glo.createTexture2D(Ih.LINEAR, Ih.LINEAR, Ih.CLAMP_TO_EDGE, gradientArray, calculatedSteps, 1),
      // }
    } catch (error) {
      return null;
    }
  },


  /**
   * Method to create 2D texture with detailed parameters.
   * This method is to be used in a WebGL context to create detailed textures.
   * @param {Uint8Array} textureData - Texture data in Uint8Array type
   * @param {number} width - Texture width
   * @param {number} height - Texture height
   * @param {GLenum} format - Specifies the format of the texel data (optional)
   * @param {GLenum} type - Specifies the data type of the texel data(optional)
   * @returns {WebGLTexture | null} Created WebGLTexture object or null if failed.
   */
  createTexture2D(textureData, width, height, format, type) {

    const gl = this._gl;

    if (gl) {
      const texture = gl.createTexture();
      if (texture) {
        // texture._width = width;
        // texture._height = height;
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        // 纹理坐标垂直填充 t
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        // 纹理放大处理
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        // 纹理缩小处理
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        // this.setBindedTexture2DParams(gl);
      }

      return this.resizeTexture2D(texture, textureData, width, height, format, type);
    }

    return null;
  },


  /**
   * Method to resize a 2D texture.
   * This method is to be used in a WebGL context to resize a detailed texture.
   * @param {WebGLTexture} texture - Texture sampler related to WebGL
   * @param {Uint8Array} imageData - Texture image data which is a Uint8Array
   * @param {number} width - The new width of the texture
   * @param {number} height - The new height of the texture
   * @param {string} format - Specifies the format of the texel data (e.g gl.RGBA)
   * @param {boolean} generateMipmap - A flag determines if generate mipmap for the texture or not
   * @return {WebGLTexture} - returns the resized texture
   */
  resizeTexture2D(texture, imageData, width, height, format='gl.RGBA', generateMipmap) {

    // If the texture does not exist, return null
    if (!texture) return null;

    const gl = this._gl;

    if (gl) {
      // Assign width, height and format to the texture's properties
      texture._width = width;
      texture._height = height;
      texture._format = format;

      // Bind the texture
      gl.bindTexture(gl.TEXTURE_2D, texture);

      if (Array.isArray(imageData)) {
        let currWidth = width;
        let currHeight = height;
        gl.pixelStorei(gl.UNPACK_ALIGNMENT, currWidth > 4 ? 4 : 1);

        for (let level = 0; level < imageData.length; level++) {
          const textureLevelData = imageData[level];
          if (currWidth === 4) {
            gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);
          }
          if (textureLevelData === null || ArrayBuffer.isView(textureLevelData)) {
            // Here we load every mipmap level manually
            gl.texImage2D(gl.TEXTURE_2D, level, format, currWidth, currHeight, 0, format, gl.UNSIGNED_BYTE, textureLevelData);
          } else {
            gl.texImage2D(gl.TEXTURE_2D, level, format, format, gl.UNSIGNED_BYTE, textureLevelData);
          }
          currWidth = Math.max(currWidth >> 1, 1);
          currHeight = Math.max(currHeight >> 1, 1);
        }
        // We don't need to generate mipmaps manually since we did it above
        generateMipmap = false;
      } else {
        if (imageData === null || ArrayBuffer.isView(imageData)) {
          gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, imageData);
        } else {
          gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, imageData);
        }
      }

      // Generate mipmaps if necessary
      if (generateMipmap) {
        gl.generateMipmap(gl.TEXTURE_2D);
      }

      // Unbind the texture
      gl.bindTexture(gl.TEXTURE_2D, null);
    }
    return texture;
  },



  _render(max_min, imageData, width, height) {
      const gl = this._gl;
      let framebuffer = gl.createFramebuffer();
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);

    let renderTexture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, renderTexture);

    // 初始化纹理数据
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 256, 256, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);

    // 把纹理作为 framebuffer 的 color attachment
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, renderTexture, 0);
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    const imgTexture = this.createTexture(gl, imageData);
    // 构建colorBarTexture
    const colorTexture = this.createTexture2D(this.gradientObj.arr, this.gradientObj.width, this.gradientObj.height, 'gl.RGBA', undefined);
    const program = this.overSamplingShaderProgram;
    // 原始图像的上下文
    gl.useProgram(program.program);

    gl.uniform2fv(gl.getUniformLocation(program.program, 'split_size'), [width, height]);
    gl.uniform1i(gl.getUniformLocation(program.program, 'factorId'), this.factorId);
    // 顶点数据映射
    gl.enable(gl.BLEND);
    gl.viewport(0, 0, 256, 256);
    gl.clearColor(0.0, 0.0, 0.0, 1.0);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    this.drawOffFrame(program,imgTexture, max_min)
    const data =  new Uint8Array(256*256*4);
    gl.readPixels(0, 0, 256, 256,gl.RGBA,gl.UNSIGNED_BYTE,data)
    gl.disable(gl.BLEND);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    const img_256 = this.createTexture2D(data, 256, 256, 'gl.RGBA', undefined);
    const insertProgram = this.insertShaderProgram
    gl.useProgram(insertProgram.program);
    gl.uniform2fv(gl.getUniformLocation(insertProgram.program, 'split_size'), [256.0, 256.0]);
    const uPars0 = [max_min[0],max_min[1],max_min[2],max_min[3]]
    const uPars1 = [this.gradientObj.mul,this.gradientObj.add,0,0]
    const uPars2 = [-.001, 0, 128 / 255, 0];
    const {factorType,minAlpha,maxAlpha} = this.renderObj;
    gl.uniform4fv(gl.getUniformLocation(insertProgram.program, 'uPars0'), uPars0);
    gl.uniform4fv(gl.getUniformLocation(insertProgram.program, 'uPars1'), uPars1);
    gl.uniform4fv(gl.getUniformLocation(insertProgram.program, 'uPars2'), uPars2);
    gl.uniform1i(gl.getUniformLocation(insertProgram.program, 'factorType'), factorType);
    gl.uniform1f(gl.getUniformLocation(insertProgram.program, 'minAlpha'), minAlpha);
    gl.uniform1f(gl.getUniformLocation(insertProgram.program, 'maxAlpha'), maxAlpha);
    gl.enable(gl.BLEND);
    gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
    gl.clearColor(0.0, 0.0, 0.0, 1.0);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    this.drawScreen(insertProgram, img_256, max_min, colorTexture);
    gl.disable(gl.BLEND);
    // gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  },


  judgeFactorType(factor){
    let factorType = 1;
      let minAlpha = 0.05;
      let maxAlpha = 0.5;
    if(factor === 'wind'){
      factorType = 3;
    }else if(factor_log.includes(factor)){
      if(factor === "dust_ddep" || factor === "dust_emis"){
        minAlpha = 5
        maxAlpha = 10
      }else if(factor === 'ri_min'){
        factorType = 5;
        maxAlpha = 7.5
      }else if(factor ==='max_reflectivity'|| factor ==='base_reflectivity'){
        minAlpha = 5
        maxAlpha = 10
      }else if(factor ==='dust_conc'){
        minAlpha = 0
        maxAlpha = 100
      }else if(factor === 'snow' || factor === 'ice'  ||  factor === 'graupel' || factor === 'rain'){

        minAlpha = 0.1;
        maxAlpha = 0.3;
      }else if(factor === 'pre_total' || factor === 'pres_all'  ||  factor === 'prer'){
        minAlpha = 0.2;
        maxAlpha = 1;
      }else if(factor === 'dswrfsfc') {
        maxAlpha = 100
        minAlpha = 50
      }
      factorType = 4;
    }else if(factor_type2.includes(factor)){
      factorType = 2;
    }else if(factor === 'ri_min') {
        factorType = 5;
        maxAlpha = 15
        minAlpha = 7.5
    }else{
      factorType = 1;
    }
      return {
        factorType,
        minAlpha,
        maxAlpha
      };
  },



  drawOffFrame(program, texture, max_min) {
      const gl = this._gl;
      const targetBuffer = this.offScreenBuffer;
      this.activeBindTexture(gl, texture, 0);
      this.bindEnableBuffer(
          gl,
          targetBuffer.texCoordsBuffer,
          program.aTextureCoords,
          2,
      );
      this.bindEnableBuffer(
          gl,
          targetBuffer.verticesBuffer,
          program.aVertexCoords,
          2,
      );
      gl.uniform1i(program.uTexture0, 0);
      gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

  },

  drawScreen(program, texture, max_min,colorTexture) {
    const gl = this._gl;
    const targetBuffer = this.screenBuffer;
    this.activeBindTexture(gl, texture, 1);
    this.activeBindTexture(gl, colorTexture, 2);
    this.bindEnableBuffer(
      gl,
      targetBuffer.texCoordsBuffer,
      program.aTextureCoords,
      2,
    );
    this.bindEnableBuffer(
      gl,
      targetBuffer.verticesBuffer,
      program.aVertexCoords,
      2,
    );

    gl.uniform1i(program.uTexture0, 1);
    gl.uniform1i(program.uGradientTexture, 2);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  },

  activeBindTexture(gl, texture, unit) {
    gl.activeTexture(gl.TEXTURE0 + unit);
    gl.bindTexture(gl.TEXTURE_2D, texture);
  },

  // 缓冲并激活对应顶点属性
  bindEnableBuffer(gl, buffer, attribute, numComponents) {
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
    gl.enableVertexAttribArray(attribute);
    gl.vertexAttribPointer(attribute, numComponents, gl.FLOAT, false, 8, 0);
  },

  createTexture(gl, source) {
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    // 反转图片 Y 轴方向 在去顶点坐标和纹理坐标的时候，坐标顺序已经经过了反转
    // gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);
    // 纹理坐标水平填充 s
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    // 纹理坐标垂直填充 t
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    // 纹理放大处理
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    // 纹理缩小处理
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    // gl.generateMipmap(gl.TEXTURE_2D);
    // 图片数据赋给纹理对象
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      source,
    );

    gl.bindTexture(gl.TEXTURE_2D, null);
    return texture;
  },
  _addTile(coords, container) {
    // 在createTile之前由GridLayer进行调用
    this._unwrappedKey = this._tileCoordsToKey(coords);
    L.GridLayer.prototype._addTile.call(this, coords, container);
  },

  createTile(coords, done, async = false) {
    // 创建图层 coords: x y z 表示层级的不同位置的瓦片 0:0:1
    // 每访问一个瓦片都要执行createTile方法
    const size = this.getTileSize();
    const tile = L.DomUtil.create('canvas', 'leaflet-tile');
    tile.width = size.x;
    tile.height = size.y;
    const imgCtx = commonCanvas.getContext('2d', { willReadFrequently: true });
    commonCanvas.width = size.x;
    commonCanvas.height = size.y + 8;

    const ctx = tile.getContext('2d', { willReadFrequently: true });
    this._getNthThreeTile(coords, this._url).then((textureImages) => {
        if (!this._map) {
          return;
        }
        const handlerTile = this.dealNearTiles(textureImages);
        imgCtx.putImageData(handlerTile, 0, 0);
        const imageData = imgCtx.getImageData(0, 0, size.x, 8);
        const result = decodePixel(imageData.data, 256);
        const [max, min, vmax, vmin] = result;
        const {
          x, y, width, height,
        } = this.computeSubImageBounds(coords, this.tileZoom - 1);
        const startX = Math.max(0, x - 1);
        const startY = Math.max(0, y - 1);
        const endWidth = width + (x - startX);
        const endHeight = height + (y - startY);
        const cropData = imgCtx.getImageData(startX, 8 + startY, endWidth, endHeight);
        // factor 累计降水 放弃数据
        this._render([max, min, vmax, vmin], cropData, endWidth, endHeight,startX,startY);
        // 开启后续的渲染
        ctx.drawImage(this._renderer, 0, 0);
        store.state.map.loading ? store.commit('map/setLoading', false) : ''
        done();
      },
      (err) => {
        L.TileLayer.prototype._tileOnError.call(this, done, tile, err);
      });

    return tile;
  },

  dealNearTiles(textureImages) {
    const factor = this.factor;
    const size = this.getTileSize();
    handlerTileCanvas.width = size.x;
    handlerTileCanvas.height = size.y + 8;
    const ctx = handlerTileCanvas.getContext('2d', { willReadFrequently: true });
    const image1 = textureImages[0];
    const image2 = textureImages[1];
    ctx.drawImage(image1, 0, 0);
    const imageDataResult1 = ctx.getImageData(0, 0, size.x, 8);
    const [max1, min1, vmax1, vmin1] = decodePixel(imageDataResult1.data, 256);
    const imageData1 = ctx.getImageData(0, 0, image1.width, image1.height);
    const h1 = imageData1.height
    const w1 = imageData1.width
    ctx.drawImage(image2, 0, 0);
    // 获取image2的像素数据
    const imageDataResult2 = ctx.getImageData(0, 0, size.x, 8);
    const [max2, min2, vmax2, vmin2] = decodePixel(imageDataResult2.data, 256);
    if (image2.direction === 'right'){
      const imageData2 = ctx.getImageData(0, 0, image2.width, image2.height);
      const w2 = imageData2.width
      this.mergeRightEdge(imageData1,imageData2,h1,w1,w2,max1, min1, vmax1, vmin1,max2, min2, vmax2, vmin2,factor)
    }else{
      const imageData2 = ctx.getImageData(0, 8, image2.width, 1);
      this.mergeBottomEdge(imageData1,imageData2,w1,h1,max1, min1, vmax1, vmin1, max2, min2, vmax2, vmin2,factor)
      return imageData1;
    }
    if (textureImages.length === 3) {
      const image3 = textureImages[2];
      // 获取第三张图片的像素数据
      ctx.drawImage(image3, 0, 0);
      const imageDataResult3 = ctx.getImageData(0, 0, size.x, 8);
      const [max3, min3, vmax3, vmin3] = decodePixel(imageDataResult3.data, 256);
      const imageData3 = ctx.getImageData(0, 8, image3.width, 1);
      this.mergeBottomEdge(imageData1,imageData3,w1,h1,max1, min1, vmax1, vmin1, max3, min3, vmax3, vmin3,factor)
    }
    return imageData1;
  },
  mergeRightEdge(image1,image2,h1,w1,w2,max1, min1, vmax1, vmin1,max2, min2, vmax2, vmin2,factor){
    const data2 = image2.data
    const data1 = image1.data
    for (let i = 8; i < h1; i++) {
      const index1 = (w1 - 1 + i * w1) * 4;
      const index2 = i * w2 * 4;
      // 像素值处理
      const resultData2 = reverse(data2[index2], data2[index2 + 1], max2, min2, vmax2, vmin2, factor,this.config);
      const reverseData = reverseResult(resultData2[0], max1, min1, vmax1, vmin1, factor, resultData2[1], this.config);
      data1[index1] = reverseData[0];
      data1[index1 + 1] = reverseData[1];
      data1[index1 + 2] = data2[index2 + 2];
      data1[index1 + 3] = data2[index2 + 3];
    }
  },
  mergeBottomEdge(image1,image2,w1,h1,max1, min1, vmax1, vmin1, max3, min3, vmax3, vmin3,factor){
    const data1 = image1.data
    const data3 = image2.data
    const len = data1.length
    for (let k = 0; k < h1 * 4; k += 4) {
      const resultData3 = reverse(data3[k], data3[k + 1], max3, min3, vmax3, vmin3, factor,this.config);
      const newData = reverseResult(resultData3[0], max1, min1, vmax1, vmin1, factor, resultData3[1],this.config);
      data1[len - w1 * 4 + k] = newData[0];
      data1[len - w1 * 4 + k + 1] = newData[1];
      data1[len - w1 * 4 + k + 2] = data3[k + 2];
      data1[len - w1 * 4 + k + 3] = data3[k + 3];
    }
  },

  _removeTile(key) {
    const tile = this._tiles[key];
    if (!tile) { return null; }
    if (!Browser.androidStock) {
      tile.el.setAttribute('src', Util.emptyImageUrl);
    }

    return GridLayer.prototype._removeTile.call(this, key);
  },

  onAdd() {
    L.TileLayer.prototype.onAdd.call(this);
  },

  onRemove(map) {
    L.TileLayer.prototype.onRemove.call(this, map);
  },


  updateUrl(url, done) {
    this._url = url;
    if (!this._map) return;
    // this.onRemove(this._map)
    const center = this._map.getCenter();
    const pixelBounds = this._getTiledPixelBounds(center);
    const tileRange = this._pxBoundsToTileRange(pixelBounds);

    const queue = [];
    const hashmap = {};

    for (let j = tileRange.min.y; j <= tileRange.max.y; j++) {
      for (let i = tileRange.min.x; i <= tileRange.max.x; i++) {
        const coords = new L.Point(i, j);
        coords.z = this._tileZoom;

        if (!this._isValidTile(coords)) {
          continue;
        }
        queue.push(coords);
        hashmap[this._tileCoordsToKey(coords)] = coords;
      }
    }

    if (!this._loading) {
      this._loading = true;
      this.fire('loading');
    }

    Object.keys(this._tiles).forEach((key) => {
      if (!hashmap[key]) {
        this._removeTile(key);
      }
    });

    Promise.all(queue.map((item) => this._getNthTile(this._wrapCoords(item), url))).then((list) => {
     const coordsArr =this._getUpdateImageUrl(list);
      for (let index = 0; index < list.length; index++) {
        const img = list[index];
        const coords = queue[index]
        const key = this._tileCoordsToKey(coords);
        const tile = this._tiles[key]?.el;
        // 构建canvas
        const tileCtx = tile.getContext('2d');
        const size = this.getTileSize();
        tileCtx.clearRect(0, 0, size.x, size.y);
        const ctx = commonCanvas.getContext('2d');
        const startTime = new Date().getTime(); // 获取开始时间
        const playImage = this._handlerPlayerTiles(coordsArr[index],coordsArr,list,index);
        if (playImage.isImageData) {
          ctx.putImageData(playImage.imageData, 0, 0);
        }else {
          ctx.drawImage(img, 0, 0);
        }
          // ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, size.x, 8);
        const [max, min, vmax, vmin] = decodePixel(imageData.data, 256);
        // const imageData1 = imgCtx.getImageData(0, 8, size.x, size.y);
        const {
          x, y, width, height,
        } = this.computeSubImageBounds(queue[index], this.tileZoom - 1);
        const startX = Math.max(0, x - 1);
        const startY = Math.max(0, y - 1);
        const endWidth = width + (x - startX);
        const endHeight = height + (y - startY);
        const cropData = ctx.getImageData(startX, 8 + startY, endWidth, endHeight);
        this._render([max, min, vmax, vmin], cropData, width, height);
        tileCtx.drawImage(this._renderer, 0, 0);
        // loading在进行中时在第一张瓦片渲染完成后结束loading
        if (index === 0 && store.state.map.loading) store.commit('map/setLoading', false)
      }
      requestAnimationFrame(() => {
        done && done();
        this._loading = false;
        this._loaded = true;
        this.fire('load');
      });
    });
  },

  //需要一个方法根据坐标计算临近的坐标点位,然后替换相邻数据
  _handlerPlayerTiles(coors,queue,list,index){
    const x = coors.x
    const y = coors.y
    const z = coors.z
    const { right_coords, under_coords } = this.getNearTile({ x_tile:x, y_tile:y, z_tile:z });
    const textureImages = []
    const imgSelf = list[index]
    imgSelf.direction = 'self';
    textureImages.push(imgSelf);

    const rightIndex = queue.findIndex(obj => obj.x === right_coords.x && obj.y === right_coords.y && obj.z === right_coords.z);
    if (rightIndex>-1){
      const imgRight = list[rightIndex]
      imgRight.direction = 'right';
      textureImages.push(imgRight)
    }
    if (Object.entries(under_coords).length > 0){
      // 右侧以及下册数据都存在需要同时判断
      const underIndex = queue.findIndex(obj => obj.x === under_coords.x && obj.y === under_coords.y && obj.z === under_coords.z);
      if (underIndex>-1){
        const imgUnder = list[underIndex]
        imgUnder.direction = 'under';
        textureImages.push(imgUnder)
      }
    }
    if (textureImages.length>=2){
      const imageData = this.dealNearTiles(textureImages);
      return {
        isImageData:true,
        imageData
      }
    }
    return {
      isImageData:false,
      imageData: '',
    }
  },

  _getUpdateImageUrl(list) {
    return list.map(img => {
      const urlArr = img.src.split("/");
      const z = Number(urlArr[urlArr.length - 3]);
      const x = Number(urlArr[urlArr.length - 2]);
      const y = Number(urlArr[urlArr.length - 1].split('.')[0]);
      return { x, y, z }; // 返回一个包含x, y, z的对象
    });
  },

  _getNthTile(coords, _tileUrl) {
    // 获取瓦片图片，返回一个Promiss
    const tile = new Image();
    tile.crossOrigin = '';
    // 根据瓦片的访问地址获取不同层级的瓦片{x}/{y}/{z}
    const url = this.getTileUrl(this._wrapCoords(coords), _tileUrl);
    const urlArr = url.split('/');
    const z = urlArr[urlArr.length - 3];
    const x = urlArr[urlArr.length - 2];
    const y = urlArr[urlArr.length - 1].split('.')[0];
    this.tileZoom = this.confirmZoom(z - 1);
    const { x_tile, y_tile, z_tile } = this._getCoords(x, y, z, this.tileZoom);
    const last = `${y_tile}.png`;
    urlArr.splice(-3, 3, z_tile, x_tile, last);
    tile.src = `${urlArr.slice(0, -1).join('/')}/${urlArr.slice(-1)}`;
    return new Promise((resolve, reject) => {
      tile.addEventListener('load', () => {
        resolve(tile);
      });
      tile.addEventListener('error', (error) => {
        reject(error);
      });
    });
  },

  _getNthThreeTile(coords, _tileUrl) {
    // 根据瓦片的访问地址获取不同层级的瓦片{x}/{y}/{z}
    const url = this.getTileUrl(this._wrapCoords(coords), _tileUrl);
      const urlArr = url.split('/');
    const z = urlArr[urlArr.length - 3];
    const x = urlArr[urlArr.length - 2];
    const y = urlArr[urlArr.length - 1].split('.')[0];
    this.tileZoom = this.confirmZoom(z - 1);
    const { x_tile, y_tile, z_tile } = this._getCoords(x, y, z, this.tileZoom);
    const { right_coords, under_coords } = this.getNearTile({ x_tile, y_tile, z_tile });
    let promises = [];
    if (Object.entries(under_coords).length > 0) {
      promises = [
        loadImage(this.getHandlerNewTileUrl(urlArr, x_tile, y_tile, z_tile),'self'),
        loadImage(this.getHandlerNewTileUrl(urlArr, right_coords.x, right_coords.y, right_coords.z),'right'),
        loadImage(this.getHandlerNewTileUrl(urlArr, under_coords.x, under_coords.y, under_coords.z),'under'),
      ];
    } else {
      promises = [
        loadImage(this.getHandlerNewTileUrl(urlArr, x_tile, y_tile, z_tile),'self'),
        loadImage(this.getHandlerNewTileUrl(urlArr, right_coords.x, right_coords.y, right_coords.z),'right'),
      ];
    }

    return Promise.all(promises);
    function loadImage(src,direction) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = '';
        img.src = src;
        img.direction = direction;
        img.addEventListener('load', () => resolve(img));
        img.addEventListener('error', (error) => reject(error));
      });
    }
  },

  getHandlerNewTileUrl(urlArr, x, y, z) {
    const copiedUrlArr = [...urlArr];
    const last = `${y}.png`;
    copiedUrlArr.splice(-3, 3, z, x, last);
    return `${copiedUrlArr.slice(0, -1).join('/')}/${copiedUrlArr.slice(-1)}`;
  },

  _getCoords(x, y, z, tileZoom) {
    const x_tile = Math.floor(x / Math.pow(2, z - tileZoom));
    const y_tile = Math.floor(y / Math.pow(2, z - tileZoom));
    const z_tile = tileZoom;
    return { x_tile, y_tile, z_tile };
  },

  getTileUrl(coords, _tileUrl) {
    const data = {
      r: '',
      s: '',
      x: coords.x,
      y: coords.y,
      z: coords.z + this.options.zoomOffset,
    };

    if (this._map && !this._map.options.crs.infinite) {
      const invertedY = this._globalTileRange.max.y - coords.y;
      if (this.options.tms) {
        data.y = invertedY;
      }
      data['-y'] = invertedY;
    }
    return Util.template(_tileUrl, Util.extend(data, this.options));
  },
  /**
   * 计算瓦片大小方便数据插值
   * @param coords
   * @param baseZ
   * @returns {{x: number, width: number, y: number, height: number}}
   */

  computeSubImageBounds(coords, baseZ) {
    const factor = Math.pow(2, coords.z - baseZ);
    const size = 256 / factor;
    const offsetX = (coords.x % factor) * size;
    const offsetY = (coords.y % factor) * size;
    return {
      x: offsetX,
      y: offsetY,
      width: size,
      height: size,
    };
  },

    confirmImproveZoom(){
        if ( store.state[this.config].hd === 'none') {
          return {
              num:2,
              diffNum:0
          }
        }else {
          if (this.baseTimeString>2024060320){
            return {
                num:0,
                diffNum:0
            }
          }
            return {
                num:0,
                diffNum:1
            }
        }

    },

  confirmZoom(zoom) {
    const factor = store.state[this.config].factor;
    const tileProduction = productionMap[factor];
    const production = store.state[this.config].t2Mode;
    // const num = store.state[this.config].hd === 'none'?2:0;
    // const diffNum = store.state[this.config].hd === 'none'?0:(this.baseTimeString>2024060320?0:1);
    const {num,diffNum} = this.confirmImproveZoom()
    if (production === '聚合') {
      if (tileProduction === 'c2_5km') {
        const maxZoom = num === 0?5:6;
        switch (zoom) {
          case 2:
          case 3:
            return 1 + num + diffNum;
          case 4:
            return 2 + num + diffNum;
          case 5:
            return 3 + num + diffNum;
          case 6:
            return 4 + num + diffNum;
          default:
            return maxZoom;
        }
      } else if (factor === 'tmp2m') {
        const maxZoom = num === 0?6:7;
        switch (zoom) {
          case 2:
          case 3:
            return 1 + num + diffNum
          case 4:
            return 2 + num + diffNum;
          case 5:
            return 3 + num + diffNum;
          case 6:
            return 4 + num + diffNum;
          case 7:
            return 5 + num + diffNum;
          default:
            return maxZoom;
        }
      } else {
        const maxZoom = num === 0?3:4;
        switch (zoom) {
          case 2:
          case 3:
            return 1 + num + diffNum;
          default:
            return maxZoom;
        }
      }
    } else if(route.currentRoute.name === 'dust'){
      const maxZoom = num === 0?3:4;
        switch (zoom) {
            case 2:
            case 3:
                return 1 + num + diffNum;
            default:
                return maxZoom;
        }
    }else {
      const maxZoom = num === 0?5:6;
      switch (zoom) {
        case 2:
        case 3:
          return 1 + num + diffNum;
        case 4:
          return 2 + num + diffNum;
        case 5:
          return 3 + num + diffNum;
        case 6:
          return 4 + num + diffNum;
        default:
          return maxZoom;
      }
    }
  },

  // 判定瓦片是不是属于边界区域
  getNearTile(coords) {
    const { x_tile, y_tile, z_tile } = coords;
    const totalX = Math.pow(2, z_tile);
    const totalY = Math.pow(2, z_tile - 1);
    let right_coords = {};
    let under_coords = {};
    // 判定瓦片所处于的位置区域 获取右边相邻瓦片以及下侧瓦片
    // 1. 瓦片处于非最下方的一行 需要考虑右侧下侧
    // 2. 瓦片处于最下方的一行 只需要考虑右侧
    if (y_tile === 0) {
      // 位于最后一行 判定x最右侧
      if (x_tile === totalX - 1) {
        right_coords = {
          x: 0,
          y: y_tile,
          z: z_tile,
        };
      } else {
        // 有相邻瓦片 坐标
        right_coords = {
          x: x_tile + 1,
          y: y_tile,
          z: z_tile,
        };
      }
    } else {
      // 既有下方右侧
      if (x_tile === totalX - 1) {
        right_coords = {
          x: 0,
          y: y_tile,
          z: z_tile,
        };
      } else {
        // 有相邻瓦片 坐标
        right_coords = {
          x: x_tile + 1,
          y: y_tile,
          z: z_tile,
        };
      }
      under_coords = {
        x: x_tile,
        y: y_tile - 1,
        z: z_tile,
      };
    }
    return { right_coords, under_coords };
  },

  _getZoomForUrl() {
    let zoom = this._tileZoom;
    const maxZoom = this.options.maxZoom;
    const zoomReverse = this.options.zoomReverse;
    const zoomOffset = this.options.zoomOffset;

    if (zoomReverse) {
      zoom = maxZoom - zoom;
    }

    return zoom + zoomOffset;
  },

  _clampZoom(zoom) {
    // 固定当前的缩放级别
    const options = this.options;
    if (undefined !== options.minNativeZoom && zoom < options.minNativeZoom) {
      return options.minNativeZoom;
    }
    if (undefined !== options.maxNativeZoom && options.maxNativeZoom < zoom) {
      return options.maxNativeZoom;
    }
    return zoom;
  },
  _tileBounds(tile) {
    const { x, y } = tile.coords;
    const tileSize = 256;
    const topLeft = L.point(x * tileSize, y * tileSize);
    const bottomRight = L.point(
      topLeft.x + (tileSize - 1),
      topLeft.y + (tileSize - 1),
    );
    return L.bounds(topLeft, bottomRight);
  },
  _getCoordsInTile(tile, pixelCoords) {
    const { x: tileX, y: tileY } = tile.coords;
    const tileSize = 256;
    return L.point(
      pixelCoords.x - (tileX * tileSize),
      pixelCoords.y - (tileY * tileSize),
    );
  },

  getPixelValue(latlng) {
    const pixelCoords = this._map.project(latlng, this._tileZoom).floor();
    const containingTile = Object.values(this._tiles).find((tile) => tile.coords.z === this._tileZoom && this._tileBounds(tile).contains(pixelCoords));
    const coordsInTile = containingTile && this._getCoordsInTile(containingTile, pixelCoords);
    let byteIndex;
    if (coordsInTile) {
      byteIndex = (coordsInTile.y * 256 + coordsInTile.x) * 4;
    }
    if (!byteIndex) {
      return undefined;
    }
    const { el } = containingTile;

    const ctx = el.getContext('2d');
    const data = ctx.getImageData(0, 0, 256, 256).data;
    const rgb = [data[byteIndex], data[byteIndex + 1], data[byteIndex + 2], data[byteIndex + 3]];
    if (rgb[3] === 0) {
      return coordsInTile && {pixelsValue:0.0,
        isMaxValue:false,
        maxValue:this.max,
        minValue:this.min
      }
    }
    const  index = this.indClosestColorIndex(this.colors, rgb);
    return coordsInTile && this.color2value(index);
  },
  indClosestColorIndex(colorsArray, targetColor) {
    let closestIndex = -1; // 最接近颜色的索引初始值设为-1
    let closestDistance = Infinity; // 最小距离初始值设为无限大
    // 遍历颜色数组
    for (let i = 0; i < colorsArray.length; i+=4) {
      // 计算目标颜色与当前颜色之间的欧几里得距离
      let distance = Math.sqrt(
        Math.pow(colorsArray[i] - targetColor[0], 2) +
        Math.pow(colorsArray[i+1] - targetColor[1], 2) +
        Math.pow(colorsArray[i+2] - targetColor[2], 2) +
        Math.pow(colorsArray[i+3] - targetColor[3], 2) // alpha 通道的值是 0-1 的浮点数
      );
      // 检查这个距离是否是目前为止我们找到的最小距离
      if (distance < closestDistance) {
        closestDistance = distance; // 更新最小距离
        closestIndex = i; // 更新最接近颜色的索引
      }
    }
    // 返回最接近颜色的索引
    return closestIndex;
  },
  color2value(index){
    index >>= 2;
    const pixelsValue = parseFloat((index * this.step + this.min).toFixed(2))
    const isMaxValue = pixelsValue>=this.max;
    const maxValue = this.max
    const minValue = this.min
    let colorValue = 0;
    colorValue = pixelsValue;

    return {
      pixelsValue:colorValue,
      isMaxValue,
      maxValue,
      minValue
    };
  },
});

L.tileLayer.WebGL = function (opts) {
  return new L.TileLayer.WebGL(opts);
};
