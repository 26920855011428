import echarts from '@/plugins/echarts';
import parseDataConfig from '@/config/ColorBar';
import { factorMap } from '@/config/MapConfig';

export const colorList = (factor) => {
  const color = parseDataConfig.color_arrays[factorMap[factor]];
  return color.map((item, index) => ({
    offset: index / color.length,
    color: `rgb(${item.length === 4 ? item.slice(0, -1) : item})`,
  }));
};

const tempColorList = () => [
  {
    offset: 0,
    color: '#E1DCFD',
  },
  {
    offset: 0.5,
    color: '#CDE8EB',
  },
  {
    offset: 1,
    color: '#FDBB45',
  },
  // {
  //   offset: 0.75,
  //   color: '#CDE8EB',
  // },
  // {
  //   offset: 1,
  //   color: '#E1DCFD',
  // },
];

export const seriesConfig = {
  type: 'line',
  symbol: 'none',
  label: {
    position: 'top',
  },
};

export const chartOption = (xData, data) => ({
  tooltip: {
    show: false,
  },
  grid: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  xAxis: [
    {
      show: false,
      type: 'category',
      boundaryGap: false,
      data: xData,
      minInterval: 1,
      axisLine: {
        onZero: false, //  当y轴有负数时,让x轴下落在y轴最小值
      },
    },
  ],
  yAxis: [
    {
      show: false,
      type: 'value',
    },
  ],
  series: [
    {
      type: 'line',
      smooth: true,
      lineStyle: {
        width: 0,
      },
      showSymbol: false,
      areaStyle: {
        origin: 'start',
        opacity: 0.5,
        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, tempColorList()),
      },
      emphasis: {
        focus: 'series',
      },
      data,
    },
  ],
});

export const lineOption = (timeData, data) => ({
  xAxis: {
    type: 'category',
    data: timeData,
    axisLabel: {
      // interval: 0,
      rotate: 60,
      // formatter: '{yyyy}-{MM}-{dd}-{hh}',
    },
  },
  grid: {
    left: '10%',
    top: '5%',
    // top: '15%',
  },
  // legend: {
  //   // right: 0,
  //   show: false,
  //   orient: 'vertical',
  //   right: 15,
  //   top: 'center',
  //   data: legendData,
  // },
  tooltip: {
    trigger: 'axis',
  },
  yAxis: {
    type: 'value',
  },
  series: [{
    type: 'line',
    symbol: 'none',
    label: {
      position: 'top',
    },
    data,
  }],
});

export const mobileLineOption = (timeData, legendData, data) => ({
  xAxis: {
    type: 'category',
    data: timeData,
    axisLabel: {
      // interval: 0,
      rotate: 60,
      // formatter: '{yyyy}-{MM}-{dd}-{hh}',
    },
  },
  // grid: {
  //   left: '5%',
  //   top: '5%',
  // },
  legend: {
    // right: 0,
    // orient: 'vertical',
    // right: 15,
    top: '15',
    data: legendData,
  },
  tooltip: {
    trigger: 'axis',
  },
  yAxis: {
    type: 'value',
  },
  series: data,
});
