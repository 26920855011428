<template>
  <div class="elevation_select">
    <div class="label">
      区域
    </div>

    <div class="select_wrap">
      <el-select v-model="value">
        <el-option
          v-for="item of regionList"
          :key="item.regionCode"
          :label="item.regionName"
          :value="item.regionCode"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('map', ['region', 'regionList']),
    value: {
      get() {
        return this.region;
      },
      set(value) {
        this.setRegion(value);
      },
    },
  },
  methods: {
    ...mapMutations('map', ['setRegion']),
  },
};
</script>

<style lang="scss" scoped>
.elevation_select {
  height: 42px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  background: rgba($text-operate, $alpha: 0.92);
  border-radius: 21px;
  margin-right: 10px;

  .label {
    font-size: 12px;
    color: #666;
    margin-right: 10px;
    cursor: move;
  }

  .select_wrap {
    width: 80px;
  }
}
</style>
