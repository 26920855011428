<template>
  <div
    class="home"
  >
    <v-map
      ref="map"
      t3
    />
    <v-search
      v-if="!isMobile"
      t3
      @locate="setLocation"
    />
    <v-title
      title="中科天机3"
      border
      @change="$router.push('/')"
    >
      <v-search
        v-if="!isMobile"
        slot="search"
        t3
        @locate="setLocation"
      />
    </v-title>
    <v-legend
      :factor="factor"
      :mobile="isMobile"
    />
    <v-control
      t3
      show
      @setting="showSetting = !showSetting;"
    >
      <v-group-select :show.sync="showSetting" />
    </v-control>
    <v-player t3 />
    <v-elevation v-if="!isMobile && elevationList && elevationList.length" />
    <template v-if="!isMobile">
      <v-bottom-more />
      <v-metric-select
        :metrics="t3Metrics"
        :factor="factor"
        @change="factorChange"
      />
      <V-layer-selected />
    </template>

    <template v-else>
      <v-mobile-bottom-more />
      <v-mobile-operate-menu
        :factor="factor"
        :windy="windy"
        :metrics="t3Metrics"
        :init-base-time="currentBaseTime"
        :base-time="baseTime"
        @handleBaseTimeChange="val => setCurrentBaseTime(val)"
        @change="factorChange"
        @setWindy="setWindy"
      />
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { getRegion, getDataByFactor } from '@/api/t3';
import { factorWind, t3Metrics } from '@/config/MapConfigT4';
import Bus from '@/bus/index';
import VLegend from '@/components/Map/Legend.vue';
import VControl from '@/components/Map/Control.vue';
import VTitle from '@/components/Map/Title.vue';
import VLayerSelected from '@/components/Map/LayerSelected.vue';
import VElevation from '@/components/Map/Elevation.vue';
import VMetricSelect from '@/components/Map/MetricSelect.vue';
import VMobileOperateMenu from '@/components/Map/MobileOperateMenu.vue';
import VGroupSelect from '@/views/map_hd/components/GroupSelect.vue';
import VBottomMore from '@/components/Map/FactorDataMore.vue';
import VMobileBottomMore from '@/components/Map/MobileFactorDataMore.vue';
import VSearch from '@/components/Map/Search.vue';
import VPlayer from '../map_hd/components/Player.vue';
import VMap from '../map_hd/Map.vue';

export default {
  name: 'Home',
  components: {
    VMap,
    VPlayer,
    VLegend,
    VTitle,
    VElevation,
    VLayerSelected,
    // VTime,
    VMetricSelect,
    VControl,
    VMobileOperateMenu,
    VGroupSelect,
    VSearch,
    VBottomMore,
    VMobileBottomMore,
  },
  data() {
    return {
      showSetting: false,
      t3Metrics,
    };
  },
  computed: {
    ...mapState('map', ['factor', 'windy', 'isobar', 'currentBaseTime', 'region']),
    ...mapState(['isMobile']),
    ...mapGetters('map', ['baseTime', 'currentTime', 'elevationList']),
  },
  created() {
    this.init(true);
  },
  methods: {
    setLocation(position) {
      this.$refs.map.setLocation(position);
    },
    ...mapMutations('map', ['setFactorData', 'setCurrentBaseTime', 'setStatus', 'setFactor', 'setWindData', 'setIndex', 'setWindy', 'setIsobar', 'setRegionList', 'setRegion']),
    /**
     * @description 根据要素获取预测数据
     * @returns {Promise}
     * @author yujie
     */
    async init(first) {
      try {
        const region = await getRegion();
        this.setRegionList(region);
        this.setRegion(region[0] ?? {});
        const data = await getDataByFactor(this.factor, this.region.regionCode);
        if (!data || !data.length) {
          this.$message('当前要素无预测数据');
          return;
        }
        if (factorWind[this.factor]) {
          const windData = await getDataByFactor(factorWind[this.factor], this.region.regionCode);
          this.setWindData(windData);
        }
        this.setFactorData(data);
        if (first) {
          this.setCurrentBaseTime(data[0].baseTimeModel);
          this.setIndex(0);
          this.$refs.map.initMap();
        }
        this.$refs.map.render();
      } catch (error) {
        // do nothing
        console.error(error);
      }
    },
    /**
     * @description 要素改变事件
     * @returns {void}
     * @author yujie
     * @param metric
     */
    factorChange(metric) {
      Bus.$emit('removePopup');
      Bus.$emit('clearLayer');
      this.setStatus('pause');
      this.setFactor(metric.id);
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  position: relative;
}
</style>
