<template>
  <div
    ref="verticalWrap"
    class="wrap"
    :class="{
      vertical_wrap: isVertical,
    }"
  >
    <i
      v-show="loading"
      class="el-icon-loading"
    />
    <div
      v-show="forecastImage"
      class="predict"
    >
      <img
        :src="forecastImage"
        alt=""
      >
      <span>天机2预测</span>
    </div>
    <div>
      <div
        v-show="realImage"
      >
        <img
          :src="realImage"
          alt=""
          style="position: relative;bottom: -12px;width: 100%;height: 106%"
        >
        <span style="bottom: -2px">风云卫星</span>
      </div>
      <div
        v-show="initShowDiffHour && !realImage"
        class="no_img_text"
      >
        <p>
          {{ getDesc(this.currentSatelliteTime) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import moment from 'moment';
import Bus from '@/bus/index';
import { getQuality } from '@/utils/index';
import { getSatelliteImage } from '@/api/image_hd';

export default {
  props: {
    isVertical: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forecastImage: '',
      realImage: '',
      initShowDiffHour: false, // 初始不显示差值
      diffHour: '',
      quality: 1,
      first: true,
    };
  },
  computed: {
    ...mapState('satellite', ['status', 'loading', 'requestIndex', 'currentBaseTime', 'screenSize']),
    ...mapGetters('satellite', ['current', 'currentRequest', 'currentTime', 'currentSatelliteTime']),
  },
  watch: {
    currentBaseTime() {
      this.setStatus('pause');
      this.$nextTick(() => {
        this.renderPicture();
      });
    },
    status(val) {
      if (val === 'pause') {
        if (this.forecastCancel && typeof this.forecastCancel === 'function') { this.forecastCancel(); }
        if (this.realCancel && typeof this.realCancel === 'function') { this.realCancel(); }
      }
    },
  },
  created() {
    Bus.$on('renderSatellite', this.renderPicture);
  },
  beforeDestroy() {
    Bus.$off('renderSatellite', this.renderPicture);
  },
  methods: {
    ...mapMutations('satellite', ['setLoading', 'setIndex', 'setStatus']),
    /**
     * @description 渲染图片
     * @returns {Promise}
     * @author caomf
     */
    async renderPicture() {
      if (this.forecastCancel && typeof this.forecastCancel === 'function') { this.forecastCancel(); }
      if (this.realCancel && typeof this.realCancel === 'function') { this.realCancel(); }
      if (this.status !== 'play') {
        this.setLoading(true);
      }
      const promise = [];
      const start = Date.now();
      const current = this.status === 'play' ? this.currentRequest : this.current;
      if (current.imagePathForecast) {
        promise.push(
          current.imagePathForecast
            ? getSatelliteImage(
              current.imagePathForecast,
              this.quality,
              (c) => {
                this.forecastCancel = c;
              },
            ) : '',
        );
      }
      if (current.imagePathForecast) {
        promise.push(
          current.imagePathReal
            ? getSatelliteImage(
              current.imagePathReal,
              1,
              (c) => {
                this.realCancel = c;
              },
            ) : '',
        );
      }
      [this.forecastImage, this.realImage] = await Promise.all(promise);
      const end = Date.now();

      if (this.first) {
        const diff = end - start;
        this.first = false;
        this.quality = getQuality(diff);
      }

      this.initShowDiffHour = true;
      this.setLoading(false);
      if (this.status === 'play') {
        this.setIndex(this.requestIndex);
        Bus.$emit('next', true);
      }
      if (!this.realImage) {
        const diffHour = moment(this.currentTime).diff(moment(), 'hours');
        this.diffHour = diffHour < 1 ? '<1' : diffHour;
      }
    },
    getDesc(times) {
      if (times) {
        const time = moment(times).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
        const click_time = moment(time, 'YYYYMMDDHH');
        const duration = moment.duration(click_time.diff(moment.now()));
        const { _data } = duration;
        const { days, hours, minutes } = _data;
        let text = '';
        let desc = '距现在';
        if (days < 0 || hours < 0 || minutes < 0) {
          text = '以前';
        } else if(days ===0 && hours === 0 && minutes === 0){
          return "现在"
        }else{
          text = '以后';
        }
        if (days !== 0) {
          desc += `${Math.abs(days)}天`;
        }
        if (hours !== 0) {
          desc += `${Math.abs(hours)}时`;
        }
        if (minutes !== 0) {
          desc += `${Math.abs(minutes)}分`;
        }
        return desc + text;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  padding: 10px 0 50px 10px;
  position: relative;
  box-sizing: border-box;

  i {
    font-size: 32px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 100px;
    width: 32px;
    height: 32px;
    color: rgba(255, 255, 255, 0.5);
    z-index: 1000;
  }

  .predict {
    padding: calc(20px + 0.5%) 0;
  }

  & > div {
    width: 50%;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 20px 0;
    position: relative;

    div {
      width: 100%;
      height: 100%;
    }

    .no_img_text {
      color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      -webkit-user-drag: none;
    }

    span {
      color: #ccc;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: 20px;
    }
  }
}

.vertical_wrap {
  display: block;
  box-sizing: border-box;
  padding: 30px 0 96px 0;
  overflow-x: hidden;
  height: 100%;
  width: 100%;

  .predict {
    padding: calc(20px + 1.1%) 0;
  }

  i {
    bottom: calc(50%);
  }

  & > div {
    width: 100%;
    height: 50%;
    text-align: center;
    // padding: 20px 0;
    box-sizing: border-box;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
</style>
